//This compoennet will render the token name, logo and symbol in the top left of the page.


import React, { useState } from 'react';
import './TokenNameAndLogoDisplay.css';
import TokenTagComponent from '../../../../ReusableUtility/TokenTags/TokenTagComponent';
import TokenChartModal from '../TokenChartModal/TokenChartModal'; // Import the modal component
import LightTokenChartIcon from '../../../../Media/Icons/LightTokenChartIcon.svg'; 
import DarkTokenChartIcon from '../../../../Media/Icons/DarkTokenChartIcon.svg'; 
import { useTheme } from '../../../../Auth/AppContexts/ThemeContext'; 

const TokenNameAndLogoDisplay = ({ logoUri, name, symbol, IsASolanaToken, tokenAddress }) => {
    const [isChartModalOpen, setIsChartModalOpen] = useState(false);
      const { theme } = useTheme();

    // Function to toggle the chart modal
    const toggleChartModal = () => setIsChartModalOpen(!isChartModalOpen);

    // URL for the default logo
    const defaultLogoUrl = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png';

    // Use logoUri if available, otherwise fall back to default logo
    const displayLogo = logoUri || defaultLogoUrl;

    return (
        <div className="token-logo-container">
            <img src={displayLogo} alt={`${name} Logo`} className="display-token-logo" />
            <div className="token-info">
                <div className="token-name-and-tag">
                    <h1 className="token-name">{name}</h1>
                    {IsASolanaToken && <TokenTagComponent />}
                    {/* Chart Icon */}
                  {
                     <img 
                        src={theme === 'dark' ? DarkTokenChartIcon : LightTokenChartIcon} 
                        alt="Chart" 
                        className="token-chart-icon" 
                        onClick={toggleChartModal} 
                    />
                    }

                </div>
                <h2 className="token-symbol">({symbol})</h2>
            </div>
            {/* Chart Modal */}
            <TokenChartModal isOpen={isChartModalOpen} onClose={toggleChartModal} tokenAddress={tokenAddress} />
        </div>
    );
};

export default TokenNameAndLogoDisplay;
