import React, { useState } from 'react';
import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu';
import TrendingTokensPanel from '../../Components/TrendingTokensPanel/TrendingTokensPanel';
import TokenInfoPanel from '../../Components/TokenInfoPanel/TokenInfoPanel';
import Charting from '../../Components/ChartingPanel/Charting';
import LatestTradesPanel from '../../Components/LatestTradesPanel/LatestTradesPanel';
import TokenRightSidePanel from '../../Components/TokenRightSidePanel/TokenRightSidePanel';
import TradingPanel from '../../Components/TradingPanelModal/TradingPanel'; 
import SearchBar from '../../Components/SearchBar/SearchBar';
import { fetchTokenList } from '../../APIFunctions/FetchTokenList';
import { fetchTokenPrice } from '../../APIFunctions/FetchTokenPrice';
import formatPrice from '../../ReusableUtility/FormatPrice'



const Trade = () => {
    const [showTradingPanel, setShowTradingPanel] = useState(false);

    const [selectedToken, setSelectedToken] = useState(null);
    
    

    





    const handleTokenSelect = async (selectedToken) => {

        console.log("Selected token in Trade component:", selectedToken);
        setSelectedToken(selectedToken);

        try {
            // Fetch price for the selected token
            const priceData = await fetchTokenPrice(selectedToken.symbol);

            // Update the selected token data with additional details
            const updatedTokenData = {
                ...selectedToken,
                price: formatPrice(priceData?.price), // formatPrice needs to be defined or imported
                // You can also fetch and add additional data like volume, change, etc.
            };

            setSelectedToken(updatedTokenData);
        } catch (error) {
            console.error("Error fetching additional token data:", error);
            // Handle errors appropriately
        }
    };



    // Placeholder for the selected token data
    const selectedTokenData = {
        name: 'Token XYZ',
        price: '$123',
        volume: '456K',
        change: '+7.89%',
        // ... other token-related info
    };

    const handleBuyClick = () => {
        setShowTradingPanel(true);
    };

    const handleSellClick = () => {
        setShowTradingPanel(true);
    };

    const handleClose = () => {
        setShowTradingPanel(false);
    };

    return (
         <div className={`dashboard-container ${showTradingPanel ? 'blur' : ''}`}>
        <SidePanel />
         <HeaderMenu onTokenSelect={handleTokenSelect} />
         <SearchBar onTokenSelect={handleTokenSelect} />
    
        <TrendingTokensPanel />
        <TokenInfoPanel selectedToken={selectedToken} />
        <Charting />
        <LatestTradesPanel />
        <TokenRightSidePanel 
             selectedToken={selectedToken} 
             onBuyClick={handleBuyClick} 
             onSellClick={handleSellClick} 
        />
        {showTradingPanel && 
            <div className="modal-backdrop" onClick={handleClose}>
                <TradingPanel 
                    isVisible={showTradingPanel} 
                    onClose={handleClose} 
                    tokenData={selectedTokenData}
                    onClick={(e) => e.stopPropagation()} 
                />
            </div>
        }
    </div>
    );
};

export default Trade;
