//Main large parent compoenent that renders all the live token infomation

import React from 'react';
import './ParentTokenDetailsComponent.css';
import { WalletAddressLink as ExternalWalletAddressLink } from '../../../ReusableUtility/WalletAndTxLink/WalletAndTxLink';
import { TxHashLink, AccountNameLink } from '../../../ReusableUtility/WalletAndTxLink/WalletAndTxLink';
import TimeAgo from '../../../ReusableUtility/RefreshFetchTime'; 
import { formatBurnAmount, formatNumber } from '../../../ReusableUtility/FormatNumberWithCommas';
import LoadingComponent from '../../../ReusableUtility/LoadingComponent'; 
import txFailedImage from '../../../Media/Icons/TxFailedTag.svg';
import tokenTransferIcon from '../../../Media/Icons/TokenTransferIcon.svg';
import { ReactComponent as TxDirectionIcon } from '../../../Media/Icons/TxDirectionIcon.svg';
import { ReactComponent as TxDirectionIconDark } from '../../../Media/Icons/TxDirectionIconDark.svg';
import SoldTokenIcon from '../../../Media/Icons/SoldTokenIcon.svg';
import BoughtTokenIcon from '../../../Media/Icons/BoughtTokenIcon.svg';
import ProtocolIcon from '../../../Media/Icons/TxDirectionIcon.svg';
import ProtocolIconDark from '../../../Media/Icons/TxDirectionIconDark.svg';
import TokenLink from '../../../ReusableUtility/TokenLink/TokenLink'; 
import { protocolMapping } from '../../../ReusableUtility/ProtocolMapping';
import { useTheme } from '../../../Auth/AppContexts/ThemeContext';




const ParentTokenDetailsComponent = ({ data, transactions, isLoading, tokenAddress, tokenMetaInfo, fetchTokenMetadata, holders, activePanel }) => {

   const { theme } = useTheme();


  // URL for the default token image
const defaultTokenImage = "https://cnemhnnwbhehhupxqoin.supabase.co/storage/v1/object/public/Icons/CircleDashed-r.svg?t=2024-01-19T20%3A11%3A42.277Z";


const formatAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-6)}`;
  };


// Use the imported ExternalWalletAddressLink component
const WalletAddressLink = ({ address, showIcon }) => {
  if (!address) {
    return <span className="wallet-link">Address not available</span>;
  }

  return (
    <div className="wallet-link-container">
            {showIcon && (
                theme === 'dark' 
                ? <TxDirectionIconDark className="icon-inline" /> 
                : <TxDirectionIcon className="icon-inline" />
            )}
            <ExternalWalletAddressLink address={address} className="wallet-link">
                {formatAddress(address)} {/* Assuming formatAddress is a function you've defined */}
            </ExternalWalletAddressLink>
        </div>
  );
};




 const renderTokenTxTransferRow = (tx, key) => {
  const txnHash = tx.signatures?.[0];
  const timestamp = tx.timestamp;
  const sender = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.sender;
  const receiver = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.receiver;
  const amount = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.amount;
  const tokenAddress = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.token_address;
  
  // Use tokenMetaInfo to get the metadata for the token
  const tokenMetadata = tokenMetaInfo[tokenAddress];
  const tokenSymbol = tokenMetadata?.symbol || 'token';
   const tokenImage = tokenMetadata?.image || defaultTokenImage;

    
    
    
    //use dynamic token image
    const isFailedTransaction = tx.status !== "Success";



    return (
       <div className="token-content-row" key={key}>
    <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
    {isFailedTransaction ? (
      <span><img src={txFailedImage} alt="Transaction Failed" className="token-tx-icon-types" /></span>
    ) : (
      <span><img src={tokenTransferIcon} alt="Token Transfer" className="token-tx-icon-types" /></span>
    )}
    <span><TimeAgo timestamp={timestamp} /></span>
    <WalletAddressLink address={sender} showIcon={false} />
    <WalletAddressLink address={receiver} showIcon={true} />
    <span className="token-amount-column">
      <img src={tokenImage} alt={tokenSymbol} className="token-tx-panel-token-logo" />
      {amount !== undefined ? `${formatNumber(amount)} ` : '0 '}&nbsp; 
      <TokenLink tokenAddress={tokenAddress} symbol={tokenSymbol} />
    </span>
  </div>
    );
  };


 
const TransactionTypeIcons = {
  'SOLD': SoldTokenIcon,
  'BOUGHT': BoughtTokenIcon,
  // Add other transaction types and their corresponding icons here in the future
};

  

  const renderTokenSwapTxTransactionRow = (tx, key, tokenMetaInfo) => {

  

  const status = tx?.status;

    // Check if the transaction is a failed one
  const isFailedTransaction = tx.status !== "Success";

  const feePayer = tx.fee_payer;
  const txnHash = tx.signatures?.[0];
  const timestamp = tx.timestamp;
  const protocolAddress = tx.protocol?.address;
  const protocolName = protocolMapping[protocolAddress] || "Unknown Protocol";

  const inToken = tx.actions?.[0]?.info?.tokens_swapped?.in || {};
  const outToken = tx.actions?.[0]?.info?.tokens_swapped?.out || {};




    // Check if the fee payer is the wallet that initiated the swap
  return (
  <>
    {/* SWAPPED OUT - WHAT USER SOLD */}
   <div className="token-content-row" key={`${txnHash}-out`}>
        <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>

        {isFailedTransaction ? (
          <span><img src={txFailedImage} alt="Transaction Failed" className="token-tx-icon-types" /></span>
        ) : (
          <span><img src={TransactionTypeIcons['SOLD']} alt="Sold" className="token-tx-icon-types" /></span>
        )}

        <span><TimeAgo timestamp={timestamp} /></span>
        <WalletAddressLink address={feePayer} showIcon={false} />

        <span className="token-link-container">
           <img 
                src={theme === 'dark' ? ProtocolIconDark : ProtocolIcon} 
                alt="Protocol" 
                className="protocol-direction-icon" 
            />


          <AccountNameLink address={protocolAddress} protocolName={protocolName} protocolMapping={protocolMapping} />
        </span>

        <span className="token-amount-column">
          <img src={inToken.image_uri || defaultTokenImage} alt={inToken.symbol || 'TOKEN'} className="token-tx-panel-token-logo" />
          {formatNumber(inToken.amount, 2)}&nbsp; <TokenLink tokenAddress={inToken.token_address} symbol={inToken.symbol || 'TOKEN'} />
        </span>
      </div>




        {/* SWAPPED IN - WHAT USER BOUGHT */}
    {status === "Success" && (
      <div className="token-content-row" key={`${txnHash}-in`}>
          <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
          <span><img src={TransactionTypeIcons['BOUGHT']} alt="Bought" className="token-tx-icon-types" /></span>

          <span><TimeAgo timestamp={timestamp} /></span>
          <AccountNameLink address={protocolAddress} protocolName={protocolName} protocolMapping={protocolMapping} />
          <WalletAddressLink address={feePayer} showIcon={true} />

          <span className="token-amount-column">
            <img src={outToken.image_uri || defaultTokenImage} alt={outToken.symbol || 'TOKEN'} className="token-tx-panel-token-logo" />
            {formatNumber(outToken.amount, 2)}&nbsp; <TokenLink tokenAddress={outToken.token_address} symbol={outToken.symbol || 'TOKEN'} />
          </span>
        </div>
    )}
  </>
);
  
  return null; // Return null if the transaction should not be displayed
};







  return (
    <div className="token-tx-details">
    <div className="token-header-row">
      <span className="header-transaction">TRANSACTION #</span>
      <span>TX TYPE</span>
      <span>TIME</span>
      <span>FROM</span>
      <span>TO</span>
      <span className="header-amount">AMOUNT</span>
    </div>
    {isLoading ? (
      // Use the custom loading component with the same style as the first component
      <div className="token-tx-loading-container">
        <LoadingComponent />
      </div>
    ) : (
      transactions.map((tx, index) => {
        const key = tx.signatures[0] || `transaction-${index}`;
        // Use the appropriate rendering function based on the transaction type
        switch (tx.type) {
          case "TOKEN_TRANSFER":
            return renderTokenTxTransferRow(tx, index, tokenMetaInfo);
          case "SWAP":
            return renderTokenSwapTxTransactionRow(tx, index, tokenMetaInfo);
          // Add additional cases for other transaction types as needed
          default:
            return null; // Or some default JSX if there's no match
        }
      })
    )}
  </div>
  );
};

export default ParentTokenDetailsComponent;
