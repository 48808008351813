import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ExplorerMainBarSearch from '../../Components/ExplorerFeature/ExploreSearchBar/ExplorerMainSearchBar';
import SierraSearch from '../../Components/SierraTradingFeature/SierraSearchBar/SierraSearch';
import HeaderSolanaPrice from './SolanaPrice/HeaderSolanaPrice'; // Make sure the path is correct
import './HeaderMenu.css';
import { UserContext } from '../../Auth/AppContexts/UserContext';
import useWalletConnection from '../../ReusableUtility/WalletConnection/WalletConnection'; 
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useTheme } from '../../Auth/AppContexts/ThemeContext'; 
import { ReactComponent as LightModeIcon } from '../../Media/Icons/LightModeIcon.svg'; 
import { ReactComponent as DarkModeIcon } from '../../Media/Icons/DarkModeIcon.svg'; 
import CustomWalletConnectButton from '../../ReusableUtility/WalletConnection/CustomWalletConnection/CustomWalletConnectionButton'; 

const HeaderMenu = ({ onTokenSelect }) => {
    const { setUserId } = useContext(UserContext);
    const { toggleTheme, theme } = useTheme();
    useWalletConnection(setUserId);

      


    const location = useLocation();
    const isBaseExplorerPage = location.pathname === '/dashboard/explorer' || location.pathname === '/dashboard/explorer/';

     // Determine if the current page is the Sierra trading insight page
    const isSierraTradingPage = location.pathname === '/dashboard/sierra-ai';


     // Define a common style object for the icons
    const iconStyles = {
        width: '22px', // Reduce width
        height: '22px' // Reduce height
    };

    return (
         <div className="header-menu">
        <HeaderSolanaPrice />

        {/* Conditional rendering of search bars based on the current page */}
        {location.pathname.includes('/dashboard/sierra-ai') ? (
            <div className="search-bar-container">
                {/* Render SierraSearch when on the Sierra AI page */}
                <SierraSearch onTokenSelect={onTokenSelect} />
            </div>
        ) : !isBaseExplorerPage ? (
            <div className="search-bar-container">
                {/* Render ExplorerMainBarSearch on all other pages except the base explorer page */}
                <ExplorerMainBarSearch onTokenSelect={onTokenSelect} className="header-search-bar" />
            </div>
        ) : null}

        <div className="wallet-button-container">
                {/* Show the WalletMultiButton on larger screens */}
                <div className="desktop-wallet-button">
                    <WalletMultiButton />
                </div>
                {/* Show the CustomWalletConnectButton on smaller screens */}
                <div className="mobile-wallet-button">
                    <CustomWalletConnectButton />
                </div>
            </div>

        {/* Toggle Button for Dark/Light Mode */}
        <button onClick={toggleTheme} className="theme-toggle-button">
            {theme === 'dark' ? 
                <LightModeIcon style={iconStyles} /> : 
                <DarkModeIcon style={iconStyles} />
            }
        </button>
    </div>
    );
};

export default HeaderMenu;

