


import React from 'react';
import './PortfolioCards.css';

const PortfolioCard = ({ title, value, children }) => {
  return (
    <div className="main-portfolio-card">
      <h2>{title}</h2>
      {/* Apply a specific class for the value */}
      {value ? <p className="main-portfolio-card-value">{value}</p> : children}
    </div>
  );
};

export default PortfolioCard;
