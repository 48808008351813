// PortfolioCharts.js
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const PortfolioCharts = () => {
  const data = [
    { name: 'SOL', value: 15000 },
    { name: 'JUP', value: 10000 },
    { name: 'WEN', value: 4000 },
    { name: 'MOCKJUP', value: 3000 },
    { name: 'GME', value: 2000 }
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  return (
    <PieChart width={400} height={400}>
      <Pie
        data={data}
        cx={200}
        cy={200}
        innerRadius={90}
        outerRadius={150}
        fill="#8884d8"
        paddingAngle={9}
        dataKey="value"
        label={(entry) => entry.name}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default PortfolioCharts;
