// TokenCard.js

import React from 'react';
import './TGECards.css'; // Renamed CSS file

const TokenCard = ({ title, value }) => {
  return (
    <div className="token-card">
      <h2>{title}</h2>
      {value && <p className="token-card-value">{value}</p>}
    </div>
  );
};

export default TokenCard;
