import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu';
import ChatPanel from '../../Components/ChatPanel/ChatPanel';
import WhiteLogo from '../../Media/Logos/WhiteLogo.svg';
import SparklesDarkMode from '../../Media/Icons/SparklesDarkMode.svg';
import './TradingInsight.css';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, VersionedTransaction, TransactionConfirmationStatus } from '@solana/web3.js';
import { useSwap } from '../../Auth/AppContexts/SwapContext';
import { useSolanaConnection } from '../../Auth/AppContexts/SolanaConnectionContext'; 
import { useSendTransaction } from '../../Auth/AppContexts/SendTransactionContext';
import SierraChart from '../../Components/SierraTradingFeature/SierraChartsModal/SierraCharts'; 
import * as solanaWeb3 from '@solana/web3.js';
import SierraTradeInfoPanel from '../../Components/SierraTradingFeature/SierraInfoPanel/SierraTradeInfoPanel'; 
import { SierraSearchContext } from '../../Auth/AppContexts/SierraSearchContext';






const Insight = () => {
    const [inputText, setInputText] = useState('');
    const [swapQuote, setSwapQuote] = useState(null);
     const connection = useSolanaConnection(); 
    const wallet = useWallet();
    const { swapQuotes, updateSwapQuotes, setSwapStatus } = useSwap();
     const [isModalVisible, setIsModalVisible] = useState(false);
     const { setTransactionStatus } = useSendTransaction();

      const [isChartPaneCollapsed, setIsChartPaneCollapsed] = useState(false);
      const [solBalance, setSolBalance] = useState(null);
      const [tokenBalance, setTokenBalance] = useState(null);
       const [tokenSymbol, setTokenSymbol] = useState('');
    
    const navigate = useNavigate();
    const { tokenAddress } = useParams(); 
    const { setSelectedTokenByAddress } = useContext(SierraSearchContext);
    const [betaAccessGranted, setBetaAccessGranted] = useState(
    sessionStorage.getItem('betaAccessGranted') === 'true'
  );
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [usdcBalance, setUsdcBalance] = useState(null);

  /*
    const toggleChat = () => {
        setIsChatVisible(!isChatVisible);
    };

     const handleTogglePane = () => {
        setIsChartPaneCollapsed(!isChartPaneCollapsed);
    };
*/
    



  useEffect(() => {
  if (betaAccessGranted) {
    setIsModalVisible(false);
  }
}, [betaAccessGranted]); 
    






    //Repsosible for navigating and displaying SOL in url and as defualt chart to display
    const SOL_TOKEN_ADDRESS = "So11111111111111111111111111111111111111112"; // Adjust as needed

    useEffect(() => {
        if (!tokenAddress) {
            navigate(`/dashboard/sierra-ai/t/${SOL_TOKEN_ADDRESS}`);
        } else {
            setSelectedTokenByAddress(tokenAddress);
        }
    }, [navigate, tokenAddress, setSelectedTokenByAddress]);
    
    /////////////



//fetches sol balance for Sierra when wallet is conencted
 useEffect(() => {
  let intervalId;

  // Function to fetch SOL balance
  const fetchSolBalance = async () => {
    if (wallet.connected && wallet.publicKey) {
      try {
        const solBalanceUrl = `${process.env.REACT_APP_BASE_URL}/sol-balance?address=${wallet.publicKey.toString()}`;
        const response = await fetch(solBalanceUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch SOL balance from API');
        }
        const { solBalance } = await response.json();
        if (solBalance !== undefined) {
          setSolBalance(solBalance);
        } else {
          throw new Error('SOL balance data is missing or the API response is malformed');
        }
      } catch (error) {
        console.error('Error fetching SOL balance:', error);
        setSolBalance(null);
      }
    }
  };


  const USDC_MINT_ADDRESS = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'; 

  // Function to fetch token balance
  const fetchTokenBalance = async () => {
  if (wallet.connected && wallet.publicKey) {
    try {
      // Fetch token balance if tokenAddress is provided
      if (tokenAddress) {
        const tokenBalanceUrl = `${process.env.REACT_APP_API_URL}/token-balance?walletAddress=${wallet.publicKey.toString()}&tokenMintAddress=${tokenAddress}`;
        const response = await fetch(tokenBalanceUrl);
        const tokenData = await response.json();

        //console.log("Token Data Response:", tokenData);

        if (tokenData.balance !== undefined) {
          setTokenBalance(tokenData.balance);
          setTokenSymbol(tokenData.tokenInfo?.symbol || '---');
        } else {
          throw new Error('Token balance data is missing or the API response is malformed');
        }
      }

      // Fetch USDC balance
      const usdcBalanceUrl = `${process.env.REACT_APP_API_URL}/token-balance?walletAddress=${wallet.publicKey.toString()}&tokenMintAddress=${USDC_MINT_ADDRESS}`;
      const usdcResponse = await fetch(usdcBalanceUrl);
      const usdcData = await usdcResponse.json();

      if (usdcResponse.ok && usdcData.balance !== undefined) {
        setUsdcBalance(usdcData.balance); // Set fetched USDC balance
      } else {
        setUsdcBalance(0); // Default to 0 if not available
      }

    } catch (error) {
      console.error('Error fetching balances:', error);
      setTokenBalance(null); // Reset token balance to null in case of an error
      setTokenSymbol('---'); // Reset token symbol to default in case of an error
      setUsdcBalance(0); // Default to 0 in case of an error
    }
  }
};



//Fetches balances for balances comepeont on a scheduled basis
  const handleVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(intervalId); // Stop polling when tab is not visible
    } else {
      fetchSolBalance(); // Fetch SOL balance immediately when tab becomes visible again
      fetchTokenBalance(); // Fetch token balance immediately when tab becomes visible again
      intervalId = setInterval(() => {
        fetchSolBalance();
        fetchTokenBalance();
      }, 30000); // Restart polling for both balances
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);

  // Immediately fetch both balances when the component mounts or wallet state changes
  fetchSolBalance();
  fetchTokenBalance();
  intervalId = setInterval(() => {
    fetchSolBalance();
    fetchTokenBalance();
  }, 30000); // Start polling for both balances

  // Cleanup function
  return () => {
    clearInterval(intervalId); // Clear polling interval
    document.removeEventListener("visibilitychange", handleVisibilityChange); // Remove visibility change listener
  };
}, [wallet.publicKey, wallet.connected, tokenAddress]); 












    useEffect(() => {
    const modalShown = sessionStorage.getItem('modalShown') === 'true';
    const betaAccess = sessionStorage.getItem('betaAccessGranted') === 'true';

    // Only show the modal if it hasn't been shown in this session AND beta access hasn't been granted
    if (!modalShown && !betaAccess) {
        setIsModalVisible(true);
    } else {
        setIsModalVisible(false);
    }
}, []);


    const handleGetStartedClick = () => {
        setIsModalVisible(false);
        sessionStorage.setItem('modalShown', 'true');
    };
    

    

  // Initiates the transaction preparation with API validation
 // Function to safely fetch the recent blockhash with retries
async function safeGetRecentBlockhash(connection) {
    try {
        return await connection.getRecentBlockhash('finalized');
    } catch (error) {
        console.error("Failed to fetch recent blockhash:", error);
        throw new Error("Unable to fetch recent blockhash due to RPC error.");
    }
}

const initiateSendTransaction = async ({ recipientAddress, amount }) => {
    if (!wallet.connected || !wallet.publicKey) {
        console.error("Wallet not connected.");
        return { status: 'error', message: 'Wallet not connected' };
    }

    console.log("Preparing to send transaction:", { 
        RecipientAddress: recipientAddress, 
        Amount: amount,
        FromPublicKey: wallet.publicKey.toString()
    });

    try {
        const connection = new solanaWeb3.Connection(solanaWeb3.clusterApiUrl('mainnet-beta'), 'confirmed');
        const { blockhash } = await safeGetRecentBlockhash(connection);

        const transaction = new solanaWeb3.Transaction({
            recentBlockhash: blockhash,
            feePayer: wallet.publicKey,
        }).add(
            solanaWeb3.SystemProgram.transfer({
                fromPubkey: wallet.publicKey,
                toPubkey: new solanaWeb3.PublicKey(recipientAddress),
                lamports: amount * solanaWeb3.LAMPORTS_PER_SOL
            })
        );

        return { status: 'prepared', transaction };
    } catch (error) {
        console.error("Error during transaction preparation:", error);
        return { status: 'error', message: error.toString() };
    }
};
















const onExecuteTransaction = async (transactionDetails) => {
    if (!wallet.connected || !wallet.publicKey) {
        console.error("Wallet not connected or public key not available.");
        alert("Please connect your wallet and ensure you have valid transaction details before proceeding.");
        return;
    }

    console.log("Initiating SOL send transaction with details:", transactionDetails);
    const numericAmount = parseFloat(transactionDetails.amount.split(" ")[0]); // Ensuring amount is numeric

    const preparationResult = await initiateSendTransaction({
        recipientAddress: transactionDetails.recipientAddress,
        amount: numericAmount
    });

    if (preparationResult.status !== 'prepared') {
        alert(`Failed to prepare transaction: ${preparationResult.message}`);
        return;
    }

    try {
        const signedTransaction = await wallet.signTransaction(preparationResult.transaction);
        const connection = new solanaWeb3.Connection(solanaWeb3.clusterApiUrl('mainnet-beta'), 'confirmed');
        const signature = await connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: true });
        const confirmationResult = await connection.confirmTransaction(signature, 'confirmed');

        if (confirmationResult.value.err) {
            console.error("Transaction failed to confirm:", confirmationResult.value.err);
            alert("Transaction failed to confirm.");
            return;
        }

        alert(`Transaction successful! Transaction ID: ${signature}`);
    } catch (error) {
        console.error("Error during send transaction process:", error);
        alert(`An error occurred: ${error.message}. Please check your transaction and try again.`);
    }
};








//FUNCTIONS FOR SWAPS
// Function that gets called when a user wants to execute a specific swap
const onExecuteSwap = async (quoteId) => {
    //console.log("swapQuotes array:", swapQuotes);
    //console.log("onExecuteSwap called with quoteId:", quoteId);
    const selectedQuote = swapQuotes.find(quote => quote.quoteId === quoteId);
    if (selectedQuote) {
        //console.log("Selected quote found:", selectedQuote);
        //console.log("Calling initiateSwapAndSendTransaction with rawQuote:", selectedQuote.rawQuote);
        try {
            setSwapStatus(quoteId, 'loading'); // Indicate that the swap process has started
            const swapResult = await initiateSwapAndSendTransaction(quoteId, selectedQuote.rawQuote);
            // React based on the swapResult
            if (swapResult === 'rejected') {
                //console.log(`Swap rejected for ${quoteId}`);
                setSwapStatus(quoteId, 'rejected'); // Explicitly handle rejection
            } else if (swapResult === 'success') {
                //console.log(`Swap successful for ${quoteId}`);
                setSwapStatus(quoteId, 'success');
            } else {
                //console.log(`Swap error for ${quoteId}`);
                setSwapStatus(quoteId, 'error');
            }
        } catch (error) {
            console.error("Swap execution failed:", error);
            setSwapStatus(quoteId, 'error');
            alert('An error occurred during the transaction. Please try again.');
        }
    } else {
        console.error("Selected quote not found for quoteId:", quoteId);
        alert("Selected quote not found. Please ensure you have a valid swap quote before proceeding.");
    }
};




const initiateSwapAndSendTransaction = async (quoteId, rawQuote) => {
    if (!wallet.connected || !wallet.publicKey) {
        console.error("Wallet not connected or public key not available.");
        alert("Please connect your wallet and ensure you have a valid swap quote before proceeding.");
        setSwapStatus(quoteId, 'error', null);
        return { status: 'error', txHash: null };
    }

    console.log("Initiating swap with rawQuote:", rawQuote);

    try {
        const baseUrl = process.env.REACT_APP_BASE_URL;  
        console.log("Contacting backend to prepare swap:", baseUrl);
        
        const swapInitResponse = await fetch(`${baseUrl}/api/prepareSwap`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userPublicKey: wallet.publicKey.toString(), rawQuote }),
        });

        if (!swapInitResponse.ok) {
            const responseText = await swapInitResponse.text();
            console.error('Failed to initiate swap with backend error:', responseText);
            alert('Failed to initiate swap. Please try again or contact support if the problem persists.');
            setSwapStatus(quoteId, 'error', null);
            return { status: 'error', txHash: null };
        }

        const { swapTransaction } = await swapInitResponse.json();
        console.log("Swap transaction received and decoded:", swapTransaction);

        const { blockhash } = await connection.getRecentBlockhash('finalized');
        console.log("Using blockhash:", blockhash);

        const byteArray = Uint8Array.from(atob(swapTransaction), c => c.charCodeAt(0));
        const transaction = VersionedTransaction.deserialize(byteArray);
        transaction.recentBlockhash = blockhash;

        const signedTransaction = await wallet.signTransaction(transaction);
        console.log("Transaction signed with new blockhash, sending to network...");

        const signature = await connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: true });
        console.log("Transaction sent to blockchain, awaiting confirmation. Signature:", signature);

        const confirmationResult = await connection.confirmTransaction(signature, "confirmed");
        console.log("Transaction confirmation result:", confirmationResult);

        if (confirmationResult.value.err) {
            throw new Error(`Blockchain error: ${confirmationResult.value.err}`);
        }
        console.log("Transaction confirmed successfully. Signature:", signature);
        alert(`Swap transaction successful! Transaction ID: ${signature}`);
        setSwapStatus(quoteId, 'success', signature);
    } catch (error) {
        console.error("Error during transaction process:", error);
        alert(`An error occurred: ${error.message}. Please check your transaction and try again.`);
        setSwapStatus(quoteId, 'error', null);
    }
};









 const handleBetaAccess = async () => {
    try {
      // Assuming baseUrl and fetch setup...
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/beta-testers-access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: inputText }),
      });

      const data = await response.json();

      if (response.ok) {
        setBetaAccessGranted(true);
        sessionStorage.setItem('betaAccessGranted', 'true'); // Store access in sessionStorage
        setIsModalVisible(false); // Assuming you have a state to control the modal visibility
      } else {
        alert(data.message); // Handle incorrect password case
      }
    } catch (error) {
      console.error('Error verifying beta access:', error);
      alert('An error occurred while verifying beta access.');
    }
  };


  

 const handleTogglePane = () => {
        setIsChartPaneCollapsed(prevState => !prevState);
        setIsChatVisible(prevState => !prevState); // Directly toggle chat visibility
    };




const [showChat, setShowChat] = useState(false);

// Function to toggle the display of the chat pane
const handleToggleChat = () => {
    setShowChat(!showChat); // Toggle the display of the chat pane
};

 





return (
     <div className="dashboard-container">
         
           {isModalVisible && (
  <div className="insight-full-page-overlay">
    <div className="insight-coming-soon-modal">
      <img src={WhiteLogo} alt="Logo" className="insight-modal-logo" />
      <p>Welcome to Sierra AI.</p>
      <p>Blackfin's next-gen AI integrated data and trading app layer, launching soon.</p>
      <p>Execute trades using natural language commands in seconds.<br />For example, you can send requests such as "Buy 0.01 SOL of Bonk at market".</p>
      <p><a href="https://blackfin-explorer.gitbook.io/welcome-to-blackfin/product-guides/execute-trades-using-sierra" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>Learn more</a></p>
      <button onClick={() => setIsModalVisible(false)} className="intro-modal-insight-content-button">Get Started</button>
      {/* Beta Access Input Field and Button Always Visible */}
      <div style={{ marginTop: '20px' }}>
        <input
          type="password"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter Beta Access Password"
          style={{ display: 'block', marginBottom: '10px', padding: '10px', width: 'calc(100% - 20px)' }}
        />
        <button onClick={handleBetaAccess} style={{ padding: '10px 20px', width: '100%' }}>Enter</button>
      </div>
    </div>
  </div>
)}



 <SidePanel />
            <HeaderMenu />
            <div className="insight-main-content">
                {!isChartPaneCollapsed && (
                    <SierraTradeInfoPanel
                        solBalance={solBalance}
                        tokenBalance={tokenBalance}
                        tokenSymbol={tokenSymbol}
                         usdcBalance={usdcBalance}
                        isWalletConnected={wallet.connected}
                    />
                )}
                <div className={`chart-pane ${isChartPaneCollapsed ? 'collapsed' : ''}`}>
                    {!isChartPaneCollapsed && (
                        <>
                            <SierraChart />
                            <button onClick={handleTogglePane} className="toggle-chart-pane collapse-button">
                                <img src={SparklesDarkMode} alt="Collapse" />
                            </button>
                        </>
                    )}
                </div>
                <div className={`chat-pane ${isChartPaneCollapsed ? 'expanded' : ''}`}>
                    {!wallet.connected && (
                        <div className="wallet-connect-overlay">
                            Connect your wallet to start trading, analysing and exploring with Sierra AI.
                        </div>
                    )}
                    <ChatPanel
                        inputText={inputText}
                        setInputText={setInputText}
                       
                        addMessage={(message) => console.log(message)}
                        onExecuteSwap={onExecuteSwap}
                        onExecuteTransaction={onExecuteTransaction}
                        className={!wallet.connected ? "no-wallet-blur-effect" : ""}
                        isWalletConnected={wallet.connected}
                    />
                    {isChartPaneCollapsed && (
                        <button onClick={handleTogglePane} className="toggle-chart-pane expand-button">←</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Insight;




