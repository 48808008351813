import React from 'react';
import './TxHashDisplay.css'; 
import { ReactComponent as TxHashIcon } from '../../../../Media/Icons/TxHashIcon.svg'; 
import { ReactComponent as TxHashIconDarkMode } from '../../../../Media/Icons/TxHashIconDarkMode.svg'; 
import { useTheme } from '../../../../Auth/AppContexts/ThemeContext'; // Adjust the path as necessary

const TxHashDisplay = ({ hash }) => { // Accept hash as a prop
    const { theme } = useTheme(); // Use the theme context to get the current theme

    return (
        <div className="txHashDisplayContainer">
            <div className="hashDivider"></div> {/* Divider added here */}
            <div className="txHashheaderText">TRANSACTION HASH #</div>
            <div className="txHashDisplay">
                {theme === 'dark' ? (
                    <TxHashIconDarkMode className="txHashIcon" />
                ) : (
                    <TxHashIcon className="txHashIcon" />
                )}
                <span className="hashText">{hash}</span> {/* Use the hash prop */}
            </div>
            
        </div>
    );
};

export default TxHashDisplay;
