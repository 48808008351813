import React, { useState, useEffect } from 'react';


const formatDate = (timestamp) => {
  const transactionDate = new Date(timestamp);
  const now = new Date();
  const differenceInSeconds = Math.floor((now - transactionDate) / 1000);

  if (differenceInSeconds < 60) {
    return 'Just now';
  } else if (differenceInSeconds < 3600) {
    return `${Math.floor(differenceInSeconds / 60)} minute${differenceInSeconds < 120 ? '' : 's'} ago`;
  } else if (differenceInSeconds < 86400) {
    return `${Math.floor(differenceInSeconds / 3600)} hour${differenceInSeconds < 7200 ? '' : 's'} ago`;
  } else if (differenceInSeconds < 604800) {
    const days = Math.floor(differenceInSeconds / 86400);
    return `${days} day${days === 1 ? '' : 's'} ago`;
  } else {
    return transactionDate.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' });
  }
};



const TimeAgo = ({ timestamp }) => {
  const [time, setTime] = useState(formatDate(timestamp));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(formatDate(timestamp));
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [timestamp]);

  return <span>{time}</span>;
};


export default TimeAgo;
