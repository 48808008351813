const formatPrice = (price) => {
    // Check for null, undefined, or non-numeric values
    if (price === null || price === undefined || isNaN(Number(price))) return 'N/A';
    
    // Ensure the price is treated as a number
    const numericPrice = Number(price);

    if (numericPrice >= 0.01) return numericPrice.toFixed(2);
    if (numericPrice >= 0.001) return numericPrice.toFixed(3);
    if (numericPrice >= 0.0001) return numericPrice.toFixed(4);
    if (numericPrice >= 0.00001) return numericPrice.toFixed(5);
    if (numericPrice >= 0.000001) return numericPrice.toFixed(6);
    if (numericPrice >= 0.0000001) return numericPrice.toFixed(7);
    if (numericPrice >= 0.00000001) return numericPrice.toFixed(8);
    if (numericPrice >= 0.000000001) return numericPrice.toFixed(9);
    if (numericPrice >= 0.0000000001) return numericPrice.toFixed(10);
    if (numericPrice >= 0.00000000001) return numericPrice.toFixed(11);

    return numericPrice.toFixed(11); // Default for very small values
};

export default formatPrice;
