import React, { useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useNavigate } from 'react-router-dom'; // react-router-dom v6
import Logo from '../../Media/Logos/BlackLogo.svg';
import './LandingPage.css';
import { supabase } from '../SupabaseAuth/SupabaseClient'





const LandingPage = () => {
    const { publicKey, connected } = useWallet();
    const navigate = useNavigate();





    useEffect(() => {
        const checkUserAndRedirect = async () => {
            if (connected && publicKey) {
                const walletAddress = publicKey.toString();
                const { data, error } = await supabase
                    .from('users')
                    .select('*')
                    .eq('wallet_address', walletAddress)
                    .maybeSingle(); // Change this to maybeSingle()

                if (error) {
                    console.error('Error fetching user data', error);
                    return;
                }

                if (data) {
                    navigate('/dashboard');
                } else {
                    navigate('/onboarding'); // Redirect new users to onboarding
                }
            }
        };


        checkUserAndRedirect();
    }, [connected, publicKey, navigate]);






    return (
        <div className="landing-page">
            <main className="main-content">
                <section className="wallet-sign-in">
                    <img src={Logo} alt="Logo" className="logo" />
                    <h2>Welcome to Blackfin.</h2>
                    <h3>Connect your wallet</h3>

                    <WalletMultiButton />
                </section>
            </main>
        </div>
    );
};

export default LandingPage;
