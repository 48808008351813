// SolanaConnectionContext.js
import React, { createContext, useContext, useMemo } from 'react';
import { Connection } from '@solana/web3.js';

const SolanaConnectionContext = createContext(null);

export const SolanaConnectionProvider = ({ children }) => {
  const rpcUrl = "https://rpc.shyft.to?api_key=ebHNsRKeCg508DMB";
  const wsEndpoint = "wss://rpc.shyft.to?api_key=ebHNsRKeCg508DMB";

  const connection = useMemo(() => new Connection(rpcUrl, {
    wsEndpoint: wsEndpoint,
    commitment: "confirmed"
  }), []);

  return (
    <SolanaConnectionContext.Provider value={connection}>
      {children}
    </SolanaConnectionContext.Provider>
  );
};

export const useSolanaConnection = () => useContext(SolanaConnectionContext);


//wss://rpc.shyft.to?api_key=ebHNsRKeCg508DMB
//https://rpc.shyft.to?api_key=ebHNsRKeCg508DMB


//https://solana-mainnet.core.chainstack.com/e36d33de17a03c6ca93e89eae1afd2bb
//wss://solana-mainnet.core.chainstack.com/ws/e36d33de17a03c6ca93e89eae1afd2bb

//https://mainnet.helius-rpc.com/?api-key=b0267f23-8eee-43c0-8c7c-8232e8d8ff5c
//wss://mainnet.helius-rpc.com/?api-key=b0267f23-8eee-43c0-8c7c-8232e8d8ff5c

