import { toSvg } from "jdenticon";

const IconGenerator = ({ tokenHash }) => {
  const iconSize = 38; // Size of the icon

  // Generate the SVG markup using the toSvg function
  const iconSvg = toSvg(tokenHash, iconSize);

  return (
    <div style={{ 
      width: iconSize, 
      height: iconSize, 
      borderRadius: '50%', 
      overflow: 'hidden' 
    }} dangerouslySetInnerHTML={{ __html: iconSvg }} />
  );
};

export default IconGenerator;
