import React, { createContext, useState } from 'react';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
  

    // Add other session states and functions as needed

    return (
        <SessionContext.Provider value={{ messages, setMessages }}>
            {children}
        </SessionContext.Provider>
    );
};
