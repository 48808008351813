import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { SierraSearchContext } from '../../../Auth/AppContexts/SierraSearchContext';
import './SierraSearch.css';
import SierraSearchResultItem from './SierraSearchResultItem/SierraSearchResultItem'; // Update the path as necessary




const SierraSearch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const searchRef = useRef(null);
    const { searchResults, setSelectedToken, fetchTokens } = useContext(SierraSearchContext);
    const navigate = useNavigate(); 


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.length > 2) {
            fetchTokens(e.target.value); // Trigger fetching tokens based on the query
        }
    };

   const handleSelectToken = (tokenData) => {
        setSelectedToken(tokenData);
        setShowDropdown(false);
        setSearchTerm('');
        navigate(`/dashboard/sierra-ai/t/${tokenData.address}`);
    };



    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (showDropdown && searchRef.current && !searchRef.current.contains(e.target)) {
                setShowDropdown(false);
            }
        };

        // Add event listener to check if clicked outside of the component
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showDropdown]);

    return (
        <div className="sierra-searchbar-container" ref={searchRef}>
            <input
                type="text"
                className="sierra-search-input"
                placeholder="Search for a token on Solana..."
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setShowDropdown(true)}
            />
           {showDropdown && (
            <div className="sierra-search-dropdown">
                {searchResults.map(tokenData => (
                    <SierraSearchResultItem key={tokenData.address} tokenData={tokenData} onSelectToken={handleSelectToken} />
                ))}
            </div>
        )}

        </div>
    );
};

export default SierraSearch;