// WalletTagComponent.js
import React from 'react';
import './WalletTagComponent.css';

const WalletTagComponent = () => {
    return (
        <span className="wallet-tag-display">Wallet</span>
    );
};

export default WalletTagComponent;
