import React, { useRef, useEffect, useState, useContext } from 'react';
import './ChatInputBox.css';
import { ReactComponent as ChatSendIcon } from './ChatSendIcon.svg';
import { ReactComponent as ChatSendIconDark } from '../../Media/Icons/SendIconWhite.svg';
import LoadingComponent from '../../ReusableUtility/LoadingComponent'; // Update the import path as necessary

import { useSwap } from '../../Auth/AppContexts/SwapContext';
import { useSendTransaction } from '../../Auth/AppContexts/SendTransactionContext';
import { fetchTokenList } from '../../APIFunctions/FetchTokenList'; // Adjust the import path as needed
import { SierraSearchContext } from '../../Auth/AppContexts/SierraSearchContext'; 
import { formatSOLAmount } from '../../ReusableUtility/FormatNumberWithCommas';
import { useTheme } from '../../Auth/AppContexts/ThemeContext';





const ChatInputBox = ({ addMessage, inputText, setInputText, className, onExecuteSwap, isWalletConnected }) => {
    const textAreaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
   const { updateSwapQuotes, swapQuotes } = useSwap()
     const { addTransaction, setTransactionStatus, prepareTransactionForConfirmation } = useSendTransaction();
     const { selectedToken, additionalTokenDetails, setSelectedTokenByAddress} = useContext(SierraSearchContext);
     const { theme } = useTheme(); 



    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [inputText]);



    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendClick();
        }
    };



    const handleTextChange = (e) => {
        setInputText(e.target.value);
    };






    const isSolana = (tokenAddress) => tokenAddress === "So11111111111111111111111111111111111111112";



    //extracts token address for Sierra when a user inputs a token address alongside a conversational sentence
    const extractTokenAddress = (inputText) => {
    const addressRegex = /[1-9A-HJ-NP-Za-km-z]{43,44}/;
    const matches = inputText.match(addressRegex);
    return matches ? matches[0] : null;
};



const handleSendClick = async () => {
    if (!inputText.trim()) return;
    setIsLoading(true);
    addMessage({ type: 'user', text: inputText });
    //console.log("Input text received:", inputText);
    setInputText('');

    const actionType = getActionTypeFromInput(inputText);
    //console.log("Determined action type:", actionType);





    // Handle the token info action type separately
if (actionType === 'token_info') {
    const tokenAddress = extractTokenAddress(inputText);
    if (!tokenAddress) {
        console.error('Token address not found in input');
        setIsLoading(false);
        return;
    }

    try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const tokenInfoUrl = `${baseUrl}/sierra-token-info?address=${tokenAddress}`;
        //console.log("Fetching token details from:", tokenInfoUrl); // Additional log for debugging
        const tokenResponse = await fetch(tokenInfoUrl);
        if (!tokenResponse.ok) {
            throw new Error(`Failed to fetch token details: ${tokenResponse.statusText}`);
        }
        const tokenData = await tokenResponse.json();

        // Fetch token authority data without disrupting existing logic
        const tokenAuthorityUrl = `${baseUrl}/token-authority-check?tokenAddress=${tokenAddress}`;
        const authorityResponse = await fetch(tokenAuthorityUrl);
        if (authorityResponse.ok) {
            const authorityData = await authorityResponse.json();
            tokenData.authorities = {
                mintAuthorityDisabled: !authorityData.hasMintAuthority,
                freezeAuthorityDisabled: !authorityData.hasFreezeAuthority
            };
        } else {
            console.error('Failed to fetch token authorities:', authorityResponse.statusText);
        }

    // New integration for fetching additional token security details
    const token360ReportUrl = `${baseUrl}/token-360-report?tokenAddress=${tokenAddress}`;

    const reportResponse = await fetch(token360ReportUrl);
    if (reportResponse.ok) {
        const reportData = await reportResponse.json(); 

    // Check if the expected data, e.g., lpLockedPct, is present
    if (reportData.hasOwnProperty('lpLockedPct')) {
    } else {
        console.warn("Warning: LP Locked Percentage is missing in the received data");
    }
    // Append the fetched market details to the tokenData
        tokenData.securityDetails = reportData;
    } else {
        console.error('Failed to fetch token 360 report:', reportResponse.statusText); // Log failure status
    }
            addMessage({
                type: 'system',
                messageType: 'token_info',
                token: tokenData
            });
        } catch (error) {
            console.error('Error fetching token info:', error);
        }
        setIsLoading(false);
        return;
    }




 // Determine the command to use based on the action type
    const commandToUse = actionType === 'send' ? inputText : actionType === 'trending' ? inputText : `${inputText} ${selectedToken?.address || ''}`;
    //console.log("Command to use:", commandToUse);

    // Determine the endpoint based on the action type
    let endpoint;
    if (actionType === 'send') {
        endpoint = '/api/parse-send-transaction';
    } else if (actionType === 'trending') {
        endpoint = '/api/get-trending-tokens';
    } else {
        endpoint = '/api/ai-parse-command';
    }

    //console.log("Endpoint to call:", endpoint);

    // Construct the request body based on the action type
    const requestBody = {
        command: commandToUse,
        ...(actionType !== 'send' && actionType !== 'trending' ? { selectedTokenAddress: selectedToken?.address } : {})
    };

    
    //console.log("Request body:", requestBody);

    try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const parseResponse = await fetch(`${baseUrl}${endpoint}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        });

        const parsedData = await parseResponse.json();
       
        if (parsedData.success && parsedData.action === 'send') {
            const transactionDetails = {
                transactionId: `TX-${Math.random().toString(36).substring(2, 8).toUpperCase()}`,
                recipientAddress: parsedData.recipient_address,  // Make sure this key is correct
                amount: parsedData.quantity + " " + parsedData.source_currency,  // Append currency to quantity
                action: 'send',
            };
            //console.log("Transaction details prepared for confirmation:", transactionDetails);

            prepareTransactionForConfirmation(transactionDetails);
            addMessage({
                type: 'system',
                text: `Confirm sending ${transactionDetails.amount} SOL to ${transactionDetails.recipientAddress}?`,
                messageType: 'send',
                transactionDetails,
            });

      

            
      //TRENDING TOKENS LOGIC

      
        } else if (parsedData.success && actionType === 'trending') {
    //console.log("Parsed data for trending tokens:", parsedData);  // Log the full parsedData object

    // Extract and validate tokens array
       const trendingTokens = parsedData.tokens.data.tokens;


    if (!trendingTokens || !Array.isArray(trendingTokens)) {
        console.error('Trending tokens data is missing or malformed', { trendingTokens });  // Log the tokens data
        throw new Error('Trending tokens data is missing or malformed');
    }


     addMessage({
        type: 'system',
        messageType: 'trending',
        tokens: trendingTokens  
    });




            //SWAP LOGIC BELOW



        } else if (parsedData.action && parsedData.quantity && parsedData.source_currency && parsedData.target_currency) {
            
            const tokens = await fetchTokenList();
            const inputTokenInfo = tokens.find(token => token.address === parsedData.source_currency);
            const outputTokenInfo = tokens.find(token => token.address === parsedData.target_currency);

            if (!inputTokenInfo || !outputTokenInfo) {
                throw new Error('Token information not found for given mint addresses');
            }

            const quoteResponse = await fetch(`${baseUrl}/api/swapQuote`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    inputMint: parsedData.source_currency,
                    outputMint: parsedData.target_currency,
                    amount: parsedData.quantity,
                    slippageBps: parsedData.slippageBps,
                }),
            });

            if (!quoteResponse.ok) {
                const errorDetails = await quoteResponse.json();
                throw new Error(`Failed to fetch swap quote: ${quoteResponse.status} - ${errorDetails.message}`);
            }

            const quoteData = await quoteResponse.json();
            const quoteId = `BF-${Math.random().toString(36).substring(2, 8).toUpperCase()}`;

            const newQuoteDetails = {
                quoteId,
                formattedInAmount: inputTokenInfo.decimals === 9 ? formatSOLAmount(quoteData.inAmount / Math.pow(10, inputTokenInfo.decimals)) : (Number(quoteData.inAmount) / Math.pow(10, inputTokenInfo.decimals)).toFixed(2),
                formattedOutAmount: outputTokenInfo.decimals === 9 ? formatSOLAmount(quoteData.outAmount / Math.pow(10, outputTokenInfo.decimals)) : (Number(quoteData.outAmount) / Math.pow(10, outputTokenInfo.decimals)).toFixed(2),
                slippagePercentage: (quoteData.slippageBps / 100).toFixed(2),
                inputTokenSymbol: inputTokenInfo.symbol,
                outputTokenSymbol: outputTokenInfo.symbol,
                rawQuote: quoteData,
            };

            updateSwapQuotes([...swapQuotes, newQuoteDetails]);
            addMessage({
                type: 'system',
                text: `Swap quote ID: ${quoteId} - ${newQuoteDetails.formattedOutAmount} ${outputTokenInfo.symbol} for ${newQuoteDetails.formattedInAmount} ${inputTokenInfo.symbol} with ${newQuoteDetails.slippagePercentage}% slippage.`,
                quoteId: quoteId,
                messageType: 'swap',
            });
        } else {
            addMessage({
                type: 'ai',
                text: 'Command not actionable or missing data',
                messageType: 'swap',
            });
        }
    } catch (error) {
        console.error('Error:', error);
        addMessage({ type: 'error', text: `Error: ${error.toString()}` });
    } finally {
        setInputText('');
        setIsLoading(false);
    }
};



const isTokenAddress = (input) => {
   
    const result = /^[1-9A-HJ-NP-Za-km-z]{43,44}$/i.test(input);  // Added 'i' for case-insensitivity
    //console.log(`isTokenAddress check for ${input}: ${result}`);
    return result;
};


const containsTokenAddress = (input) => {
    const words = input.split(/\s+/);
    return words.some(word => {
        const isAddress = isTokenAddress(word);
        //console.log(`Checking word: ${word}, is address: ${isAddress}`);
        return isAddress;
    });
};



// Utility function to determine the action type from the input text
const getActionTypeFromInput = (inputText) => {
    const lowerCaseInput = inputText.toLowerCase();

    // First check for token address presence
    if (containsTokenAddress(lowerCaseInput)) {
        return 'token_info';
    }

    // Checking for transactional intents
    if (lowerCaseInput.includes('send')) {
        return 'send';
    } else if (lowerCaseInput.includes('buy') || lowerCaseInput.includes('sell')) {
        return 'trade';
    }
    // Checking for trending tokens query
    else if (
        lowerCaseInput.includes('trending tokens') ||
        lowerCaseInput.includes('popular tokens') ||
        lowerCaseInput.includes('hot tokens') ||
        lowerCaseInput.includes('most active tokens') ||
        lowerCaseInput.includes('top tokens') ||
        lowerCaseInput.includes('best performing tokens')
    ) {
        return 'trending';
    }
    // Expanded query detection patterns
    else if (
        lowerCaseInput.includes('how much') || 
        lowerCaseInput.includes('what is the') ||
        lowerCaseInput.includes('details about') ||
        lowerCaseInput.includes('information on') ||
        lowerCaseInput.includes('current price of') ||
        lowerCaseInput.includes('supply of') ||
        lowerCaseInput.includes('market cap') ||
        lowerCaseInput.includes('token info') ||
        lowerCaseInput.startsWith('explain') || // Starting with "explain" might indicate a query for information
        lowerCaseInput.includes('transaction status of') ||
        lowerCaseInput.includes('tx history') ||
        lowerCaseInput.includes('wallet balance') ||
        lowerCaseInput.includes('address info') ||
        lowerCaseInput.includes('staking rewards') ||
        lowerCaseInput.includes('yield for') ||
        lowerCaseInput.includes('vs') || // For comparisons
        lowerCaseInput.includes('compare') ||
        lowerCaseInput.startsWith('how to') ||
        lowerCaseInput.startsWith('guide on') ||
        lowerCaseInput.includes('historical price of') ||
        lowerCaseInput.includes('price trend') ||
        lowerCaseInput.includes('latest news on') ||
        lowerCaseInput.includes('updates on') ||
        lowerCaseInput.includes('smart contract details') ||
        lowerCaseInput.includes('blockchain explorer') ||
        lowerCaseInput.includes('how do I') ||
        lowerCaseInput.includes('can you tell me about') ||
        lowerCaseInput.includes('learn more') ||
        lowerCaseInput.includes('interested in') ||
        lowerCaseInput.includes('where can I find')
    ) {
        return 'query';
    }
    return 'query'; // Default to query for other inputs not covered above
};







//calls backend for token query 
 const handleTokenQuery = async (query) => {
        // Using additionalTokenDetails and selectedToken directly, as per correct useContext usage
        const tokenDetails = additionalTokenDetails[selectedToken.address];
        const baseUrl = process.env.REACT_APP_BASE_URL || '';

        try {
            const response = await fetch(`${baseUrl}/api/parse-token-query`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    question: query,
                    tokenData: tokenDetails // Now sending detailed token data
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to parse token query');
            }

            const responseData = await response.json();
            //console.log("Response data from server:", responseData); // Confirming the received data

            // Reintegrating the addMessage logic to handle the response
            if (responseData.success && responseData.response) {
                addMessage({
                    type: 'system',
                    text: responseData.response, // Using 'response' as per the server's response structure
                    messageType: 'info',
                });
            } else {
                console.error("No success or no proper response in responseData:", responseData);
            }
        } catch (error) {
            console.error('Error fetching token information:', error);
            addMessage({
                type: 'system',
                text: 'Sorry, there was a problem fetching the token information.', // Provide feedback in case of an error
                messageType: 'error',
            });
        }
    };








    return (
        <div className={`chat-input-container ${className || ''}`}>
            <textarea
                ref={textAreaRef}
                className="chat-input"
                placeholder={isLoading ? '' : 'Type your message here...'}
                value={inputText}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                disabled={isLoading}
            ></textarea>
            {isLoading ? (
                <div className="loader-container">
                    <LoadingComponent loading={true} />
                </div>
            ) : (
                <button className="chat-send-button" onClick={handleSendClick} disabled={isLoading || !inputText.trim()}>
                {theme === 'dark' 
                    ? <ChatSendIconDark className="chat-send-icon-dark" /> 
                    : <ChatSendIcon className="chat-send-icon-light" />}
            </button>

            )}
        </div>
    );
};

export default ChatInputBox;
