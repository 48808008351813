// WalletDataButtonNavigation.js
import React from 'react';
import Button from '../../../Buttons/Button'; 
import './WalletPageDataButtonNavigation.css';

const WalletDataNavigation = ({ onNavigate }) => {
  // Define a handler for button click events that invokes a callback with the target component's name
  const handleNavigationClick = (targetComponent) => {
    onNavigate(targetComponent);
  };

  return (
    <div className="wallet-data-navigation"> {/* Unique class name */}
      <Button onClick={() => handleNavigationClick('transfers')} text="Transactions" />
     
      {/* You can add more buttons as needed */}
    </div>
  );
};

export default WalletDataNavigation;
