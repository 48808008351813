// ReferralCard.js


import React from 'react';
import './ReferCards.css';

const ReferralCard = ({ title, value, children }) => {
  return (
    <div className="referral-card">
      <h2>{title}</h2>
      {/* Apply a specific class for the value */}
      {value ? <p className="referral-card-value">{value}</p> : children}
    </div>
  );
};

export default ReferralCard;
