import React from 'react';
import './TokenInfoPanel.css'; 

const TokenInfoPanel = ({ selectedToken }) => {
    if (!selectedToken) {
        return <div className="token-info-panel">Please select a token.</div>;
    }

    const defaultImage = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png';

    return (
        <div className="token-info-panel">
            {/* Token Image */}
            <img 
                src={selectedToken.logoURI || defaultImage} 
                alt={`${selectedToken.name} logo`} 
                className="token-logo" 
                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
            />
            {/* Token Information */}
            <div className="token-info">
                <div className="token-info-item"><h1>{selectedToken.name}</h1></div>
                <div className="token-info-item"><p>Price: {selectedToken.price}</p></div>
                {/* Additional token information here */}
            </div>
        </div>
    );
};

export default TokenInfoPanel;
