import React, { useState, useEffect, useRef } from 'react';
import Loading from 'react-loading';
import { ReactComponent as SearchIcon } from './SearchIcon.svg'; // Importing SVG as a React component
import './SearchBar.css';
import { fetchTokenPrice } from '../../APIFunctions/FetchTokenPrice';
import { fetchTokenList } from '../../APIFunctions/FetchTokenList';
import formatPrice from '../../ReusableUtility/FormatPrice'


const debounce = (func, delay) => {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const SearchBar = ({ onTokenSelect }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [tokenData, setTokenData] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef(null);


    const handleTokenSelection = (selectedToken) => {
    if (onTokenSelect) {
        onTokenSelect(selectedToken);
    }
};
     


    

    const handleFocus = () => {
        setShowDropdown(true);
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };



    const fetchAndSetTokenData = async (value) => {
    if (value) {
        const listData = await fetchTokenList(value);
        const tokenDataWithPrice = await Promise.all(listData.map(async token => {
            const priceData = await fetchTokenPrice(token.symbol.toUpperCase());
            // Use formatPrice to format the fetched price
            return { ...token, price: formatPrice(priceData?.price) };
        }));
        setTokenData(tokenDataWithPrice);
    } else {
        setTokenData([]);
    }
};




    const debouncedFetchTokenData = debounce(fetchAndSetTokenData, 500);

    useEffect(() => {
        if (searchTerm) {
            debouncedFetchTokenData(searchTerm);
        } else {
            setTokenData([]);
        }
    }, [searchTerm]);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    
    return (
        <div className="headerbar-search-container" ref={ref}>
        <SearchIcon className="search-icon" />
        <input
            type="text"
            className="headerbar-search-input"
            onFocus={handleFocus}
            onChange={handleInputChange} // Updated to handleInputChange
            placeholder="Search Tokens..."
            value={searchTerm}
        />
        {showDropdown && (
            <div className="searchbar-dropdown">
                {/* Header row */}
                <div className="searchbar-header-row">
                     <span className="searchbar-heading token-name">Token</span>
                        <span className="searchbar-heading symbol">Symbol</span>
                        <span className="searchbar-heading price">Price</span>
                        <span className="searchbar-heading volume">Volume</span>
                        <span className="searchbar-heading change">Chg %</span>
                </div>
                {/* Item rows */}
                {tokenData.map((token, index) => (
                    


                    <div className="searchbar-session-item" 
                         key={index}
                         onClick={() => handleTokenSelection(token)} >

                        <img 
                            src={token.logoURI || 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png'} 
                            alt={`${token.name} logo`} 
                            className="token-logo" 
                            onError={(e) => { e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png'; }}
                        />
                        <span className="token-name">{token.name || 'N/A'}</span>
                        <span className="symbol">{token.symbol || 'N/A'}</span>
                         <span className="price">${formatPrice(token.price)}</span>
                        <span className="volume">{/* Volume data placeholder */}</span>
                        <span className="change">{/* Chg % data placeholder */}</span>
                    </div>
                ))}
            </div>
        )}
    </div>

    );
};

export default SearchBar;
