import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-identicon-sprites';
import './UserChatMessageItem.css';
import chatUserAvatarPath from './ChatUserAvatar.svg'; // Import the SVG directly
import { UserContext } from '../../Auth/AppContexts/UserContext';
import { ReactComponent as CopyClipboardIconLight } from '../../Media/Icons/CopyClipboardIcon.svg';
import { ReactComponent as CopyClipboardIconDark } from '../../Media/Icons/CopyIconDarkMode.svg';
import { ReactComponent as TickIcon } from '../../Media/Icons/TickIcon.svg';
import { useTheme } from '../../Auth/AppContexts/ThemeContext';

const UserChatMessageItem = ({ text }) => {
    const { avatarURL, userWalletAddress } = useContext(UserContext);
    const { theme } = useTheme();
    const [showTick, setShowTick] = useState(false); // State to control icon display

    const handleCopyClick = () => {
        // Simulate the copy action here
        navigator.clipboard.writeText(text).then(() => {
            setShowTick(true); // Show the tick mark
            setTimeout(() => setShowTick(false), 3000); // After 3 seconds, revert to the copy icon
        }, () => {
            // Handle copy failure if necessary
            console.error('Copy failed!');
        });
    };

    const generateAvatar = (seed) => {
        return createAvatar(style, {
            seed: seed,
            baseColor: 'random',  // Use a random base color
            colorLevel: 500,      // Set color level (100 - 900)
            saturation: 9.5,      // Set the color saturation (0 - 1)
            brightness: 995,      // Set the brightness (0 - 1)
        });
    };

    return (
        <div className="user-chat-message-item">
            <div className="user-message-header">
                {avatarURL
                    ? <img src={avatarURL} alt="Chat User Avatar" className="user-avatar-icon" />
                    : userWalletAddress
                        ? <div dangerouslySetInnerHTML={{ __html: generateAvatar(userWalletAddress) }} className="user-avatar-icon" />
                        : <img src={chatUserAvatarPath} alt="Default User Avatar" className="user-avatar-icon" />
                }
                <p className="user-message-text">{text}</p>
                {showTick ? (
                    <TickIcon className="tick-icon-user-message" />
                ) : (
                    <div onClick={handleCopyClick}>
                        {theme === 'dark' ? <CopyClipboardIconDark className="copy-icon-dark" /> : <CopyClipboardIconLight className="copy-icon-light" />}
                    </div>
                )}
            </div>
        </div>
    );
};

UserChatMessageItem.propTypes = {
    text: PropTypes.string.isRequired,
};

export default UserChatMessageItem;
