// TGEPage.js
import React, { useState } from 'react';

import TokenCard from './TGECards/TGECards';
import BlackfinTokenDetails from './TGETokenDetailsPanel/BlackfinTokenDetails';
import BlackfinChart from './BlackfinChart/BlackfinChart'; 
import './TGEPage.css';
import { ReactComponent as CopyIcon } from '../../Media/Icons/CopyIconDarkMode.svg';
import { ReactComponent as TickIcon } from '../../Media/Icons/TickIcon.svg';
import SidePanel from '../../Components/SidePanel/SidePanel';
import TGEIntroModal from './CountdownIntroModal/TGEIntroModal'; 





const TGEPage = () => {
    const [copySuccess, setCopySuccess] = useState(false);
     const [isModalOpen, setIsModalOpen] = useState(true); 

  
   // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    localStorage.setItem('tgeIntroSeen', 'true'); // Optionally save to localStorage that the intro has been seen
  };



   const fullTokenAddress = "BLKFNuCET9ZnmhFczGXmcWKSap9kGtTFnosvA9P5majk";
  const shortTokenAddress = `${fullTokenAddress.slice(0, 6)}...${fullTokenAddress.slice(-6)}`;
  const tokenExplorerUrl = `https://www.blackfinexplorer.com/dashboard/explorer/t/${fullTokenAddress}`;


 

const copyToClipboard = () => {
    navigator.clipboard.writeText(fullTokenAddress).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000); 
    });
  };


  
const tokenInfo = {
    title: "Blackfin Token Info",
    columns: [
      { subtitle: "NAME & TICKER", main: "Blackfin AI (BLKFN)" },
      {
        subtitle: "TOKEN ADDRESS",
        main: (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <a href={tokenExplorerUrl} className="token-info-link" target="_blank" rel="noopener noreferrer">
              {shortTokenAddress}
            </a>
            <div style={{ position: 'absolute', top: '-2px', left: '-22px' /* Adjust as needed */ }}>
              {copySuccess ? (
                <TickIcon className="tge-tick-icon" />
              ) : (
                <CopyIcon className="tge-copy-icon" onClick={copyToClipboard} />
              )}
            </div>
          </div>
        ),
        url: null
      },
      { subtitle: "TOKENOMICS", main: "Learn more", url: "https://blackfin-explorer.gitbook.io/welcome-to-blackfin/" }
    ]
  };


  const tradingInfo = {
    title: "Trading & Liquidity Venues",
    columns: [
      { subtitle: "Jupiter DEX", main: "Buy BLKFN" },
      { subtitle: "CEX", main: "Coming Soon" }
    ]
  };




  return (
    
    <div className="tge-container">

 <SidePanel />
    {isModalOpen && <TGEIntroModal isOpen={isModalOpen} onClose={handleCloseModal} />}
    
      <div className="token-overview-container">
        <TokenCard title="Price" value="$0.005" />

         <TokenCard title="Circulating Market Cap" value="$50K" />

          <TokenCard title="Circulating Supply" value="10M" />


            
        <TokenCard title="Fully Diluted Value (FDV)" value="$500K" />



        <TokenCard title="Total Supply" value="100M" />
      
       
       
      </div>
      <div className="token-details-chart-container"> 
        <BlackfinTokenDetails data={tokenInfo} />
        <BlackfinTokenDetails data={tradingInfo} />
        <BlackfinChart /> 
      </div>
    </div>
  );
};

export default TGEPage;
