// ReferralPage.js

import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../Auth/AppContexts/UserContext';
import { useWallet } from '@solana/wallet-adapter-react';


import './ReferUserPage.css';
import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu';
import ReferralCard from '../../Components/ExplorerFeature/ReferPage/ReferUICards/ReferCards';
import ReferMainTable from '../../Components/ExplorerFeature/ReferPage/ReferPointsTable/ReferMainTable';
import ReferLink from '../../Components/ExplorerFeature/ReferPage/ReferURL/ReferLink';




const ReferralPage = () => {
     const [referralUrl, setReferralUrl] = useState('');
    const { userId } = useContext(UserContext);
    const [successfulReferrals, setSuccessfulReferrals] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [leaderboardUsername, setLeaderboardUsername] = useState('');
const [referralPoints, setReferralPoints] = useState(0);
const [leaderboardData, setLeaderboardData] = useState([]);
 const [leaderboardRank, setLeaderboardRank] = useState(null);
 const { connected } = useWallet();





 useEffect(() => {
    if (userId && connected) {
        // Fetch referral code
        fetch(`${process.env.REACT_APP_USER_API_URL}/api/get-referral-code?userId=${userId}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.referralCode) {
                    const newReferralUrl = `${process.env.REACT_APP_BLACKFIN_CLIENT_URL}/refer?code=${data.referralCode}`;
                    setReferralUrl(newReferralUrl);
                } else {
                    //console.log('Referral code not found in response data');
                    setReferralUrl(''); // Reset referral URL if not found
                }
            })
            .catch(error => {
                console.error('Error fetching referral code:', error);
                setReferralUrl(''); // Reset referral URL on error
            });

        // Fetch user referral data
        fetch(`${process.env.REACT_APP_USER_API_URL}/api/fetch-user-referral-data?userId=${userId}`)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setSuccessfulReferrals(data.totalReferrals);
                    setTotalPoints(data.totalPoints);
                    setLeaderboardUsername(data.username);
                    setReferralPoints(data.referralPoints); 
                } else {
                    // Reset these states if no data is found
                    setSuccessfulReferrals(0);
                    setTotalPoints(0);
                    setLeaderboardUsername('');
                    setReferralPoints(0);
                }
            })
            .catch(error => {
                console.error('Error fetching user referral data:', error);
                // Optionally reset states on error
            });


            
          // Fetch leaderboard data
        fetch(`${process.env.REACT_APP_USER_API_URL}/api/get-leaderboard`)
            .then(response => response.json())
            .then(data => {
                setLeaderboardData(data);
                // Find the index of the user in the leaderboard data and add 1 to get the rank
                const userRankIndex = data.findIndex(u => u.id === userId); // Use 'id' field for comparison
                const userRank = userRankIndex >= 0 ? userRankIndex + 1 : null;
                setLeaderboardRank(userRank); // set the rank in the state
            })
            .catch(error => {
                console.error('Error fetching leaderboard data:', error);
                setLeaderboardData([]);
                setLeaderboardRank(null); // reset the rank if there's an error
            });




        





    } else {
        // Reset all states when no user is connected or wallet is disconnected
        setReferralUrl('');
        setSuccessfulReferrals(0);
        setTotalPoints(0);
        setLeaderboardUsername('');
        setReferralPoints(0);
        setLeaderboardData([]);
        setLeaderboardRank(null);
        
    }
}, [userId, connected]);








    return (
        <div className="referral-container">
            <SidePanel />
            <div className="refer-user-main-content">
                <HeaderMenu />
                <div className="referral-overview-container">
                    <ReferralCard title="Your Referral Link">
                        <ReferLink url={referralUrl} />
                    </ReferralCard>
                    <ReferralCard title="Leaderboard Rank" value={leaderboardRank ? `#${leaderboardRank}` : 'Not ranked'} />
                    <ReferralCard title="Successful Referrals" value={successfulReferrals.toString()} />
                    <ReferralCard title="Total Points" value={totalPoints.toString()} />
                </div>
                <ReferMainTable 
                leaderboardUsername={leaderboardUsername} 
                referralPoints={referralPoints}
                totalPoints={totalPoints}
                 data={leaderboardData}
                
                
                
                
                
                
                
                />
            </div>
        </div>
    );
}

export default ReferralPage;


//changes mde
