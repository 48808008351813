import React, { createContext, useContext, useState } from 'react';

const SendTransactionContext = createContext();

export const SendTransactionProvider = ({ children }) => {
    const [transactions, setTransactions] = useState({});
    const [transactionStatuses, setTransactionStatuses] = useState({});
    const [pendingTransaction, setPendingTransaction] = useState({});

    

    // Method to add a new transaction to the context
    const addTransaction = (transactionDetails) => {
        //console.log('Adding transaction with details:', transactionDetails);
        const transactionId = transactionDetails.transactionId;
        setTransactions(prevTransactions => {
            const updatedTransactions = { ...prevTransactions, [transactionId]: transactionDetails };
            //console.log('Transactions after adding new one:', updatedTransactions);
            return updatedTransactions;
        });
    };

    // Method to update the status of a transaction
    const setTransactionStatus = (transactionId, status, txHash = null) => {
        //console.log(`Updating status for transactionId ${transactionId} to '${status}', txHash: '${txHash}'`);
        setTransactionStatuses(prevStatuses => {
            const updatedStatuses = { ...prevStatuses, [transactionId]: { ...prevStatuses[transactionId], status, txHash } };
            //console.log('Transaction statuses after update:', updatedStatuses);
            return updatedStatuses;
        });
    };

    // New method to prepare a transaction for confirmation
    const prepareTransactionForConfirmation = (transactionDetails) => {
        //console.log('Preparing transaction for confirmation:', transactionDetails);
        setPendingTransaction(transactionDetails);
    };

    // Method to clear the pending transaction (after confirmation or cancellation)
    const clearPendingTransaction = (transactionId) => {
    setPendingTransaction(prev => {
        const newState = { ...prev };
        delete newState[transactionId]; // Remove the specific transaction
        return newState;
    });
};




    

    return (
        <SendTransactionContext.Provider value={{
            transactions,
            addTransaction,
            transactionStatuses,
            setTransactionStatus,
            pendingTransaction,
            prepareTransactionForConfirmation,
            clearPendingTransaction
        }}>
            {children}
        </SendTransactionContext.Provider>
    );
};

export const useSendTransaction = () => useContext(SendTransactionContext);
