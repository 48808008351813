// UsernameLink.js
import React from 'react';
import './UsernameWalletLink.css';

const UsernameLink = ({ username, walletAddress }) => {
    return (
        <a href={`/dashboard/explorer/w/${walletAddress}`} className="username-link-style">
            {username}
        </a>
    );
};

export default UsernameLink;
