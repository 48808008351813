import React, { useState } from 'react';
import './WalletInfoComponent.css'; 
import { ReactComponent as TickIcon } from '../../../../Media/Icons/TickIcon.svg';
import { ReactComponent as CopyClipboardIcon } from '../../../../Media/Icons/CopyClipboardIcon.svg';
import { numberWithCommas } from '../../../../ReusableUtility/FormatNumberWithCommas';
import ModalToDisplayAllDomainNames from './ModalToDisplayAllDomainNames'; 


const WalletInfoComponent = ({ totalWalletValue, solanaDomains, decimals, mintAuthority, address }) => {
  const [copiedAddress, setCopiedAddress] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);

   const handleDomainClick = (domainName) => {
    navigator.clipboard.writeText(domainName).then(() => {
      // You can implement some state to show a copied confirmation if desired
      console.log(`Copied ${domainName} to clipboard`);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };


     // Update formatValue to handle undefined and non-numeric values
  const formatValue = (value) => {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'number') return numberWithCommas(value.toFixed(2));
    return numberWithCommas(Number(value).toFixed(2));
  };


  const handleCopy = (text, type) => {
    if (text && text !== 'N/A') {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedAddress(type);
        setTimeout(() => {
          setCopiedAddress(''); // Reset copied address after 2 seconds
        }, 2000);
      });
    }
  };

   const formattedTotalWalletValue = numberWithCommas((totalWalletValue || 0).toFixed(2));
  
   
  // Display only the first five domains initially
  const displayedDomains = solanaDomains.slice(0, 3);
  const additionalDomainsCount = solanaDomains.length - 3;


   return (
     <div className="wallet-info-container">
    <h2 className="info-title">Info</h2>

    <div className="info-item">
      <span className="info-label">TOTAL WALLET VALUE ($)</span>
      <span className="info-data">${formattedTotalWalletValue}</span>
    </div>

    <div className="info-item">
      <span className="info-label">ADDRESS</span>
      <span className="info-data">
        {formatValue(address)}
        {address && address !== 'N/A' && (
          <span className="copy-icon" onClick={() => handleCopy(address, 'address')}>
            {copiedAddress === 'address' ? <TickIcon className="icon" /> : <CopyClipboardIcon className="icon" />}
          </span>
        )}
      </span>
    </div>

    <div className="info-item">
      <span className="info-label">LAST TX SENT</span>
      <span className="info-data">{formatValue(mintAuthority)}</span>
    </div>

    <div className="info-item">
      <span className="info-label">ASSOCIATED SOL DOMAINS</span>
      <div className="domain-names-container">
        {solanaDomains.length > 0 ? (
          <>
            {solanaDomains.slice(0, 3).map((domain, index) => (
              <div key={index} className="wallet-info-domain-name" onClick={() => handleDomainClick(domain.name || domain)}>
                {domain.name || domain}
              </div>
            ))}
            {additionalDomainsCount > 0 && (
              <div className="more-domains-indicator" onClick={openModal}>
                +{additionalDomainsCount} more
              </div>
            )}
          </>
        ) : (
          <div className="no-domains-info">No domains</div> // Display when no domains are associated
        )}
      </div>
    </div>

    {isModalOpen && (
      <ModalToDisplayAllDomainNames
        solanaDomains={solanaDomains}
        closeModal={() => setIsModalOpen(false)}
      />
    )}
  </div>
  );
};

export default WalletInfoComponent;
