import React, { useState, useEffect, useRef } from 'react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { ReactComponent as WalletIcon } from '../../../Media/Icons/NewWalletIcon.svg'; 
import { useWallet } from '@solana/wallet-adapter-react';
import './CustomStylesForWallet.css';

const CustomWalletConnectButton = () => {
    const { connected, disconnect } = useWallet();
    const { setVisible } = useWalletModal();
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleOpenModal = () => {
        if (!connected) {
            setVisible(true); // Opens the wallet selection modal
        } else {
            setShowDropdown(!showDropdown); // Toggles the dropdown
        }
    };

    const handleDisconnect = () => {
        disconnect(); // Disconnects the wallet
        setShowDropdown(false); // Closes the dropdown
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    return (
        <div className="wallet-connect-wrapper">
            <button 
                onClick={handleOpenModal} 
                className={`wallet-connect-button ${connected ? 'connected' : ''}`}
            >
                <WalletIcon />
            </button>
            {connected && showDropdown && (
                <div ref={dropdownRef} className="wallet-dropdown">
                    <button onClick={handleDisconnect}>Disconnect</button>
                    {/* Include other options like 'Copy address' and 'Change wallet' here */}
                </div>
            )}
        </div>
    );
};

export default CustomWalletConnectButton;
