

//ChatMessageList.js to render the message items --manage the order of the messages and handle rendering of different message types.


import React, { useEffect, useRef, useState } from 'react';
import AIChatMessageItem from './AIChatMessageItem';
import UserChatMessageItem from './UserChatMessageItem';
import SierraTokenInfoComponent from './SierraTokenInfoComponent'; 
import './ChatMessageList.css';
import { useSwap } from '../../Auth/AppContexts/SwapContext';
import { useSendTransaction } from '../../Auth/AppContexts/SendTransactionContext';
import { formatNumber } from '../../ReusableUtility/FormatNumberWithCommas';




const ChatMessageList = ({ messages, onExecuteSwap, onExecuteTransaction, addMessage, handleTokenClick  }) => {
    const chatContainerRef = useRef(null);
    const { swapStatuses } = useSwap();
    const { pendingTransaction } = useSendTransaction();
    const welcomeMessageAddedRef = useRef(false);
    

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);




  useEffect(() => {
  if (!welcomeMessageAddedRef.current && messages.length === 0) {
    //console.log("Adding welcome message.");
    const welcomeMessageHTML = `
      <div>
        Welcome to Sierra AI! ✨ 
        Here's what you can do, but please remember we are in early Beta:
        <ul>
          <li style="margin-bottom: 12px;">To get started, search for the asset you're interested in, and type your command.</li>
          <li style="margin-bottom: 12px;">You can execute trades by stating <strong>"Buy X SOL of this token" or "Sell X [token] at market"</strong></li>
          <li style="margin-bottom: 12px;">To increase or decrease slippage simply state it in your trade command: "Buy X SOL of this token with 5% slippage"</li>
          <li style="margin-bottom: 12px;">All trades are executed as USDC or SOL being the base currency & settlement currency.</li>
          <li style="margin-bottom: 12px;">You can also request information about a token, for example <strong>"Tell me more about this token" or "Give me market insights about this token"</strong></li>
        </ul>
        Just type your command, and our models will take care of the rest! 🚀
        <p><a href="https://blackfin-explorer.gitbook.io/welcome-to-blackfin/product-guides/execute-trades-using-sierra" target="_blank" class="TokenLinkStyle">Learn More</a></p>
      </div>
    `;

    const welcomeMessage = {
      type: 'system',
      messageType: 'info',
      text: welcomeMessageHTML,
    };

    addMessage(welcomeMessage);
    welcomeMessageAddedRef.current = true;
  }
}, [messages, addMessage]);


  // Log every message added to check what's being received and processed
    //useEffect(() => {
        //console.log("Current messages in state:", messages);
    //}, [messages]);




const renderMessageItem = (message, index) => {
        if (message.type === 'system') {

             if (message.messageType === 'info') {
            return (
                <div className="info-message-item" key={`info-${index}`}>
                    
                    <AIChatMessageItem
                        text={message.text}
                        isSystemMessage={true}
                        messageType={message.messageType}
                    />
                </div>
            );
         

        } else if (message.messageType === 'token_info') { // Handle token information messages
            return (
                <AIChatMessageItem
                    key={`token-info-${index}`}
                    text="Here are the key details and security insights I could find:" 
                    isSystemMessage={true}
                    messageType={message.messageType}
                    
                >
                    <SierraTokenInfoComponent tokenData={message.token} />
                </AIChatMessageItem>
            );
        }



            // Check if the message is related to a swap action
            if (message.messageType === 'swap' && message.quoteId) {
                const txHash = swapStatuses[message.quoteId]?.txHash;
                // Formatting the swap message
                const quoteIDMatch = message.text.match(/Swap quote ID: (\S+)/);

                const quoteID = quoteIDMatch ? quoteIDMatch[1] : '';
                const detailsText = message.text.replace(/Swap quote ID: \S+ - /, '');

                const formattedDetailsText = detailsText.replace(/([\d,]+(?:\.\d+)?)(\s\$\S*|\s\S+)?\sfor\s([\d,]+(?:\.\d+)?)(\s\$\S*|\s\S+)/, (match, amount1, symbol1 = '', amount2, symbol2 = '') => {
                // Ensure symbols are trimmed and formatted correctly
                const formattedAmount1 = formatNumber(amount1);
                const formattedAmount2 = formatNumber(amount2);
                return `You'll receive <strong>${formattedAmount1} ${symbol1.trim()}</strong> for <strong>${formattedAmount2} ${symbol2.trim()}</strong>`;
            });

                const userFriendlyMessage = `<strong>Swap Quote ID: ${quoteID}</strong><br/><br/>${formattedDetailsText}`;
                
                
            
                
                return (
                    <AIChatMessageItem
                        key={`system-${message.quoteId}`}
                        text={userFriendlyMessage}
                        isSystemMessage={true}
                        onExecuteSwap={onExecuteSwap}
                        quoteId={message.quoteId}
                        txHash={txHash}
                        messageType={message.messageType}
                    />
                );
            }
            // Check if the message is related to a send action
            else if (message.messageType === 'send' && pendingTransaction) {
                return (
                    <AIChatMessageItem
                        key={`system-send-${index}`}
                        text={`Confirm sending ${pendingTransaction.amount} SOL to ${pendingTransaction.recipientAddress}?`}
                        isSystemMessage={true}
                        onExecuteTransaction={onExecuteTransaction}
                        messageType={message.messageType}
                        transactionDetails={pendingTransaction}
                        message={message}
                    />
                );
            }

            



        // Formatting function for better readability
const formatValue = (value, type) => {
    if (type === 'currency') {
        return value ? `$${(value / 1e6).toFixed(1)}m` : "N/A";
    }
    if (type === 'percentage') {
        return value ? `${value.toFixed(2)}%` : "N/A";
    }
    return value ? value.toString() : "N/A";
}





            // Handling trending type messages
if (message.messageType === 'trending' && message.tokens) {
    return (
        <AIChatMessageItem
            key={`trending-${index}`}
            text="Here are the most recent trending tokens I could find:" 
            isSystemMessage={true}
            messageType={message.messageType}
        >
            <div>
                {message.tokens.map(token => (
                    <div key={token.address} onClick={() => handleTokenClick(token.address)} className="sierra-token-trending-container">
                        <img src={token.logoURI} alt={token.name} style={{ width: '20px', height: '20px', marginRight: '8px', borderRadius: '50%' }} />
                        <div>
                            <strong>{token.name} ({token.symbol})</strong>
                            <div>
                                MC: {formatValue(token.mc, 'currency')} | 
                                Vol: {formatValue(token.v24hUSD, 'currency')} | 
                                 
                                 24hr: <span style={{ color: token.v24hChangePercent >= 0 ? 'green' : 'red' }}>
                                    {formatValue(token.v24hChangePercent, 'percentage')}
                                </span>

                                
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </AIChatMessageItem>
    );
}








        } else if (message.type === 'user') {
            return <UserChatMessageItem key={`user-${index}`} text={message.text} />;
        } else if (message.type === 'ai') {
            return <AIChatMessageItem key={`ai-${index}`} text={message.text} />;
        }
        return null;
    };

        useEffect(() => {
    //console.log("Messages in ChatMessageList:", messages);
}, [messages]);



    return (
        <div ref={chatContainerRef} className="chat-message-list-container">
            {messages.map(renderMessageItem)}
        </div>
    );
};

export default ChatMessageList;