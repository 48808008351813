import React, { useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const { connected } = useWallet();
    const navigate = useNavigate();

    useEffect(() => {
        if (!connected) {
            navigate('/landing');
        } else {
            navigate('/dashboard/explorer'); // Redirect to /dashboard/trade
        }
    }, [connected, navigate]);

    return <div>Loading...</div>;
};

export default Dashboard;
