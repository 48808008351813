import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SierraSearchContext } from '../../../Auth/AppContexts/SierraSearchContext';
import TokenInfo from './SierraTokenInfo/SierraTokenInfo';
import TokenVolume from './TokenVolume/SierraTokenVolume';
import UserBalance from './UserSOLBalance/SierraUserSOLBalance';
import './SierraTradeInfoPanel.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles



const SierraTradeInfoPanel = ({ solBalance, tokenBalance, usdcBalance, tokenSymbol, isWalletConnected }) => {
    const { selectedToken, searchResults, tokenMetaInfo, additionalTokenDetails, fetchAdditionalTokenDetailsForURLShare } = useContext(SierraSearchContext);
    const { tokenAddress } = useParams();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // State to track mobile view

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (tokenAddress) {
            fetchAdditionalTokenDetailsForURLShare(tokenAddress);
        }
    }, [tokenAddress, fetchAdditionalTokenDetailsForURLShare]);

    const tokenDetails = additionalTokenDetails[selectedToken?.address] || searchResults.find(token => token.address === selectedToken?.address);
    const tokenPriceUsd = tokenDetails?.priceUsd;
    const tokenVolume = tokenDetails?.volume24h;
    const tokenSupply = tokenMetaInfo[selectedToken?.address]?.currentSupply;
    const marketCap = tokenPriceUsd && tokenSupply ? parseFloat(tokenPriceUsd) * parseFloat(tokenSupply) : null;





    const renderSwiperContent = () => (
        <Swiper
            spaceBetween={50}
            slidesPerView={2}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide><TokenInfo tokenData={selectedToken} /></SwiperSlide>
            <SwiperSlide><TokenVolume tokenAddress={selectedToken?.address} volumeData={tokenVolume} marketCap={marketCap} /></SwiperSlide>
            <SwiperSlide><UserBalance solBalance={solBalance} tokenBalance={tokenBalance} tokenSymbol={tokenSymbol} isWalletConnected={isWalletConnected} /></SwiperSlide>
        </Swiper>
    );

    return (
        <div className="sierra-trade-info-panel">
            {isMobile ? renderSwiperContent() : (
                <>
                    <TokenInfo tokenData={selectedToken} />
                    <TokenVolume tokenAddress={selectedToken?.address} volumeData={tokenVolume} marketCap={marketCap} />
                    <UserBalance solBalance={solBalance} tokenBalance={tokenBalance} usdcBalance={usdcBalance} tokenSymbol={tokenSymbol} isWalletConnected={isWalletConnected} />
                </>
            )}
        </div>
    );
};

export default SierraTradeInfoPanel;
