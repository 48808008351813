import React, { useContext } from 'react';
import { SierraSearchContext } from '../../../../Auth/AppContexts/SierraSearchContext';
import './SierraTokenInfo.css'; // Ensure you have the CSS file for styling
import { ReactComponent as ExternalLinkIcon } from '../../../../Media/Icons/ExternalLink.svg'; // Adjust the path as needed

const TokenInfo = () => {
    const { selectedToken, tokenMetaInfo } = useContext(SierraSearchContext);

    // Define Solana's specific properties
    const solanaAddress = "So11111111111111111111111111111111111111112";
    const solanaLogoUrl = "https://cnemhnnwbhehhupxqoin.supabase.co/storage/v1/object/public/Icons/solana-sol-logo-12828AD23D-seeklogo.com.png?t=2024-03-10T04%3A17%3A18.521Z";
    const solanaName = "Solana";
    const solanaSymbol = "SOL";

    const isSolana = selectedToken?.address === solanaAddress;
    const logoUrl = isSolana ? solanaLogoUrl : (tokenMetaInfo[selectedToken?.address]?.image || 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png');
    let tokenName = isSolana ? solanaName : (tokenMetaInfo[selectedToken?.address]?.name || 'Unknown Token'); // Use 'let' to allow modification
    const tokenSymbol = isSolana ? solanaSymbol : selectedToken?.symbol;
    const tokenPageUrl = `/dashboard/explorer/t/${selectedToken?.address}`;

    // Truncate the token name if it exceeds 13 characters
    if (tokenName.length > 13) {
        tokenName = `${tokenName.substring(0, 10)}...`; // Append ellipsis
    }

    return (
        <div className="sierra-token-info-container">
            <img src={logoUrl} alt={`${tokenName} logo`} className="sierra-panel-token-logo" />
            <p className="token-name-symbol">
                {tokenName} ({tokenSymbol})
                <a href={tokenPageUrl} className="token-page-link" target="_blank" rel="noopener noreferrer">
                    <ExternalLinkIcon className="sierra-external-link-icon" />
                </a>
            </p>
        </div>
    );
};

export default TokenInfo;
