import React, { useState } from 'react';
import './ReferLink.css';
import { ReactComponent as CopyClipboardIcon } from '../../../../Media/Icons/CopyClipboardIcon.svg';
import { ReactComponent as TickIcon } from '../../../../Media/Icons/TickIcon.svg';
import { useTheme } from '../../../../Auth/AppContexts/ThemeContext';
import CopyIconDarkMode from '../../../../Media/Icons/CopyIconDarkMode.svg';

const ReferLink = ({ url }) => {
    const [isCopied, setIsCopied] = useState(false);
    const { theme } = useTheme();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500); // Revert back to copy icon after 3 seconds
    };

    return (
         <div className="refer-link-container">
            {isCopied ? (
                <TickIcon className="copy-clipboard-icon" />
            ) : theme === 'dark' ? (
                <img 
                    src={CopyIconDarkMode} 
                    alt="Copy" 
                    className="copy-clipboard-icon" 
                    onClick={copyToClipboard}
                />
            ) : (
                <img 
                    src={CopyClipboardIcon} 
                    alt="Copy" 
                    className="copy-clipboard-icon" 
                    onClick={copyToClipboard} 
                />
            )}
            <p className="refer-link-url">{url}</p>
        </div>
    );
};

export default ReferLink;
