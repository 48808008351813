import React, { useState } from 'react';
import './TokenHoldersList.css'; 
import { AddressOrProtocolName } from '../../../../ReusableUtility/ProtocolMapping';
import { formatNumber } from '../../../../ReusableUtility/FormatNumberWithCommas'; 
import LoadingComponent from '../../../../ReusableUtility/LoadingComponent'; 

const TokenHoldersList = ({ tokenHoldersList, totalSupply, currentPrice, isFetchingTokenHolders }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);

  const totalPages = Math.ceil(tokenHoldersList.length / itemsPerPage);
  const [fetchError, setFetchError] = useState(false);




  // Calculate properties for each holder
  const calculatedHoldersList = tokenHoldersList.map((holder, index) => {
    const amountFormatted = formatNumber(holder.amount);
    const percentOwnership = ((holder.amount / totalSupply) * 100).toFixed(2);
    const dollarValue = currentPrice ? `$${formatNumber(holder.amount * currentPrice)}` : 'N/A';
    return {
      ...holder,
      rank: index + 1,
      amountFormatted,
      percentOwnership,
      dollarValue,
    };
  });

  // Get current items for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = calculatedHoldersList.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePrevPage = () => {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage => Math.min(currentPage + 1, Math.ceil(calculatedHoldersList.length / itemsPerPage)));
  };


  

  return (
    <div className="token-holders-details">
    <div className="token-holders-header-row">
      <span>RANK</span>
      <span>OWNER</span>
      <span className="header-amount">AMOUNT</span>
      <span>% OWNERSHIP</span>
      <span>$ VALUE</span>
    </div>

    {isFetchingTokenHolders && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
        <LoadingComponent />
      </div>
    )}

    {fetchError && (
      <div className="error-message">
        Hmm. Something went wrong.
      </div>
    )}

    {!isFetchingTokenHolders && !fetchError && (
      <>
        {currentItems.map((holder) => (
          <div key={holder.rank} className="token-holder-row">
                <span>#{holder.rank}</span>
            <AddressOrProtocolName address={holder.owner} />
            <span>{holder.amountFormatted}</span>
            <span>{holder.percentOwnership}%</span>
            <span>{holder.dollarValue}</span>
          </div>
        ))}

        {totalPages > 1 && (
          <div className="pagination-controls-holders">
            <button className="pagination-button" onClick={handlePrevPage} disabled={currentPage <= 1}>
              &#8592; Prev
            </button>
            <span className="pagination-info">
              Page {currentPage} of {totalPages}
            </span>
            <button className="pagination-button" onClick={handleNextPage} disabled={currentPage >= totalPages}>
              Next &#8594;
            </button>
          </div>
        )}
      </>
    )}
  </div>
    );
  };

export default TokenHoldersList;


   