// FetchTokenList.js

const STRICT_TOKEN_LIST_URL = 'https://token.jup.ag/strict';
const ALL_TOKEN_LIST_URL = 'https://token.jup.ag/all';

export const fetchTokenList = async (searchTerm = '', listType = 'all', includeBanned = false) => {
    let url = listType === 'strict' ? STRICT_TOKEN_LIST_URL : ALL_TOKEN_LIST_URL;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const tokens = await response.json();
        if (!searchTerm) return tokens; // Return all tokens if no search term
        return tokens.filter(token => 
            token.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
            token.symbol.toLowerCase().includes(searchTerm.toLowerCase())
        );
    } catch (error) {
        console.error('Error fetching token list:', error);
        return []; 
    }
};
