// BlackfinTokenDetails.js

import React from 'react';
import './BlackfinTokenDetails.css'; // Ensure this path is correct

const BlackfinTokenDetails = ({ data }) => {
  return (
     <div className="blackfin-token-details-container">
      <h2 className="token-details-title">{data.title}</h2>
      <div className="token-info-section">
        {data.columns.map((column, index) => (
          <div className="token-info-column" key={index}>
            {/* Use `token-info-subtitle` for the subtitle, and check if it's a link to use `token-info-subtitle-link` */}
            <p className={column.url ? "token-info-subtitle-link" : "token-info-subtitle"}>
              {column.subtitle}
            </p>
            {/* Check if it's a link to use `token-info-link` */}
            {column.url ? (
              <a href={column.url} className="token-info-link" target="_blank" rel="noopener noreferrer">
                {column.main}
              </a>
            ) : (
              <p className="token-info-main">{column.main}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlackfinTokenDetails;
