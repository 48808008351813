// AIChatMessageItem.js
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './AIChatMessageItem.css'; // Importing the CSS
import { ReactComponent as CopyClipboardIcon } from '../../Media/Icons/CopyClipboardIcon.svg';
import { ReactComponent as TickIcon } from './TickIcon.svg';
import blackfinAIMessageIconPath from '../../Media/Logos/BlackEmblem.svg';
import WhiteblackfinAIMessageIconPath from '../../Media/Logos/WhiteEmblem.svg';
import { useTheme } from '../../Auth/AppContexts/ThemeContext';

import failedOrRejectedIconPath from '../../Media/Icons/FailedOrRejectedIcon.svg'; // Import the SVG file path
import { ReactComponent as TickCircleGreen } from '../../Media/Icons/TickCircleGreen.svg';
import Button from '../Buttons/Button';
import { useSwap } from '../../Auth/AppContexts/SwapContext'; // Import SwapContext
import { useSendTransaction } from '../../Auth/AppContexts/SendTransactionContext';





const AIChatMessageItem = ({
    text,
    isSystemMessage = false,
    onExecuteSwap, // Existing swap execution function
    onExecuteTransaction, // New send execution function
    quoteId = "",
    transactionId = "", // New identifier for send transactions
    txHash = "",
    messageType,
    message,
    children,
    
}) => {



    const [copySuccess, setCopySuccess] = useState(false);
    const { swapStatuses } = useSwap(); 

    const swapStatus = swapStatuses[quoteId]?.status || 'idle';

    const { transactionStatuses, setTransactionStatus, prepareTransactionForConfirmation, clearPendingTransaction,   pendingTransaction, } = useSendTransaction(); 

    const transactionStatus = transactionStatuses[transactionId]?.status || 'idle';

   
      //const transactionDetails = pendingTransaction[transactionId] || {};
    const [isCancelled, setIsCancelled] = useState(false);
      const transactionDetails = message?.transactionDetails;
       const { theme } = useTheme();




        
   

   


    const handleCopyToClipboard = async () => {
        try {
            const plainText = new DOMParser().parseFromString(text, 'text/html').body.textContent || '';
            await navigator.clipboard.writeText(plainText);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        } catch (error) {
            console.error('Failed to copy message:', error);
        }
    };




//SWAP LOGIC

    // Adjust executeSwap to use the global context for status updates
    const executeSwap = async () => {
        // Since status updates are now handled in the global context,
        // ensure onExecuteSwap correctly updates the status in the context.
        try {
            await onExecuteSwap(quoteId); // This should handle updating the status in the global context
        } catch (error) {
            console.error('Swap execution failed:', error);
            // Error handling remains as needed, but remember status should be updated globally
        }
    };
  



 // Log to see what's being received
    //console.log("AIChatMessageItem received messageType:", messageType);
    //console.log("AIChatMessageItem received pendingTransaction:", pendingTransaction);


// SEND TRANSACTION LOGIC
 // Adjust executeTransaction to include transactionId
    const executeTransaction = async (transactionDetails) => {
         //console.log("Executing transaction with details:", transactionDetails);
        if (onExecuteTransaction) {
             //console.log("onExecuteTransaction is available, executing...");
            await onExecuteTransaction(transactionDetails);
            clearPendingTransaction(transactionDetails.transactionId); // Clear specific transaction
        }
    };


  // Handle canceling a transaction
    const handleCancel = (transactionId) => {
        clearPendingTransaction(transactionId);
        setIsCancelled(true); // Trigger the UI to update for this specific transaction
    };



    
    // Direct rendering for "info" system messages
if (isSystemMessage && messageType === 'info') {
      //console.log("Rendering info message:", text);
    return (
        <div className="ai-chat-message-item">
            <img 
                src={theme === 'dark' ? WhiteblackfinAIMessageIconPath : blackfinAIMessageIconPath} 
                alt="App Icon" 
                className={theme === 'dark' ? "app-icon app-icon-dark" : "app-icon"} 
            />
            <div dangerouslySetInnerHTML={{ __html: text }} className="ai-message-text"></div>
        </div>
    );
}



if (isSystemMessage && messageType === 'trending') {
        return (
            <div className="ai-chat-message-item system-message">
                <img 
                    src={theme === 'dark' ? WhiteblackfinAIMessageIconPath : blackfinAIMessageIconPath} 
                    alt="App Icon"
                    className={theme === 'dark' ? "app-icon app-icon-dark" : "app-icon"} 
                />
                <div dangerouslySetInnerHTML={{ __html: text }} className="ai-message-text"></div>
                {children && <div className="additional-content">{children}</div>}  
            </div>
        );
    }



  if (isSystemMessage && messageType === 'token_info') {
    return (
        <div className="ai-chat-message-item system-message">
            <img 
                src={theme === 'dark' ? WhiteblackfinAIMessageIconPath : blackfinAIMessageIconPath} 
                alt="App Icon"
                className={theme === 'dark' ? "app-icon app-icon-dark" : "app-icon"} 
            />
            <div dangerouslySetInnerHTML={{ __html: text }} className="ai-message-text"></div>
            {children}
        </div>
    );
}








    
  // Conditional rendering based on the transaction state
    if (isSystemMessage && messageType === 'send' && transactionDetails) {
        const transactionId = transactionDetails.transactionId; 
        if (isCancelled) {
            return (
                <div className="ai-chat-message-item">

                    <img 
                    src={theme === 'dark' ? WhiteblackfinAIMessageIconPath : blackfinAIMessageIconPath} 
                    alt="App Icon" 
                    className={theme === 'dark' ? "app-icon app-icon-dark" : "app-icon"} 
                />

                <div className="ai-message-text">
                    <p><strong>Transaction ID: {transactionId}</strong></p>
                    <p>This transaction was cancelled manually, please resend your request.</p>
                </div>
            </div>




            );
        } else {
            // Render the transaction confirmation UI
            return (
                <div className="ai-chat-message-item">

                    <img 
                    src={theme === 'dark' ? WhiteblackfinAIMessageIconPath : blackfinAIMessageIconPath} 
                    alt="App Icon" 
                    className={theme === 'dark' ? "app-icon app-icon-dark" : "app-icon"} 
                />


                    
                     
                    <div className="ai-message-text">

                        <p><strong>Transaction ID: {transactionDetails.transactionId}</strong></p> 
                        
                        <p>Please confirm that you want to send {transactionDetails.amount} to {transactionDetails.recipientAddress}?</p>
                        <Button
                            text="Confirm Send"
                            color="primary"
                            onClick={() => executeTransaction(transactionDetails)}
                        />
                        
                        <Button
                            text="Cancel"
                            color="secondary"
                            onClick={() => handleCancel(transactionDetails.transactionId)}
                        />
                    </div>
                </div>
            );
        }
    }




    return (
      <div className="ai-chat-message-item">
        <img 
            src={theme === 'dark' ? WhiteblackfinAIMessageIconPath : blackfinAIMessageIconPath} 
            alt="App Icon" 
            className={theme === 'dark' ? "app-icon app-icon-dark" : "app-icon"} 
        />

            <div dangerouslySetInnerHTML={{ __html: text }} className="ai-message-text"></div>
            {copySuccess ? (
                <TickIcon className="copy-icon" />
            ) : (
                <CopyClipboardIcon className="copy-icon" onClick={handleCopyToClipboard} />
            )}

            {isSystemMessage && (
                <>
                    {/* Swap Transaction Status Handling */}
                    {swapStatus === 'success' && txHash && (
                        <div className="trade-success-container">
                            <TickCircleGreen className="trade-success-icon" />
                            <span className="trade-success-message">Trade Executed Successfully</span>
                            <a href={`${process.env.REACT_APP_BLACKFIN_MAIN_URL}/dashboard/explorer/tx/${txHash}`} target="_blank" rel="noopener noreferrer" className="tx-details-link">View Transaction</a>
                        </div>
                    )}

                    {(swapStatus === 'failed' || swapStatus === 'rejected' || swapStatus === 'error') && quoteId && (
                        <div className="transaction-rejected-or-error-container">
                            <img src={failedOrRejectedIconPath} alt="Failed or Rejected" className="failed-or-rejected-icon" />
                            <div className="transaction-rejected-or-error-message">Swap transaction {swapStatus}. Please try again.</div>
                            {/* Note: Execute Swap button is removed when transaction is failed, rejected, or in error state */}
                        </div>
                    )}

                    {!txHash && quoteId && swapStatus !== 'rejected' && swapStatus !== 'error' && (
                        <Button
                            text={swapStatus === 'loading' ? "Processing Swap..." : "Execute Swap"}
                            color={"primary"}
                            onClick={() => onExecuteSwap(quoteId)}
                            disabled={swapStatus === 'loading'}
                        />
                    )}


                   {/* BELOW IS TX RELATED LOGIC */}

                   

                   


            {transactionStatus === 'pending' && (
            <div className="transaction-confirmation-container">
                <p>Confirm sending {transactionStatuses[transactionId]?.amount} SOL to {transactionStatuses[transactionId]?.recipientAddress}?</p>
                <Button
                    text="Confirm Send"
                    color="primary"
                    onClick={() => executeTransaction()}
                />
                {/* Optional: Add a Cancel button to clear the pending transaction */}
            </div>
        )}

        {transactionStatus === 'success' && txHash && (
            <div className="transaction-success-container">
                <TickCircleGreen className="transaction-success-icon" />
                <span className="transaction-success-message">Transaction Executed Successfully</span>
                <a href={`${process.env.REACT_APP_BLACKFIN_MAIN_URL}/dashboard/explorer/tx/${txHash}`} target="_blank" rel="noopener noreferrer" className="tx-details-link">View Transaction</a>
            </div>
        )}

        {['failed', 'rejected', 'error'].includes(transactionStatus) && transactionId && (
            <div className="transaction-rejected-or-error-container">
                <img src={failedOrRejectedIconPath} alt="Failed or Rejected" className="failed-or-rejected-icon" />
                <div className="transaction-rejected-or-error-message">Send transaction {transactionStatus}. Please try again.</div>
            </div>
        )}

        {!txHash && transactionId && transactionStatus !== 'pending' && (
            <Button
                text={transactionStatus === 'loading' ? "Processing Transaction..." : "Confirm Transaction"}
                color="primary"
                onClick={() => onExecuteTransaction(transactionId)}
                disabled={transactionStatus === 'loading'}
            />
        )}
    </>
            )}
        </div>
    );
};




AIChatMessageItem.propTypes = {
    text: PropTypes.string.isRequired,
    isSystemMessage: PropTypes.bool,
    onExecuteSwap: PropTypes.func, 
    onExecuteTransaction: PropTypes.func, 
    quoteId: PropTypes.string,
    transactionId: PropTypes.string, 
    txHash: PropTypes.string,
    messageType: PropTypes.string,
};


export default AIChatMessageItem;