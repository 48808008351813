import React from 'react';
import './TxSummary.css'; 
import TimeAgo from '../../../../ReusableUtility/RefreshFetchTime'; 
import { WalletAddressLink } from '../../../../ReusableUtility/WalletAndTxLink/WalletAndTxLink'; 
import AISparkleIconPath from '../../../../Media/Icons/AISparkleIcon.svg'; 
import AISparkleIconPathDark from '../../../../Media/Icons/SparklesDarkMode.svg'; 
import { formatBurnAmount, formatNumber, formatAmount } from '../../../../ReusableUtility/FormatNumberWithCommas';
import TokenLink from '../../../../ReusableUtility/TokenLink/TokenLink';
import { useTheme } from '../../../../Auth/AppContexts/ThemeContext';



//{' from '}  



const TxSummary = ({ amount, sender, receiver, timestamp, type, tokenData, status, signer, swapDetails, tokenAddress }) => {
    const { theme } = useTheme();
    //console.log("TxSummary amount:", amount);
    //console.log("TxSummary amount type:", typeof amount);
       

    const summaryText = () => {
        if (status === 'Fail') {
            return (
                 <>
            <img 
                src={theme === 'dark' ? AISparkleIconPathDark : AISparkleIconPath} 
                alt="Transaction Icon" 
                className="txSummaryIcon" 
            />
            <span className="txFailText">
                A transaction attempt was made, however, it failed and has not been processed.
            </span>
        </>
            );
        }

        switch (type) {
            case 'SOL_TRANSFER':
                return (
                    <>
                        <img 
                        src={theme === 'dark' ? AISparkleIconPathDark : AISparkleIconPath}
                        
                        alt="AI Sparkle" className="txSummaryIcon" />
                        {amount} <TokenLink symbol="SOL" isSolTransfer={true} /> was successfully transferred {' from '}   
                        <WalletAddressLink address={sender} /> 
                        {' to '}  
                        <WalletAddressLink address={receiver} /> 
                        {' as of '} 
                        <TimeAgo timestamp={timestamp} />.
                    </>
                );



            case 'TOKEN_TRANSFER':
                const tokenSymbol = tokenData?.result?.symbol || 'Unknown Token';
                return (
                    <>
                        <img 
                        src={theme === 'dark' ? AISparkleIconPathDark : AISparkleIconPath}
                        
                        alt="AI Sparkle" className="txSummaryIcon" />
                         {formatNumber(amount, 2)} <TokenLink tokenAddress={tokenAddress} symbol={tokenSymbol} /> was successfully transferred {' from '}   
                        <WalletAddressLink address={sender} /> 
                        {' to '}  
                        <WalletAddressLink address={receiver} /> 
                        {' as of '} 
                        <TimeAgo timestamp={timestamp} />.
                    </>
                );


                case 'CREATE':
        return (
            <>
                <img src={theme === 'dark' ? AISparkleIconPathDark : AISparkleIconPath} alt="Create Account" className="txSummaryIcon" />
                This transaction has initiated a new account on the blockchain for vesting/locking or a smart contract deployment {' as of '} 
                <TimeAgo timestamp={timestamp} />. 

                
            </>
        );



            case 'SWAP':
                if (swapDetails) {
                    return (
                        <>
                            <img 
                            src={theme === 'dark' ? AISparkleIconPathDark : AISparkleIconPath}
                            
                            alt="Swap Transaction" className="txSummaryIcon" />
                            {formatNumber(swapDetails.inAmount, 2)}{'  '}   <TokenLink tokenAddress={swapDetails.inTokenAddress} symbol={swapDetails.inSymbol} />  was successfully swapped  {' for '}  
                             {formatNumber(swapDetails.outAmount, 2)}  <TokenLink tokenAddress={swapDetails.outTokenAddress} symbol={swapDetails.outSymbol} />   {' as of '} 
                            <TimeAgo timestamp={timestamp} />.
                        </>
                    );
                }
                return 'Swap details not available';




            case 'TOKEN_BURN':
            const formattedBurnAmount = formatBurnAmount(amount);
            const burnTokenSymbol = tokenData?.result?.symbol || 'Unknown Token'; // Extract token symbol
            const burnTokenAddress = tokenData?.result?.address;



            return (
                <>
                    <img 
                    
                    src={theme === 'dark' ? AISparkleIconPathDark : AISparkleIconPath}
                    
                    alt="Token Burn" 
                    className="txSummaryIcon" />
                    {formattedBurnAmount} <TokenLink symbol={burnTokenSymbol} tokenAddress={burnTokenAddress} /> were successfully burned from the {' wallet '}  
                    <WalletAddressLink address={signer} /> 
                    {' as of '} 
                    <TimeAgo timestamp={timestamp} />.
                </>
            );



            default:
                return 'Other transaction type';
        }
    };


    return (
        <div className="txSummaryContainer">
            <div className="txSummaryHeaderText">TX SUMMARY</div>
            <div className="txSummaryTextContent">
                {summaryText()}
            </div>
        </div>
    );
};

export default TxSummary;
