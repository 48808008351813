import React, { useState, useEffect, useRef } from 'react';
import { formatNumber } from '../../../../ReusableUtility/FormatNumberWithCommas';
import './SierraUserSOLBalance.css';

const UserBalances = ({ solBalance, tokenBalance, usdcBalance, tokenSymbol, isWalletConnected }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(); // Ref for the modal

    // Function to toggle the modal's visibility
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    // Close the modal if clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef]);

    if (!isWalletConnected) {
        return (
            <div className="user-sol-balance">
                <p><strong>Connect Wallet</strong></p>
            </div>
        );
    }

    const formattedSOLBalance = solBalance !== null ? parseFloat(solBalance).toFixed(2) : '---';
    const formattedTokenBalance = tokenBalance !== null ? formatNumber(tokenBalance) : '---';
  const formattedUSDCBalance = usdcBalance !== null ? parseFloat(usdcBalance).toFixed(2) : '0';


    return (
        <>
            <div className="user-sol-balance" onClick={toggleModal}>
                <p>
                    <strong>SOL:</strong> {formattedSOLBalance}  | 
                    <strong> {tokenSymbol}:</strong> {formattedTokenBalance}
                </p>
            </div>
            {isModalOpen && (
                <div className="balances-popout-modal" ref={modalRef}>
                    <div className="balances-header">
                        <h4>Wallet Balances</h4>
                    </div>
                    <div className="balances-content">
                        <p><strong>SOL:</strong> {formattedSOLBalance} SOL</p>
                        <p><strong>USDC:</strong> {formattedUSDCBalance} USDC</p>
                        <p><strong>{tokenSymbol}:</strong> {formattedTokenBalance}</p>
                        
                    </div>
                </div>
            )}
        </>
    );
};

export default UserBalances;
