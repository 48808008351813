import React from 'react';
import './ModalToDisplayAllDomainNames.css'; // Make sure to create this CSS file

const ModalToDisplayAllDomainNames = ({ solanaDomains, closeModal }) => {
  
  const handleDomainClick = (domainName) => {
    navigator.clipboard.writeText(domainName).then(() => {
      // You can implement some state to show a copied confirmation if desired
      console.log(`Copied ${domainName} to clipboard`);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={closeModal}>X</button>
        <h3 className="total-domains-count">SOL DOMAIN NAMES: {solanaDomains.length}</h3>
        <div className="domain-names-list">
          {solanaDomains.map((domain, index) => (
            <div 
              key={index} 
              className="wallet-info-domain-name-modal" 
              onClick={() => handleDomainClick(domain.name || domain)}
            >
              {domain.name || domain}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalToDisplayAllDomainNames;
