const BASE_URL = "https://price.jup.ag/v4/price";

export const fetchSolanaTokenPrice = async (vsToken = "USDC") => {
    try {
        const solanaSymbol = 'SOL'; // Symbol for Solana
        const url = `${BASE_URL}?ids=${encodeURIComponent(solanaSymbol)}&vsToken=${encodeURIComponent(vsToken)}`;
        const response = await fetch(url);
        const data = await response.json();

        // Return data for the Solana token
        return data.data[solanaSymbol] || null;
    } catch (error) {
        console.error("Error fetching Solana token data:", error);
        return null;
    }
};
