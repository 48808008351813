import React from 'react';
import './SierraTokenInfoComponent.css';
import { ReactComponent as TickCircleGreen } from '../../Media/Icons/TickCircleGreen.svg';
import failedOrRejectedIcon from '../../Media/Icons/FailedOrRejectedIcon.svg';

const TokenInfo = ({ tokenData }) => {
    if (!tokenData) return <div>Loading...</div>;

    const formatCurrency = (value) => {
        if (value >= 1e9) {
            return `$${(value / 1e9).toFixed(1)}B`;
        } else if (value >= 1e6) {
            return `$${(value / 1e6).toFixed(1)}M`;
        } else if (value >= 1e3) {
            return `$${(value / 1e3).toFixed(1)}K`;
        } else {
            return `$${value}`;
        }
    };

    const getPriceChangeText = (change, period) => {
        if (change === null) return null;

        const priceChangeClass = change > 0 ? 'sierra-chat-price-change-positive' :
                                 change < 0 ? 'sierra-chat-price-change-negative' :
                                 'sierra-chat-price-change-neutral';
        const sign = change > 0 ? '+' : '';
        let displayChange = (Math.abs(change) >= 1000) ? `${sign}${(change / 1000).toFixed(1)}K%` : `${sign}${change.toFixed(change % 1 === 0 ? 0 : 2)}%`;

        return <span>{period}: <span className={priceChangeClass}>{displayChange}</span></span>;
    };

    const lpLockedPercentage = tokenData.securityDetails && tokenData.securityDetails.lpLockedPct ?
                               tokenData.securityDetails.lpLockedPct.toFixed(0) : '0';

    const marketCap = formatCurrency(tokenData.fdv);

    return (
        <div className="sierra-token-info-chat-response-container">
            <div className="sierra-chat-token-info-header">
                <img src={tokenData.imageUrl} alt={`${tokenData.name} logo`} className="sierra-token-logo-info" />
                <div className="header-text">
                    <span className="sierra-chat-token-symbol">{tokenData.name} ({tokenData.symbol})</span>
                    <div className="sierra-chat-price-changes">
                        {getPriceChangeText(tokenData.priceChange5m, '5m')}
                        {tokenData.priceChange1h !== null && <> | {getPriceChangeText(tokenData.priceChange1h, '1h')}</>}
                        {tokenData.priceChange24h !== null && <> | {getPriceChangeText(tokenData.priceChange24h, '24h')}</>}
                        <span className="market-cap"> | MC: {marketCap}</span>
                    </div>
                </div>
            </div>
            
            <div className="sierra-chat-token-details">
                <div>
                    {tokenData.authorities && tokenData.authorities.mintAuthorityDisabled ?
                        <TickCircleGreen className="sierra-chat-tick-icon" /> :
                        <img src={failedOrRejectedIcon} alt="Mint Authority Disabled" className="sierra-chat-cross-icon" />}
                    Mint Authority Disabled: {tokenData.authorities.mintAuthorityDisabled ? "Yes" : "No"}
                </div>
                <div>
                    {tokenData.authorities && tokenData.authorities.freezeAuthorityDisabled ?
                        <TickCircleGreen className="sierra-chat-tick-icon" /> :
                        <img src={failedOrRejectedIcon} alt="Freeze Authority Disabled" className="sierra-chat-cross-icon" />}
                    Freeze Authority Disabled: {tokenData.authorities.freezeAuthorityDisabled ? "Yes" : "No"}
                </div>
                <div>
                    {parseFloat(lpLockedPercentage) < 10 ? 
                        <img src={failedOrRejectedIcon} alt="Low Liquidity Locked" className="sierra-chat-cross-icon" /> :
                        <TickCircleGreen className="sierra-chat-tick-icon" />}
                    Liquidity Locked: {lpLockedPercentage}%
                </div>
            </div>
        </div>
    );
};

export default TokenInfo;
