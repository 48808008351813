import React, { useContext } from 'react';
import { SierraSearchContext } from '../../../Auth/AppContexts/SierraSearchContext';
import './SierraCharts.css';

const SierraChart = () => {
    const { selectedToken } = useContext(SierraSearchContext);

    const defaultTokenAddress = "So11111111111111111111111111111111111111112";
    // Use selectedToken.address if available, otherwise use defaultTokenAddress
    const tokenAddress = selectedToken?.address || defaultTokenAddress;

    // Define your desired chart parameters here
    const chartType = "candle"; 
    const chartInterval = "60"; 
    const chartLeftToolbar = "show"; // Options: show, hide

    // Construct the Birdeye iframe URL using the selected token address from the context or the default one
    const birdeyeURL = `https://birdeye.so/tv-widget/${tokenAddress}?chain=solana&chartType=${chartType}&chartInterval=${chartInterval}&chartLeftToolbar=${chartLeftToolbar}`;

    return (
        <div className="sierra-chart-container">
            <iframe
                src={birdeyeURL}
                title="Token Chart"
                className="token-chart-iframe"
                width="100%"
                height="100%" // Adjust height as needed to fit within the container
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default SierraChart;
