import React, { useEffect, useState } from 'react';
import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu';
import TxStatusAndTypeComponent from '../../Components/ExplorerFeature/TxDetailsPage/TxStatusAndTypeTags/TxStatusAndTypeComponent'; 
import TxDateAndTime from '../../Components/ExplorerFeature/TxDetailsPage/DateAndTimeDisplay/TxDateAndTime';
import TxHashDisplay from '../../Components/ExplorerFeature/TxDetailsPage/TxHashDisplay/TxHashDisplay'; 
import TxSummary from '../../Components/ExplorerFeature/TxDetailsPage/TxSummaryComponent/TxSummary'; 
import TxSummaryAssetDisplay from '../../Components/ExplorerFeature/TxDetailsPage/TxSummaryAssetDisplay/TxSummaryAssetDisplay'; 
import AdditionalTxInfo from '../../Components/ExplorerFeature/TxDetailsPage/AdditionalTxInfoComponent/AdditionalTxInfo'; 
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../ReusableUtility/LoadingComponent'; 
import './TxDetailsPage.css';
import FullPageLoader from '../../ReusableUtility/FullPageUILoading';




const TxDetailsPage = () => {
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [txTokenData, setTxTokenData] = useState(null);
    const [displayAmount, setDisplayAmount] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { txAddress } = useParams();
    const [displaySender, setDisplaySender] = useState(null);
    const [displayReceiver, setDisplayReceiver] = useState(null);
    const [displayFee, setDisplayFee] = useState(null);
    const [displayProtocolName, setDisplayProtocolName] = useState(null);
    const [displaySigner, setDisplaySigner] = useState(null);
    const [swapDetails, setSwapDetails] = useState(null);




    // Extract status from transactionDetails
    const status = transactionDetails?.result?.status;
    const type = transactionDetails?.result?.type;
    const timestamp = transactionDetails?.result?.timestamp;
    const txnHash = transactionDetails?.result?.signatures?.[0];
    const fee = transactionDetails?.result?.fee; 
    const protocolName = transactionDetails?.result?.protocol?.name || transactionDetails?.result?.actions[0]?.source_protocol?.name;
    const sender = transactionDetails?.result?.actions[0]?.info?.sender;
    const receiver = transactionDetails?.result?.actions[0]?.info?.receiver;





    

// Fetch token info API call fetches symbol, token images etc.
const fetchTokenInfoForTxPage = async (tokenAddress) => {
    try {
        const tokenInfoUrl = `${process.env.REACT_APP_API_URL}/api/token-info-for-txPage?tokenAddress=${encodeURIComponent(tokenAddress)}`;
        const response = await fetch(tokenInfoUrl);
        if (!response.ok) {
            throw new Error(`Network response was not ok. Status: ${response.status}`);
        }
        const tokenInfoData = await response.json();
         //console.log("Token info data:", tokenInfoData); 
        setTxTokenData(tokenInfoData);
    } catch (error) {
        console.error('Error fetching token info:', error);
    }
};




// Fetch transaction details
useEffect(() => {
    const fetchTxData = async () => {
        setIsLoading(true);
        if (!txAddress) {
            setError('No transaction address provided');
            setIsLoading(false);
            return;
        }
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/txData-for-TxDetailsPage?txnSignature=${txAddress}`;
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setTransactionDetails(data);

            // Extract general transaction details
            setDisplayProtocolName(data.result.protocol?.name);
            setDisplayFee(data.result.fee);
            setDisplaySigner(data.result.signers?.[0]);

            // Handle specific transaction types
            switch (data.result.type) {
                case 'SWAP':
                    const swapInfo = data.result.actions[0]?.info?.tokens_swapped;
                    if (swapInfo) {
                        setSwapDetails({
                            inAmount: swapInfo.in.amount,
                            inSymbol: swapInfo.in.symbol,
                            inImage: swapInfo.in.image_uri,
                            inTokenAddress: swapInfo.in.token_address,
                            outAmount: swapInfo.out.amount,
                            outSymbol: swapInfo.out.symbol,
                            outImage: swapInfo.out.image_uri,
                            outTokenAddress: swapInfo.out.token_address,
                        });
                    }
                    break;

                case 'TOKEN_BURN':
                    const burnAction = data.result.actions.find(action => action.type === 'TOKEN_BURN');
                    if (burnAction) {
                        setDisplayAmount(burnAction.info.amount);
                        setDisplaySender(data.result.signers[0]);
                        
                         // Fetch additional token info if necessary
                    if (burnAction?.info?.token_address) {
                        fetchTokenInfoForTxPage(burnAction.info.token_address);
                    }

                    }
                    break;

                case 'TOKEN_TRANSFER':
                    const tokenTransferAction = data.result.actions.find(action => action.type === 'TOKEN_TRANSFER');
                    if (tokenTransferAction) {
                        setDisplayAmount(tokenTransferAction.info.amount);
                        setDisplaySender(tokenTransferAction.info.sender);
                        setDisplayReceiver(tokenTransferAction.info.receiver);
                        
                         // Fetch additional token info if token_address is available
                        if (tokenTransferAction.info.token_address) {
                            fetchTokenInfoForTxPage(tokenTransferAction.info.token_address);
                        }
                    }
                    break;

                case 'SOL_TRANSFER':
                    const solTransferAction = data.result.actions.find(action => action.type === 'SOL_TRANSFER');
                    if (solTransferAction) {
                        setDisplayAmount(solTransferAction.info.amount);
                        setDisplaySender(solTransferAction.info.sender);
                        setDisplayReceiver(solTransferAction.info.receiver);
                    }
                    break;

                // Additional cases for other transaction types can be added here...
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    fetchTxData();
}, [txAddress]);






if (isLoading) {
  return <FullPageLoader />;
}

    
    if (error) {
        return <div>Error: {error}</div>;
    }





    return (
        <>
            <SidePanel />
            <HeaderMenu />
            <div className={`mainTxDetailsCard ${isLoading ? 'blurred' : ''}`}>
            <TxStatusAndTypeComponent status={status} type={type} />
            <TxDateAndTime timestamp={timestamp} />
           
            <TxSummary
                 amount={displayAmount}
                 sender={displaySender}
                 receiver={displayReceiver}
                 timestamp={transactionDetails?.result?.timestamp}
                 type={transactionDetails?.result?.type}
                 tokenData={txTokenData} 
                 status={transactionDetails?.result?.status}
                 swapDetails={swapDetails}
                 signer={displaySigner}
                 tokenAddress={txTokenData?.result?.address}

                                       />
             
              <TxSummaryAssetDisplay 
                  amount={displayAmount}
                  sender={displaySender}
                  receiver={displayReceiver}
                  symbol={txTokenData?.result?.symbol} 
                  image={txTokenData?.result?.image}
                  type={transactionDetails?.result?.type}
                  swapDetails={swapDetails}
                  status={transactionDetails?.result?.status}
                  tokenAddress={txTokenData?.result?.address}
                                          />


              <TxHashDisplay hash={txnHash} />

                
                <AdditionalTxInfo 
                 fee={displayFee}
                 protocolName={displayProtocolName}
                 signer={displaySigner}
                 sender={displaySender}
                 receiver={displayReceiver}
                
                
                />



                </div>
                </>
    );
        };

export default TxDetailsPage;