import React, { useState, useEffect } from 'react';
import './TokenOverviewComponent.css';
import formatPrice from '../../../../ReusableUtility/FormatPrice';

const TokenOverview = ({ currentPrice, supply, holders }) => {
  // Format number for display
  const formatNumber = (num, options = {}) => {
    return new Intl.NumberFormat('en-US', { style: 'decimal', ...options }).format(num);
  };

  // Format market cap with a dollar sign
  const marketCap = currentPrice * supply;
  const formattedMarketCap = `$${formatNumber(marketCap)}`;

  // Use formatPrice function to format the current price
  const formattedCurrentPrice = `$${formatPrice(currentPrice)}`;

  // Adjust the condition to explicitly check for null (or another chosen "loading" indicator)
  const displayHolders = (holders === null || holders === undefined) ? "Updating..." : formatNumber(holders);

  return (
    <div className="token-overview">
      <h2 className="overview-title">Token Overview</h2>
      <div className="overview-item">
        <span className="overview-label">MARKET CAP</span>
        <span className="overview-data">{formattedMarketCap}</span>
      </div>
      
      <div className="overview-item">
        <span className="overview-label">CURRENT PRICE</span>
        <span className="overview-data">{formattedCurrentPrice}</span>
      </div>

      <div className="overview-item">
        <span className="overview-label">CURRENT SUPPLY</span>
        <span className="overview-data">{formatNumber(supply)}</span>
      </div>
      <div className="overview-item">
        <span className="overview-label">HOLDERS</span>
        <span className="overview-data">{displayHolders}</span>
      </div>
    </div>
  );
};


export default TokenOverview;
