import React, { useState } from 'react';
import './TokenInfoComponent.css'; 
import { ReactComponent as TickIcon } from './TickIcon.svg'; 
import { ReactComponent as CopyClipboardIcon } from './CopyClipboardIcon.svg'; 
import DiscordIcon from '../../../../Media/Icons/discordicon.png'; // Adjust if it's SVG
import TelegramIcon from '../../../../Media/Icons/telegramicon.svg';
import TwitterIcon from '../../../../Media/Icons/twitter.svg';

const TokenInfoComponent = ({  name, address, decimals, website, socials }) => {
  const [copiedAddress, setCopiedAddress] = useState('');
  const formatValue = (value) => value || 'N/A';

  const handleCopy = (text, type) => {
    if (text && text !== 'N/A') {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedAddress(type);
        setTimeout(() => {
          setCopiedAddress(''); // Reset copied address after 2 seconds
        }, 2000);
      });
    }
  };


    const getSocialIcon = (type) => {
    switch (type) {
      case 'twitter':
        return <img src={TwitterIcon} alt="Twitter" className="info-social-icon" />;
      case 'telegram':
        return <img src={TelegramIcon} alt="Telegram" className="info-social-icon" />;
      case 'discord':
        return <img src={DiscordIcon} alt="Discord" className="info-social-icon" />;
      default:
        return null;
    }
  };


 const formatSocials = (socials) => {
  //console.log("Socials received:", socials);

    if (socials && socials.length > 0) {
      return socials.map((social, index) => (
        <a key={index} href={social.url} target="_blank" rel="noopener noreferrer" className="social-link">
          {getSocialIcon(social.type)}
        </a>
      ));
    }
    return 'N/A';
  };



  return (
    <div className="token-info-container">
      <h2 className="info-title">Info</h2>
      <div className="info-item">
        <span className="info-label">WEBSITE</span>
         <span className="info-data">
          {website ? 
            <a 
              href={website} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="TokenLinkStyle" // we use the style from tokenlink reusable compeoent
            >
              {website}
            </a> 
            : 'N/A'
          }
        </span>

      </div>
      <div className="info-item">
        <span className="info-label">SOCIALS</span>
        <div className="info-data">
          {formatSocials(socials)}
        </div>
      </div>
      <div className="info-item">
        <span className="info-label">TOKEN ADDRESS</span>
        <span className="info-data">
          {formatValue(address)}
          {address && address !== 'N/A' && (
            <span className="copy-icon" onClick={() => handleCopy(address, 'address')}>
              {copiedAddress === 'address' ? <TickIcon className="icon" /> : <CopyClipboardIcon className="icon" />}
            </span>
          )}
        </span>
      </div>
      <div className="info-item">
        <span className="info-label">DECIMALS</span>
        <span className="info-data">{decimals !== null ? decimals : 'N/A'}</span>
      </div>
    </div>
  );
};

export default TokenInfoComponent;
