import React from 'react';
import './NotificationItem.css'; // Ensure your CSS file includes styles mentioned later
import SystemMessageIcon from '../../../../Media/Icons/SystemMessageIcon.svg';
import SwapIconEmblem from '../../../../Media/Icons/SwapIconEmblem.svg';
import TokenIconEmblem from '../../../../Media/Icons/TokenIconEmblem.svg';
import SolanaIcon from '../../../../Media/Icons/solana-plain.svg';

// Helper function to select icon based on notification type
const iconSelector = (type) => {
    switch (type) {
        case 'Syste':
            return SystemMessageIcon;
        case 'wap':
            return SwapIconEmblem;
        case 'oken Transfer':
            return TokenIconEmblem;
        case 'OL Transfer':
            return SolanaIcon;
        default:
            return null; // Default case if no icon matches
    }
};

const NotificationItem = ({ type, description, timestamp }) => {
    const Icon = iconSelector(type); // Get the correct icon for the notification type

    return (
        <div className="notification-modal-content-row">
        <div className="notification-modal-type-column">
            {Icon && <img src={Icon} alt={type} className="notification-modal-icon" />}
            {type}
        </div>
        <div className="notification-modal-description-column">{description}</div>
        <div className="notification-modal-time-column">{timestamp}</div>
        <div className="notification-modal-action-column">
            <button className="notification-modal-view-button">View</button>
        </div>
    </div>
    );
};

export default NotificationItem;
