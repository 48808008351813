import React, { useState } from 'react';
import './WalletAddressDisplay.css';
import IconGenerator from '../../../../Media/Icons/IconGenerator';
import WalletTagComponent from '../../../../ReusableUtility/WalletTags/WalletTagComponent';
import NotificationBell from '../../../../Media/Icons/NotificationBell.svg';
// Correctly import SVGs as React components
import { ReactComponent as CopyClipboardIcon } from '../../../../Media/Icons/CopyClipboardIcon.svg';
import { ReactComponent as CopyIconDarkMode } from '../../../../Media/Icons/CopyIconDarkMode.svg';
import { ReactComponent as TickIcon } from '../../../../Media/Icons/TickIcon.svg';


import { useTheme } from '../../../../Auth/AppContexts/ThemeContext'; 


const WalletAddressDisplay = ({ address, label, onBellClick, isActiveNotification }) => {
  const [copied, setCopied] = useState(false); // State to track copy status
   const { theme } = useTheme(); 

  

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address).then(() => {
      setCopied(true); // Indicate that the address has been copied
      setTimeout(() => {
        setCopied(false); // Reset copied status after 2 seconds
      }, 2000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };



  return (
   <div className="wallet-display-address-container">
            <div className="wallet-address-display">
                <div className="wallet-label-container">
                    <span className="wallet-label">{label}</span>
                    <WalletTagComponent /> {/* Assume this is correctly imported */}
                </div>
                <div className="wallet-address-info">
                    <IconGenerator tokenHash={address} /> {/* Assume this is correctly imported */}
                    <span className="wallet-address">{address}</span>
                    <div className="notification-container">
                        {isActiveNotification && (
                            <div className="active-notification-indicator"></div>
                        )}
                        {/* Assuming NotificationBell is an img path, keep using <img> for it */}
                        <img src={NotificationBell} alt="Notification Bell" className="wallet-address-notification-bell-icon" onClick={onBellClick} />
                    </div>
                    {/* Copy to Clipboard Icon */}
                    {copied ? (
                        <TickIcon className="wallet-address-copy-icon" onClick={copyToClipboard} />
                    ) : theme === 'dark' ? (
                        <CopyIconDarkMode className="wallet-address-copy-icon-dark" onClick={copyToClipboard} />
                    ) : (
                        <CopyClipboardIcon className="wallet-address-copy-icon" onClick={copyToClipboard} />
                    )}
                </div>
            </div>
        </div>
  );
};

export default WalletAddressDisplay;
