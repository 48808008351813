// BlackfinChart.js

import React from 'react';
import './BlackfinChart.css';

const BlackfinChart = () => {
   
    const defaultTokenAddress = "BLKFNuCET9ZnmhFczGXmcWKSap9kGtTFnosvA9P5majk";
  
    const tokenAddress = defaultTokenAddress;

    // Define your desired chart parameters here
    const chartType = "candle";
    const chartInterval = "60";
    const chartLeftToolbar = "show"; // Options: show, hide

    // Construct the Birdeye iframe URL using the selected token address from the context or the default one
    const birdeyeURL = `https://birdeye.so/tv-widget/${tokenAddress}?chain=solana&chartType=${chartType}&chartInterval=${chartInterval}&chartLeftToolbar=${chartLeftToolbar}`;

    return (
        <div className="blackfin-chart-container">
            <iframe
                src={birdeyeURL}
                title="Token Chart"
                className="blackfin-chart-iframe"
                width="100%"
                height="100%" // Adjust height as needed to fit within the container
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default BlackfinChart;
