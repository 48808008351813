import React, { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { useTheme } from '../Auth/AppContexts/ThemeContext'; // Adjust the import path as necessary

const LoadingComponent = ({ loading, size = 13, marginTop = '35px', marginBottom = '0px' }) => {
    const { theme } = useTheme(); // Consuming theme context directly

    // Set loader color based on the theme
    const loaderColor = theme === 'dark' ? '#FFFFFF' : '#000000'; // White for dark theme, black for light theme

    return (
        <div className="loading-container" style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '80%', 
            marginTop,
            marginBottom,
        }}>
            <MoonLoader color={loaderColor} loading={loading} size={size} />
        </div>
    );
};

export default LoadingComponent;
