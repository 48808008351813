import React from 'react';
import { ReactComponent as TrendingIcon } from './TrendingIcon.svg'; // Import the SVG icon
import './TrendingTokensPanel.css';

const TrendingTokensPanel = () => {
    // Example with more tokens for a continuous ticker effect
    const trendingTokens = [
        { name: 'Token A', change: '+5%' },
        { name: 'Token B', change: '-2%' },
        { name: 'Token A', change: '+5%' },
        { name: 'Token B', change: '-2%' },
        { name: 'Token A', change: '+5%' },
        { name: 'Token B', change: '-2%' },
        { name: 'Token A', change: '+5%' },
        { name: 'Token B', change: '-2%' },
        { name: 'Token A', change: '+5%' },
        { name: 'Token B', change: '-2%' },
        { name: 'Token A', change: '+5%' },
        { name: 'Token B', change: '-2%' },
        // ... repeat the tokens or add more for a continuous effect
    ];

    return (
        <div className="trending-tokens-panel">
            <div className="trending-overlay"></div> {/* New overlay block */}
            <div className="trending-header">
                <TrendingIcon className="trending-icon" />
                <span className="trending-text">Trending</span>
            </div>
            <div className="trending-tokens-scroll">
                {trendingTokens.map((token, index) => (
                    <div key={index} className="trending-token">
                        <span>{token.name}</span>
                        <span>{token.change}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrendingTokensPanel;
