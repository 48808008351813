import React, { useContext, useEffect } from 'react';
import { SessionContext } from '../../Auth/AppContexts/SessionContext';
import ChatMessageList from './ChatMessageList';
import ChatInputBox from './ChatInputBox';
import './ChatPanel.css';
import { SierraSearchContext } from '../../Auth/AppContexts/SierraSearchContext';
import { useNavigate } from 'react-router-dom';



const ChatPanel = ({
    inputText,
    setInputText,
    className,
    onExecuteSwap,
    onExecuteTransaction,
    isWalletConnected,
     
}) => {

    const { messages, setMessages } = useContext(SessionContext);
    const navigate = useNavigate();
    const { setSelectedTokenByAddress } = useContext(SierraSearchContext);
    const addMessage = (message) => {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, message];
            sessionStorage.setItem('messages', JSON.stringify(updatedMessages));
            return updatedMessages;
        });
    };

     const handleTokenClick = async (tokenAddress) => {
        await setSelectedTokenByAddress(tokenAddress);
        navigate(`/dashboard/sierra-ai/t/${tokenAddress}`);
    };


    return (
        <div className={`chat-panel ${className}`}>
            <ChatMessageList
                messages={messages}
                onExecuteSwap={onExecuteSwap}
                onExecuteTransaction={onExecuteTransaction}
                handleTokenClick={handleTokenClick}
                addMessage={addMessage}
            />
            <ChatInputBox
                addMessage={addMessage}
                inputText={inputText}
                setInputText={setInputText}
                onExecuteSwap={onExecuteSwap}
                onExecuteTransaction={onExecuteTransaction}
            />
        </div>
    );
};

export default ChatPanel;

