import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './WalletTokenHoldingsDropdown.css';
import DropdownTokenHoldingsItem from './TokenHoldingsItem/DropdownTokenHoldingsItem'; 
import { numberWithCommas } from '../../../../../ReusableUtility/FormatNumberWithCommas';
import { fetchTokenPrice } from '../../../../../APIFunctions/FetchTokenPrice';
import LoadingComponent from '../../../../../ReusableUtility/LoadingComponent';






const WalletTokenHoldingsDropdown = ({ tokenHoldings, onTotalTokenValueCalculated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); 
  const toggleDropdown = () => setIsOpen(!isOpen);
   const [updatedTokenHoldings, setUpdatedTokenHoldings] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const navigate = useNavigate();

  const handleTokenClick = (tokenAddress) => {
    navigate(`/dashboard/explorer/t/${tokenAddress}`);
  };




   //uses Jupiter API to fetch prices and calculate the total value of a wallet's total crypto holdings
 // Flag to track if the prices have been fetched
const [pricesFetched, setPricesFetched] = useState(false);

useEffect(() => {
  const fetchPricesAndUpdateHoldings = async () => {
    if (pricesFetched) return;
    setIsLoading(true); // Start loading

    try {
      const pricePromises = tokenHoldings.map(token => {
        return fetchTokenPrice(token.info.symbol, token.address)
               .catch(error => {
                 console.error(`Error fetching price for token ${token.address}:`, error);
                 return null; // Return null for failed requests
               });
      });

      const prices = await Promise.all(pricePromises);
      let updatedHoldings = tokenHoldings.map((token, index) => {
        const priceInfo = prices[index];
        const tokenQuantity = token.balance / Math.pow(10, token.decimals || 0);
        const usdValue = priceInfo ? (priceInfo.price * tokenQuantity) : 0;

        return {
          ...token,
          usdValue: usdValue.toFixed(2)
        };
      });

      updatedHoldings = updatedHoldings.sort((a, b) => parseFloat(b.usdValue) - parseFloat(a.usdValue));
      setUpdatedTokenHoldings(updatedHoldings);

      const totalTokenUsdValue = updatedHoldings.reduce((acc, token) => acc + parseFloat(token.usdValue || 0), 0);
      onTotalTokenValueCalculated(totalTokenUsdValue);
      setPricesFetched(true); // Set the flag indicating that prices have been fetched
    } catch (error) {
      console.error('Error fetching token prices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (tokenHoldings.length && !pricesFetched) {
    fetchPricesAndUpdateHoldings();
  }
}, [tokenHoldings]); // Removed onTotalTokenValueCalculated from dependencies










  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if click is outside
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]); 



    // Format the total value with commas
  const totalValueFormatted = numberWithCommas(
    updatedTokenHoldings.reduce((acc, token) => acc + parseFloat(token.usdValue || 0), 0).toFixed(2)
  );


  

  const fallbackIconUrl = "https://cnemhnnwbhehhupxqoin.supabase.co/storage/v1/object/public/Icons/CircleDashed-r.svg?t=2024-01-02T19%3A56%3A21.493Z";
  
  const customLoadingStyle = {
  display: 'block', // This makes margin auto effective for horizontal centering
  margin: '0 auto', // Centers the loading component horizontally
};


  return (
     <div className="token-holdings-container" ref={dropdownRef}>
    <div className="token-holdings-summary" onClick={toggleDropdown}>
      {isLoading ? (
         <div style={customLoadingStyle}>
           <LoadingComponent loading={isLoading} size={20} marginTop="1px" marginBottom="1px" />
        </div>
      ) : (
        <>
          <span className="token-count">{updatedTokenHoldings.length} Tokens</span>
          <span className="token-total-value">${totalValueFormatted}</span>
        </>
      )}
      <span className="dropdown-icon">{isOpen ? '↑' : '↓'}</span>
    </div>

    {isOpen && (
      <div className="token-holdings-dropdown">
        {isLoading ? (
          <div style={customLoadingStyle}>
           <LoadingComponent loading={isLoading} size={20} marginTop="55px" marginBottom="1px" />
        </div>
        ) : (
          <>
            <div className="token-holding-header">
              <span>TOKEN</span>
              <span>VALUE</span>
              <span>QUANTITY</span>
            </div>
            {updatedTokenHoldings.map((token, index) => {
              const formattedTokenQuantity = numberWithCommas((token.balance / Math.pow(10, token.decimals || 0)).toFixed(2));
              const imageSrc = token.info.image || fallbackIconUrl;

              return (
                <DropdownTokenHoldingsItem
                  key={index}
                  tokenLabel={token.info.name}
                  iconUrl={imageSrc}
                  valueInUSD={token.usdValue}
                  tokenQuantity={`${formattedTokenQuantity} ${token.info.symbol}`}
                  tokenAddress={token.address}
                  onClick={handleTokenClick}
                />
              );
            })}
          </>
        )}
      </div>
    )}
  </div>
  );
};

export default WalletTokenHoldingsDropdown;
