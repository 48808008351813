import React, { useState, useEffect } from 'react';
import WhiteLogo from '../../Media/Logos/WhiteLogo.svg'; 
import './MobileUXWarning.css';

const MobileWarningModal = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Check sessionStorage to see if the modal has already been dismissed in this session
        const modalDismissed = sessionStorage.getItem('modalDismissed');

        if (!modalDismissed) {
            setShowModal(true); // Show the modal if not dismissed before
        }
    }, []);

    const handleDismiss = () => {
        // Set the flag in sessionStorage when the modal is dismissed
        sessionStorage.setItem('modalDismissed', 'true');
        setShowModal(false); // Hide the modal
    };

    if (!showModal) {
        return null; // Don't render the modal if showModal is false
    }

    return (
        <div className="mobile-warning-overlay">
            <div className="mobile-warning-content">
                <img src={WhiteLogo} alt="Blackfin Logo" className="mobile-warning-logo" />
                <p>For the best experience, please use a laptop or desktop computer to explore Blackfin. We're working hard to optimise our services fully for mobile users.</p>
                <button onClick={handleDismiss}>Let me in anyway</button>
            </div>
        </div>
    );
};

export default MobileWarningModal;


