import React, { useState } from 'react';
import './AdditionalTxInfo.css'; 
import { WalletAddressLink } from '../../../../ReusableUtility/WalletAndTxLink/WalletAndTxLink'; 


const AdditionalTxInfo = ({ fee, protocolName, signer, sender, receiver }) => { 
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };







    return (
        <div className="additionalTxInfoContainer">
            <div className="additionalTxInfoHeader" onClick={toggleExpand}>
                <span className="additionalTxInfoTitle">ADDITIONAL TX INFO</span>
                {/* Using a span instead of an SVG for the arrow */}
                <span className="additionalTxInfoDropdownIcon">{isExpanded ? '↑' : '↓'}</span>
            </div>
            {isExpanded && (
                <div className="additionalTxInfoContent">
                    {/* Replace below with actual data */}
                    <div className="additionalTxInfoItem">
                        <span className="additionalTxInfoLabel">FEE</span>
                        <span className="additionalTxInfoValue">{fee !== undefined ? `${fee} SOL` : 'N/A'}</span>
                    </div>
                    <div className="additionalTxInfoItem">
                        <span className="additionalTxInfoLabel">SOURCE/PROTOCOL</span>
                         <span className="additionalTxInfoValue">{protocolName || 'N/A'}</span>
                                                  
                    </div>
                    <div className="additionalTxInfoItem">
                        <span className="additionalTxInfoLabel">SIGNER</span>
                              {signer ? <WalletAddressLink address={signer} /> : 'N/A'}
                    </div>
                    <div className="additionalTxInfoItem">
                         <span className="additionalTxInfoLabel">SENDING WALLET</span>
                         {sender ? <WalletAddressLink address={sender} /> : 'N/A'}
                    </div>
                    <div className="additionalTxInfoItem">
                        <span className="additionalTxInfoLabel">RECEIVING WALLET</span>
                              {receiver ? <WalletAddressLink address={receiver} /> : 'N/A'}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdditionalTxInfo;
