import React from 'react';
import './WalletOverviewComponent.css'; 
import WalletTokenHoldingsDropdown from './TokenHoldingsDropdown/WalletTokenHoldingsDropdown';

const WalletOverview = ({ balance, usdValue, tokenHoldings, onTotalTokenValueCalculated }) => {
  
  
  const formatBalance = (num) => {
  // First, check if `num` is not null and is a valid number
  const number = parseFloat(num); // Attempt to convert `num` to a floating-point number
  // Check if `number` is a valid finite number before calling `toFixed`
  return Number.isFinite(number) ? number.toFixed(5) + ' SOL' : 'Loading...';
};


  const solanaLogoURL = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png";



  return (
     <div className="wallet-overview">
      <h2 className="overview-title">Wallet Overview</h2> 
      <div className="overview-item">
        <span className="overview-label">SOL BALANCE</span> 
        <div className="balance-container">
          <img src={solanaLogoURL} alt="Solana" className="wallet-overview-solana-icon" />
          <span className="overview-data">{formatBalance(balance)}</span>
        </div>
      </div>
      <div className="overview-item">
        <span className="overview-label">SOL VALUE ($)</span> 
        <span className="overview-data">{usdValue !== null ? `${usdValue} USD` : 'Loading...'}</span>
      </div>
      <div className="overview-item">
        <span className="overview-label">SPL TOKEN HOLDINGS</span> 
        <WalletTokenHoldingsDropdown tokenHoldings= {
         tokenHoldings}
          onTotalTokenValueCalculated={onTotalTokenValueCalculated}

/>
      </div>
    </div>
  );
};

export default WalletOverview;
