import React, { useState, useEffect } from 'react';
import './ExplorerMainPageNotice.css';
import moment from 'moment';  // Ensure moment.js is installed

const ExplorerMainPageNotice = () => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const updateCountdown = () => {
            const now = moment.utc();
            const deadline = moment.utc('2024-04-25T17:00:00');  // Adjust the date and time as necessary
            const diff = deadline.diff(now);

            if (diff <= 0) {
                setTimeLeft('00:00:00');
            } else {
                const duration = moment.duration(diff);
                const timeFormat = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
                setTimeLeft(timeFormat);
            }
        };

        const intervalId = setInterval(updateCountdown, 1000);
        return () => clearInterval(intervalId);  // Cleanup interval on component unmount
    }, []);

    return (
        <div className="explorer-main-notice">
            <h2 className="notice-header"><span style={{ color: 'green' }}>✔</span> Transactions are now operational</h2>
            <p className="notice-content">
                Sierra AI (beta) transactions are now currently confirming on-chain.
            </p>
        </div>
    );
};

export default ExplorerMainPageNotice;
