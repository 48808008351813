import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import OnboardingForm from './OnboardingForm';
import Logo from '../../Media/Logos/WhiteLogo.svg'; // Make sure the path is correct

const OnboardingPage = () => {
    const navigate = useNavigate();
    const { publicKey, connected } = useWallet();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
      const [loading, setLoading] = useState(true);

    
       useEffect(() => {
        if (connected && publicKey) {
            const walletAddress = publicKey.toString();
            fetchUserData(walletAddress);
        } else {
            setLoading(false); // Not connected, stop loading
        }
    }, [connected, publicKey, navigate]);



    const fetchUserData = async (walletAddress) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/api/check-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ walletAddress }),
            });

            const data = await response.json();
            if (response.ok && data.onboardingComplete) {
                navigate('/dashboard');
            } else {
                setLoading(false); // User needs to onboard, stop loading
            }
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            setLoading(false); // Error occurred, stop loading
        }
    };

    
    if (loading) {
    return <div>Loading...</div>;
}






     // Check wallet connection and display the onboarding form or wallet connect UI
    if (!connected) {
        return (
            <div className="onboarding-page" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="onboarding-logo" />
                </div>
                <div className="wallet-connect-container" style={{ textAlign: 'center', marginTop: '70px' }}> {/* Adjust margin as needed */}
                    <p>Please connect your wallet to proceed with onboarding.</p>
                    <WalletMultiButton />
                </div>
            </div>
        );
    }

    const walletAddress = publicKey.toString();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Add your logic to save the username, email, and wallet address to Supabase
        // Example: const result = await saveUserData(username, email, walletAddress);
    };

    return (
        <div className="onboarding-page">
            <OnboardingForm
                setUsername={setUsername}
                setEmail={setEmail}
                walletAddress={walletAddress}
                usernameError={usernameError}
                emailError={emailError}
                handleSubmit={handleSubmit}
            />
        </div>
    );
};

export default OnboardingPage;
