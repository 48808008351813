import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../Auth/AppContexts/UserContext';
import { useNavigate } from 'react-router-dom';
import './OnboardingForm.css';
import Button from '../../Components/Buttons/Button';
import Logo from '../../Media/Logos/WhiteLogo.svg';
import { supabase } from '../SupabaseAuth/SupabaseClient';
import IntroTextContainer from './IntroTextContainer'; 
import ReCAPTCHA from 'react-google-recaptcha';
import mixpanelUtils from '../../Auth/MixPanelManager'; 


export default function OnboardingForm({ walletAddress }) {
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [usernameAvailable, setUsernameAvailable] = useState(null);
    const navigate = useNavigate(); // Initialize navigate
    const { setUserId } = useContext(UserContext);
    const [referralCode, setReferralCode] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(''); // reCAPTCHA response token
    const [recaptchaError, setRecaptchaError] = useState(''); 




    // Function to verify reCAPTCHA response on your server
    const verifyRecaptcha = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/api/verify-captcha`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ captchaResponse: recaptchaValue }),
            });

            const data = await response.json();
            return data.success; // Returns true if verification is successful
        } catch (error) {
            console.error('Error verifying reCAPTCHA:', error);
            return false;
        }
    };
  

    // This useEffect is for checking username availability
useEffect(() => {
    if (username.trim() === '' || username.includes('@')) {
        setUsernameAvailable(null);
        return;
    }

    const checkUsernameAvailability = async () => {
        const { data, error } = await supabase
            .from('users')
            .select('username')
            .eq('username', username)
            .single();

        if (error && error.code !== 'PGRST116') {
            setUsernameError('Error checking username');
            return;
        }

        setUsernameAvailable(!data);
    };

    checkUsernameAvailability();
}, [username]); // Run this effect when username changes


    const usernameFeedbackStyle = {
        color: usernameAvailable ? 'green' : 'red',
    };


    // Separate useEffect for auto-populating the referral code
useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const referralCodeFromUrl = queryParams.get('code');
    if (referralCodeFromUrl) {
        setReferralCode(referralCodeFromUrl);
    }
}, []); 









const handleSubmit = async (e) => {
    e.preventDefault();

     // Verify reCAPTCHA first
        const isCaptchaValid = await verifyRecaptcha();
        if (!isCaptchaValid) {
            setRecaptchaError('Failed to verify CAPTCHA. Please try again.');
            return;
        }
    

    if (!usernameAvailable) {
        //console.log('Username not available');
        setUsernameError('Username is not available');
        return;
    }

    const currentDate = new Date();
    const yearOfRegistration = currentDate.getFullYear();
    const monthOfRegistration = currentDate.getMonth() + 1;

    try {
        // First, validate the referral code if it's provided
        if (referralCode && referralCode.trim() !== '') {
            const validationResponse = await fetch(`${process.env.REACT_APP_USER_API_URL}/api/validate-referral-code`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ referralCode })
            });

            const validationResult = await validationResponse.json();
            if (!validationResponse.ok || !validationResult.isValid) {
                console.error('Invalid referral code:', referralCode);
                setUsernameError('Hmm. Invalid referral code, double check it.');
                return;
            }
        }

        // Proceed with user registration
        //console.log('Inserting data into Supabase');
        const { data, error } = await supabase
            .from('users')
            .insert([{
                username: username,
                wallet_address: walletAddress,
                year_of_registration: yearOfRegistration,
                month_of_registration: monthOfRegistration,
                onboarding_complete: true 
            }])
            .select();

        if (error) {
            console.error('Error inserting data:', error);
            setUsernameError('Failed to save user data');
            return;
        }

        if (!data || data.length === 0) {
            console.error('No data returned from insert operation');
            setUsernameError('Failed to complete registration.');
            return;
        }

    

        const userId = data[0].id;
        //console.log('Generated user ID:', userId);
        setUserId(userId);

         mixpanelUtils.trackEvent('New User!', {
            distinct_id: userId,
            username: username,
            // ... other properties you want to track ...
        });


        // Update the user's own referral code
        await updateReferralCode(userId);

        // If a valid referral code was used, handle the referral
        if (referralCode && referralCode.trim() !== '') {
            await handleReferral(userId, referralCode);
        }

        navigate('/dashboard');
    } catch (networkError) {
        console.error('Network error:', networkError);
        setUsernameError('Failed to complete onboarding process due to network error.');
    }

      setRecaptchaError('');
};






const updateReferralCode = async (userId) => {
    const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/api/update-referral-code`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: userId })
    });

    const result = await response.json();
    if (!response.ok) {
        throw new Error(result.message || 'Failed to update referral code.');
    }
};

const handleReferral = async (userId, referralCode) => {
    const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/api/handle-referral`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, referralCode })
    });

    const result = await response.json();
    if (!response.ok) {
        throw new Error(result.message || 'Failed to process referral.');
    }
};
  


    const onCaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError(''); // Clear any existing errors
};





    return (
        <form className="onboarding-form" onSubmit={handleSubmit}>
            <div className="logo-container">
                <img src={Logo} alt="Logo" className="onboarding-logo" />
            </div>
             
              <IntroTextContainer /> {/* Place IntroTextContainer here */}



            <div className="intro-text">
              

            </div>
            <label className="input-label">
                Username
                <input
                    className="input-field"
                    type="text"
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setUsernameError('');
                    }}
                    placeholder="Enter username, no @ sign or spaces"
                />
                {username && (
                    <div style={usernameFeedbackStyle}>
                        {usernameAvailable ? 'Username available' : 'Try again, someone has this username'}
                    </div>
                )}
                {username.includes('@') && (
                    <div className="error-text">Username should not include @ sign</div>
                )}
            </label>
            <label className="input-label">
                Wallet Address
                <input
                    className="input-field"
                    type="text"
                    value={walletAddress}
                    readOnly
                />
            </label>

            <label className="input-label">
                Referral Code (Optional)
                <input
                    className="input-field"
                    type="text"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                    placeholder="Temporarily disabled"
                    disabled={true} 
                />

                <div className="error-text">{usernameError}</div>

            </label>


                    <div className="recaptcha-container" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onCaptchaChange}
                />
                {recaptchaError && <div className="error-text">{recaptchaError}</div>}
            </div>




            <div className="onboarding-submit-button-container">
                <Button text="Go" type="submit" />
            </div>
        </form>
    );
}
