// TGEIntroModal.js
import React, { useState, useEffect } from 'react';
import './TGEIntroModal.css';
import moment from 'moment';
import { ReactComponent as DiscordLogo } from '../../../Media/Icons/DiscordLogo.svg';
import { ReactComponent as TwitterLogo } from '../../../Media/Icons/XIconLogo.svg';
import { ReactComponent as DocsIcon } from '../../../Media/Icons/DocsIcon.svg';
import WhiteLogo from '../../../Media/Logos/WhiteLogoBig.svg'; // Make sure this path is correct


const TGEIntroModal = ({ isOpen, onClose }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const updateCountdown = () => {
      const now = moment.utc();
      const deadline = moment.utc('2024-04-25T17:00:00');
      const diff = deadline.diff(now);

      if (diff <= 0) {
        setTimeLeft('00:00:00');
        onClose();
      } else {
        const duration = moment.duration(diff);
        const timeFormat = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
        setTimeLeft(timeFormat);
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="tge-full-page-overlay" style={{ display: isOpen ? 'flex' : 'none' }}>
      <div className="tge-coming-soon-modal">
        <img src={WhiteLogo} alt="Logo" className="tge-modal-logo" />
        <button onClick={onClose} className="tge-close-modal-button">X</button>
        <h2 className="tge-countdown-header">Countdown to TGE</h2>
        <p className="event-date"><span role="img" aria-label="Calendar">📅</span> 25th April 2024 @ 5pm UTC</p>
           <p className="event-description"> Our TGE ethos and strategy is designed to empower smaller investors to grow alongside us, offering attractive fundamentals without the influence of insiders or key opinion leaders (KOL's).  Check out the links below to join the fam.
</p>
        <p className="tge-countdown-time">{timeLeft}</p>
        <div className="tge-social-links">
          <a href="https://twitter.com/blackfinhq" target="_blank" rel="noopener noreferrer">
            <TwitterLogo className="social-icon" />
          </a>
          <a href="https://discord.gg/jwFZqZt9Dp" target="_blank" rel="noopener noreferrer">
            <DiscordLogo className="social-icon" />
          </a>
          <a href="https://blackfin-explorer.gitbook.io/welcome-to-blackfin/" target="_blank" rel="noopener noreferrer">
            <DocsIcon className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TGEIntroModal;
