import React from 'react';
import { useLocation } from 'react-router-dom'; // Correctly imported useLocation
import './TokenChartModal.css'; // Ensure the CSS file is correctly imported

const TokenChartModal = ({ isOpen, onClose }) => {
    const location = useLocation(); // Correctly using useLocation here
   
    if (!isOpen) return null;

    
    // Extract the token address from the URL using useLocation instead of window.location
    const tokenAddress = location.pathname.split('/').pop();

    //console.log("Token Address:", tokenAddress); // Log the token address

    // Define your desired chart parameters here
    const chartType = "candle"; // Options: candle, line, area, bar, column
    const chartInterval = "60"; // Options: 1, 3, 5, 15, 30, 60, 120, 240, 1D, 1W
    const chartLeftToolbar = "show"; // Options: show, hide

    // Construct the Birdeye iframe URL
    const birdeyeURL = `https://birdeye.so/tv-widget/${tokenAddress}?chain=solana&chartType=${chartType}&chartInterval=${chartInterval}&chartLeftToolbar=${chartLeftToolbar}`;

    //console.log("Birdeye URL:", birdeyeURL); // Log the constructed URL

    return (
        <div className="token-chart-modal-overlay" onClick={onClose}>
            <div className="token-chart-modal-content" onClick={e => e.stopPropagation()}>
                <button onClick={onClose}>X</button> {/* Close button */}
                <iframe
                    title="Token Chart"
                    width="100%"
                    height="400"
                    src={birdeyeURL}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default TokenChartModal;
