// PortfolioAnalyticsPage.js
import React from 'react';
import SidePanel from '../../Components/SidePanel/SidePanel'; // Adjust the path as necessary
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu'; // Adjust the path as necessary
import PortfolioCard from '../../Components/ExplorerFeature/PortfolioPageComponents/PortfolioUICards/PortfolioCards'; // Adjust the path as necessary
import PortfolioCharts from '../../Components/ExplorerFeature/PortfolioPageComponents/PortfolioCharts/PortfolioCharts';
import './PortfolioAnalyticsPage.css';
import WhiteLogo from '../../Media/Logos/WhiteLogo.svg'; // Adjust the path as necessary


const PortfolioAnalyticsPage = () => {
  // Example data - replace with real data as needed
  const portfolioValue = "34,900";
  const cashChange = "2,900";
  const percentChange = "+3.49%";
   const biggestGainer = "Solana";

   const isFeatureUnderConstruction = true;


  return (


    <div className="portfolio-analytics-page">
      {isFeatureUnderConstruction && (
        <div className="portfolio-full-page-overlay">
          <div className="coming-soon-modal">
            <img src={WhiteLogo} alt="Logo" className="modal-logo" /> 
            <p>Coming Soon.</p>
            <p>Imagine asking an AI assistant how you should rebalance your Solana portfolio?</p>
            <p style={{ fontStyle: 'italic' }}>We're more than an explorer.</p>
          </div>
        </div>
      )}
      <SidePanel />
      <div className="portfolio-content">
        <HeaderMenu />
        <div className="portfolio-cards-container">
          <PortfolioCard title="Portfolio Overview" value={`$${portfolioValue}`} />
          <PortfolioCard title="24hr Cash Change" value={`+$${cashChange}`} isPositive />
          <PortfolioCard title="24hr % Change" value={percentChange} isPositive />
        </div>
        <div className="portfolio-main-content">
          <PortfolioCharts />
        </div>
      </div>
    </div>
  );
};

export default PortfolioAnalyticsPage;
