import React from 'react';
import './LatestTrades.css'; // Ensure this CSS file is created

const LatestTradesPanel = () => {
    const trades = [
        // Example trades data
        // Update the trade object structure as per your actual data
        { time: '12:00', type: 'Buy', amount: '50 Tokens', trader: 'User1', nativeAmount: '$100', source: 'Source1' },
        { time: '12:05', type: 'Sell', amount: '30 Tokens', trader: 'User2', nativeAmount: '$95', source: 'Source2' },
        { time: '12:00', type: 'Buy', amount: '50 Tokens', trader: 'User1', nativeAmount: '$100', source: 'Source1' },
        { time: '12:05', type: 'Sell', amount: '30 Tokens', trader: 'User2', nativeAmount: '$95', source: 'Source2' },
        { time: '12:00', type: 'Buy', amount: '50 Tokens', trader: 'User1', nativeAmount: '$100', source: 'Source1' },
        { time: '12:05', type: 'Sell', amount: '30 Tokens', trader: 'User2', nativeAmount: '$95', source: 'Source2' },
        { time: '12:00', type: 'Buy', amount: '50 Tokens', trader: 'User1', nativeAmount: '$100', source: 'Source1' },
        { time: '12:05', type: 'Sell', amount: '30 Tokens', trader: 'User2', nativeAmount: '$95', source: 'Source2' },
        { time: '12:00', type: 'Buy', amount: '50 Tokens', trader: 'User1', nativeAmount: '$100', source: 'Source1' },
        { time: '12:05', type: 'Sell', amount: '30 Tokens', trader: 'User2', nativeAmount: '$95', source: 'Source2' },
        // ... additional trades
    ];

    return (
        <div className="latest-trades-panel">
            
            <div className="trade-item trade-header">
                <span>Time</span>
                <span>Type</span>
                <span>Price</span>
                <span>($) Amount</span>
                <span>Trader</span>
                <span>Native Amount</span>
                <span>Source</span>
            </div>
            {trades.map((trade, index) => (
                <div key={index} className="trade-item">
                    <span>{trade.time}</span>
                    <span>{trade.type}</span>
                    <span>{trade.price}</span>
                    <span>{trade.amount}</span>
                    <span>{trade.trader}</span>
                    <span>{trade.nativeAmount}</span>
                    <span>{trade.source}</span>
                </div>
            ))}
        </div>
    );
};

export default LatestTradesPanel;
