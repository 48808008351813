import React from 'react';
import './SearchResultItem.css'; 

import TokenTagComponent from '../../../../ReusableUtility/TokenTags/TokenTagComponent'; 
import WalletTagComponent from '../../../../ReusableUtility/WalletTags/WalletTagComponent';

import IconGenerator from '../../../../Media/Icons/IconGenerator';
import txFailedImage from '../../../../Media/Icons/TxFailedTag.svg';
import txSuccessImage from '../../../../Media/Icons/TxSuccessTag.svg';
import solTransferIcon from '../../../../Media/Icons/SolTransferIconNew.svg';
import tokenTransferIcon from '../../../../Media/Icons/TokenTransferIcon.svg';
import BurnTxIcon from '../../../../Media/Icons/BurnTxIconTag.svg';

import SwapTxIcon from '../../../../Media/Icons/SwapTxIcon.svg';
import TxTagComponent from '../../../../ReusableUtility/TxTags/TxTagComponent'; 




const SearchResultItem = ({ token, walletData, onClick, txData }) => {
    const defaultLogoUrl = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png';

    const formatBalance = (balance) => {
    return new Intl.NumberFormat('en-US', { 
        style: 'decimal', 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 6 
    }).format(balance);
};


    // Check if it's token data, wallet data or transaction data
    const isToken = token !== null && !txData;
    const isWallet = walletData !== null && !txData;
    const data = isToken ? token : (isWallet ? walletData : null);



    // Format the number of holders or display 'N/A' if not available (for tokens)
    const formattedHolders = isToken && data.holders !== null && data.holders !== undefined 
        ? data.holders.toLocaleString() 
        : 'N/A';


       

    //SEARCH FOR TX INFO  

const renderTransactionInfo = (txData) => {
    if (!txData) {
        return null; // or some placeholder content
    }

     const isSwapTransaction = txData.type === 'SWAP';
    const isBurnTransaction = txData.type === 'TOKEN_BURN';
    const isSolTransfer = txData.type === 'SOL_TRANSFER';
    const isTokenTransfer = txData.type === 'TOKEN_TRANSFER';
    const isSuccess = txData.status === 'Success';
    const isFailure = txData.status === 'Fail';
     const first15 = txData.signatures[0].substring(0, 25);
    const last15 = txData.signatures[0].substring(txData.signatures[0].length - 25);

   
    return (
        <div className="search-result-tx-info">
            <div className="tx-hash-display">
                {/* Replace "TX #:" text with TxTagComponent */}
                <TxTagComponent />
                <span className="tx-hash-text">{`${first15}...${last15}`}</span>
            </div>
            <div className="tx-info-flex-container">
                {isSwapTransaction && (
                    <img src={SwapTxIcon} alt="Swap Transaction" className="tx-type-icon" />
                )}
                {isBurnTransaction && (
                    <img src={BurnTxIcon} alt="Burn Transaction" className="tx-type-icon" />
                )}
                {isSolTransfer && (
                    <img src={solTransferIcon} alt="Sol Transfer" className="tx-type-icon" />
                )}
                {isTokenTransfer && (
                    <img src={tokenTransferIcon} alt="Token Transfer" className="tx-type-icon" />
                )}
                <div className="tx-status">
                    {isSuccess && (
                        <img src={txSuccessImage} alt="Transaction Success" className="tx-status-icon" />
                    )}
                    {isFailure && (
                        <img src={txFailedImage} alt="Transaction Failed" className="tx-status-icon" />
                    )}
                    {!isSuccess && !isFailure && txData.status} {/* Render only status if no specific icon */}
                </div>
            </div>
        </div>
    );
};



 



    //SEARCH FOR TOKEN INFO 

   const renderTokenInfo = (tokenData) => {
    // Conditionally render the holders count only if it exists
    const holdersInfo = tokenData.holders ? `HOLDERS: ${tokenData.holders.toLocaleString()}` : '';

    return (
        <>
            <div className="main-searchbar-token-name-and-tag">
                {tokenData.name ? `${tokenData.name} (${tokenData.symbol})` : 'Unknown Token'}
                <TokenTagComponent /> 
            </div>
            <div className="main-searchbar-token-address">{tokenData.address || 'No address'}</div>
            {tokenData.holders && (
                <div className="main-searchbar-token-holders">{holdersInfo}</div>
            )}
        </>
    );
};







 //SEARCH FOR WALLET INFO 
const renderWalletInfo = (walletData) => {
    if (!walletData) {
        return null; // or some placeholder content
    }
    const hasDomain = walletData.domainName && walletData.domainName.trim().length > 0;
    const formattedBalance = walletData.balance ? formatBalance(parseFloat(walletData.balance)) : 'N/A';

    return (
        <div className="search-result-wallet-info">
            <div className="wallet-info-header">
                {/* Keep domain information and WalletTagComponent next to each other but not in the same container */}
                <span className={hasDomain ? "search-result-domain-tag" : "search-result-no-domain-tag"}>
                    {hasDomain ? walletData.domainName : 'No Domains'}
                </span>
                <WalletTagComponent />
            </div>
            <div className="main-searchbar-wallet-address">{walletData.address || 'No address'}</div>
            <div className="main-searchbar-wallet-balance">BALANCE: {formattedBalance} SOL</div>
        </div>
    );
};






    return (
          <div className="search-result-item" onClick={onClick}>
        {token ? (
             <img 
                src={token.image || defaultLogoUrl} 
                alt={`${token.name ? token.name : 'Unknown'} logo`} 
                className="main-searchbar-token-logo" 
                />
        ) : txData ? (

            // Placeholder for transaction icon
            <div className="icon-generator-container">
                <IconGenerator tokenHash={txData.signatures[0]} />
            </div>

        ) : (

            <div className="icon-generator-container">
                <IconGenerator tokenHash={walletData.address} />
            </div>
        )}
        <div className="token-info">
            {token ? renderTokenInfo(token) 
                : txData ? renderTransactionInfo(txData)
                : renderWalletInfo(walletData)}
        </div>
    </div>
    );
};

export default SearchResultItem;