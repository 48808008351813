
// TokenLink.js
import React from 'react';
import './TokenLink.css'; // Import the CSS file

// Function to create a token link
export const createTokenLink = (tokenAddress) => {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    return `${baseUrl}/dashboard/explorer/t/${tokenAddress}`;
};

// Component to render a clickable token link
const TokenLink = ({ tokenAddress, symbol, isSolTransfer = false }) => {
    const solAddress = 'So11111111111111111111111111111111111111112';
    const href = isSolTransfer ? createTokenLink(solAddress) : (tokenAddress ? createTokenLink(tokenAddress) : '#');

    return <a href={href} className="TokenLinkStyle">{symbol}</a>; // Apply the unique class name
};

export default TokenLink;
