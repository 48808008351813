

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './TokenDetailsPage.css';
import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu';
import TokenNameAndLogoDisplay from '../../Components/ExplorerFeature/TokenExplorerMainPage/TokenNameAndLogoDisplay/TokenNameAndLogoDisplay';
import TokenOverview from '../../Components/ExplorerFeature/TokenExplorerMainPage/TokenOverviewComponent/TokenOverviewComponent'; 
import TokenInfoComponent from '../../Components/ExplorerFeature/TokenExplorerMainPage/TokenInfoComponent/TokenInfoComponent'; 
import TokenDataNavigation from '../../Components/ExplorerFeature/TokenDataButtonNavigation/TokenDataButtonNavigation'; 
import ParentTokenDetailsComponent from '../../Components/ExplorerFeature/ParentTokenDetailsComponenet/ParentTokenDetailsComponent';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import Button from '../../Components/Buttons/Button'; 
import TokenHoldersList from '../../Components/ExplorerFeature/TokenExplorerMainPage/HoldersList/TokenHoldersList';
import { fetchTokenPrice } from '../../APIFunctions/FetchTokenPrice';
import LoadingComponent from '../../ReusableUtility/LoadingComponent'; 
import TokenChartModal from '../../Components/ExplorerFeature/TokenExplorerMainPage/TokenChartModal/TokenChartModal'; 
import FullPageLoader from '../../ReusableUtility/FullPageUILoading';


const TokenDetailsPage = () => {
  const { tokenAddress } = useParams();
  const [tokenData, setTokenData] = useState({
    logoUri: '',
    name: '',
    symbol: '',
    description: '',
    supply: 0,
    holders: 0,
    isSolanaToken: false 
  });







   const [tokenTransactions, setTokenTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenMetaInfo, setTokenMetaInfo] = useState({});
  const [lastTxSignature, setLastTxSignature] = useState(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activePanel, setActivePanel] = useState('transfers'); // 'transactions' as default
  const [tokenHoldersList, setTokenHoldersList] = useState([]);
  const isPollingNeededForList = useRef(true); 
  const isPollingNeeded = useRef(true);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [pricesFetched, setPricesFetched] = useState(false);
   const location = useLocation();
   const [isFetchingTokenHolders, setIsFetchingTokenHolders] = useState(false);
   const [selectedTokenAddress, setSelectedTokenAddress] = useState('');
    const [websiteAndSocials, setWebsiteAndSocials] = useState({ websites: [], socials: [] });

   
   
   
   //Mnaages global loading status

   const [isGlobalLoading, setGlobalLoading] = useState(true);
   const [loadingStatuses, setLoadingStatuses] = useState({
  tokenPriceAndUpdate: true,
  tokenData: true,

   });

const hasAllComponentsLoaded = () => {
  return Object.values(loadingStatuses).every(status => !status);
};

useEffect(() => {
  setGlobalLoading(!hasAllComponentsLoaded());
}, [loadingStatuses]);






   
   

//fetches price from jupiter based on token address
useEffect(() => {
    const fetchTokenPriceAndUpdate = async () => {
        setLoadingStatuses(prevStatuses => ({ ...prevStatuses, tokenPriceAndUpdate: true })); // Start loading

        try {
            const priceResponse = await fetchTokenPrice(tokenData.symbol, tokenAddress, "USDC");
            const price = priceResponse ? priceResponse.price : null;

            if (price !== null) {
                setTokenPrice(price);
            } else {
                //console.log('No price returned from fetchTokenPrice function.');
            }
        } catch (error) {
            console.error('Error fetching token price:', error);
        } finally {
            setLoadingStatuses(prevStatuses => ({ ...prevStatuses, tokenPriceAndUpdate: false })); 
        }
    };

  
    setPricesFetched(false);
    fetchTokenPriceAndUpdate();

}, [tokenData.symbol, tokenAddress, location]); 








  // Fetch basic token data except the holders count
// Fetch basic token data except the holders count
useEffect(() => {
  const fetchTokenData = async () => {
    setLoadingStatuses(prevStatuses => ({ ...prevStatuses, tokenData: true })); // Start loading token data

    try {
      const apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/token-info?tokenAddress=${tokenAddress}`);
      if (!apiResponse.ok) throw new Error(`HTTP error! Status: ${apiResponse.status}`);
      const apiData = await apiResponse.json();

      const mergedTokenData = {
        logoUri: apiData.result.image,
        name: apiData.result.name,
        symbol: apiData.result.symbol,
        description: apiData.result.description,
        supply: apiData.result.current_supply,
        // Holders will be fetched separately
        address: apiData.result.address,
        mintAuthority: apiData.result.mint_authority,
        decimals: apiData.result.decimals,
        isSolanaToken: true,
      };

      setTokenData(prevState => ({ ...prevState, ...mergedTokenData }));
    } catch (error) {
      console.error('Error fetching token data:', error);
    } finally {
      setLoadingStatuses(prevStatuses => ({ ...prevStatuses, tokenData: false })); // End loading token data
    }
  };

  fetchTokenData();
}, [tokenAddress, location, setTokenData]); 






// Fetch holders count separately and update immediately
useEffect(() => {
    // Immediately set holders to null to indicate loading
    setTokenData(prevState => ({
        ...prevState,
        holders: null
    }));

    // Define the function to fetch holders count
    const fetchHoldersCount = async () => {
        const holdersCountUrl = `${process.env.REACT_APP_BASE_URL}/total-holders-count?mintAddress=${tokenAddress}`;

        try {
            const response = await fetch(holdersCountUrl);
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const { totalHoldersCount } = await response.json();

            // Update state with the fetched holders count
            setTokenData(prevState => ({
                ...prevState,
                holders: totalHoldersCount
            }));
        } catch (error) {
            console.error(`Error fetching holders count for tokenAddress: ${tokenAddress}:`, error);
        }
    };
    // Call the fetch function immediately on effect run
    fetchHoldersCount();

   }, [tokenAddress]); 













//Fetches token address tx hsiotry
const fetchTokenTransactions = async (loadMore = false) => {
  setLoadingMore(loadMore);
  setIsLoading(!loadMore);

  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/api/Fetch-Token-TxHistory?tokenAddress=${tokenAddress}&tx_num=100`;

    if (loadMore && lastTxSignature) {
      apiUrl += `&before_tx_signature=${lastTxSignature}`;
    }

    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`Error fetching token transactions: ${response.statusText}`);
    }

    const fetchedData = await response.json();

    if (loadMore) {
      setTokenTransactions(prevTransactions => [...prevTransactions, ...fetchedData.result]);
    } else {
      setTokenTransactions(fetchedData.result);
    }

    if (fetchedData.result.length > 0) {
      const lastTransactionInBatch = fetchedData.result[fetchedData.result.length - 1];
      setLastTxSignature(lastTransactionInBatch.signatures[0]);
    }

    setCanLoadMore(fetchedData.result.length >= 100);

  } catch (error) {
    console.error('Error fetching token transaction history:', error);
  } finally {
    setLoadingMore(false);
    setIsLoading(false);
  }
};

useEffect(() => {
  if (tokenAddress) {
    fetchTokenTransactions();
  }
}, [tokenAddress, location]);





//Fetch holders list data
useEffect(() => {
    // Define a function to fetch token holders list
    const fetchTokenHolders = async () => {
  if (!isPollingNeededForList.current) return;
  
  setIsFetchingTokenHolders(true); // Start loading before the fetch
  const holdersListUrl = `${process.env.REACT_APP_BASE_URL}/token-holders-list?mintAddress=${tokenAddress}`;

  try {
    const response = await fetch(holdersListUrl);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    const data = await response.json();
    if (data.tokenHolders && data.tokenHolders.length > 0) {
      setTokenHoldersList(data.tokenHolders);
      isPollingNeededForList.current = false;
    } else {
      //console.log('No token holders found or data is still loading');
    }
  } catch (error) {
    console.error('Error fetching token holders list:', error);
  } finally {
    setIsFetchingTokenHolders(false); // End loading after the fetch
  }
};


    // Execute fetch immediately if the active panel is 'holders'
    if (activePanel === 'holders') {
      fetchTokenHolders();
    }

    // Setup polling at specified intervals
    let intervalId;
    if (activePanel === 'holders' && isPollingNeededForList.current) {
      intervalId = setInterval(fetchTokenHolders, 1000); // Adjust interval as needed
    }

    // Cleanup function to clear interval when component unmounts or conditions change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

  }, [activePanel, tokenAddress, location]);





// Function to fetch specific token information such as symbols, images etc for a  token address
const fetchTokenMetadata = async (tokenAddress) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/token-info?tokenAddress=${encodeURIComponent(tokenAddress)}`;
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const jsonResponse = await response.json();

    // Check if the API call was successful and has the result
    if (jsonResponse.success && jsonResponse.result) {
      setTokenMetaInfo(prevMetaInfo => ({
        ...prevMetaInfo,
        [tokenAddress]: {
          symbol: jsonResponse.result.symbol,
          image: jsonResponse.result.image
        }
      }));
    } else {
      throw new Error(jsonResponse.message || "Unknown error occurred");
    }
  } catch (error) {
    console.error("Error fetching token metadata:", error);
    setTokenMetaInfo(prevMetaInfo => ({
      ...prevMetaInfo,
      [tokenAddress]: { symbol: 'TOKEN', image: null } // Fallback values
    }));
  }
};


useEffect(() => {
  // Define a new Set to hold unique token addresses
  const uniqueTokenAddresses = new Set();

  // Iterate over all transactions
  tokenTransactions.forEach(tx => {
    // Look for token addresses in all actions that have a token_address field
    tx.actions?.forEach(action => {
      if (action.info?.token_address && !tokenMetaInfo[action.info.token_address]) {
        uniqueTokenAddresses.add(action.info.token_address);
      }
    });
  });

  // Fetch metadata for each unique token address
  uniqueTokenAddresses.forEach(tokenAddress => {
    fetchTokenMetadata(tokenAddress);
  });
}, [tokenTransactions, tokenMetaInfo, location]);




//calls the geenral dexscreener api to fetch website and socials for the info componenet
  useEffect(() => {
    const fetchTokenDetails = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/full-token-details?address=${tokenAddress}`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        //console.log("Fetched token details:", data); // Log the API response

        setWebsiteAndSocials({
          websites: data.websites || [],
          socials: data.socials || [],
        });
      } catch (error) {
        console.error('Failed to fetch token details:', error);
      }
    };

    if (tokenAddress) {
      fetchTokenDetails();
    }
  }, [tokenAddress]);







 const handleNavigation = (targetComponent) => {
  //console.log(`Navigate to ${targetComponent}`);
  setActivePanel(targetComponent); // Update the active panel state
};



if (isGlobalLoading) {
  return <FullPageLoader />;
}







  return (
    <div className="token-details-container">
    <SidePanel />
  
    <HeaderMenu />
   
      <TokenNameAndLogoDisplay
        logoUri={tokenData.logoUri}
        name={tokenData.name}
        symbol={tokenData.symbol}
        IsASolanaToken={tokenData.isSolanaToken}
        
        
      />

      
      <div className="overview-and-info-container"> {/* This container is for the side-by-side layout */}
       
        <TokenOverview
          description={tokenData.description}
          supply={tokenData.supply}
          holders={tokenData.holders}
          decimals={tokenData.decimals}
          currentPrice={tokenPrice}
        />


        <TokenInfoComponent
       
        name={tokenData.name}
        address={tokenData.address}
        decimals={tokenData.decimals}
        website={websiteAndSocials.websites.length > 0 ? websiteAndSocials.websites[0] : ''}
        socials={websiteAndSocials.socials}
      />
      </div>

      
       <TokenDataNavigation onNavigate={handleNavigation} activePanel={activePanel} 

       
       
       
       />

       <TokenChartModal
       
        tokenAddress={selectedTokenAddress} 
      />



    {/* Conditional rendering based on activePanel */}
    {activePanel === 'transfers' && (
      <ParentTokenDetailsComponent
        transactions={tokenTransactions}
        isLoading={isLoading}
        tokenAddress={tokenAddress}
        tokenMetaInfo={tokenMetaInfo}
        fetchTokenMetadata={fetchTokenMetadata}
      />
    )}


    {activePanel === 'holders' && (
  <TokenHoldersList
    tokenHoldersList={tokenHoldersList}
   totalSupply={tokenData.supply} 
   currentPrice={tokenPrice}
   isFetchingTokenHolders={isFetchingTokenHolders} 
  
  />




)}



      

  {activePanel === 'transfers' && canLoadMore && (
  <div style={{ position: 'relative', height: 'fit-content' }}>
    <Button
      text={loadingMore ? 'Loading...' : 'Load More Transactions'}
      color="primary"
      isLoading={loadingMore}
      onClick={() => fetchTokenTransactions(true)}
      type="button"
      style={{
        position: 'absolute',
        left: '1px',
        right: '150px',
        marginBottom: '20px',
      }}
    />
  </div>
)}



    </div>
 
  );
};

export default TokenDetailsPage;
