// TxTagComponent.js
import React from 'react';
import './TxTagComponent.css';

const TxTagComponent = () => {
    return (
        <span className="transaction-tag-display">Transaction</span>
    );
};

export default TxTagComponent;
