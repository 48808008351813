import React, { useState } from 'react';
import './Explorer.css';
import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu';
import ExplorerMainBarSearch from '../../Components/ExplorerFeature/ExploreSearchBar/ExplorerMainSearchBar';
import ExplorerMainPageNotice from './ExplorerMainNotice/ExplorerMainPageNotice'; // Import the new component
import IntroModal from './WelcomeModal/ExplorerWelcomeModal';
import WhiteLogo from '../../Media/Logos/WhiteLogoBig.svg'; 

function Explorer() {
    const [data, setData] = useState(null);
    const [showModal, setShowModal] = useState(true); 
    
    const fetchData = async (searchTerm) => {
        // Implement data fetching logic based on searchTerm
    };

    return (
        <div className="explorer-container">
            <SidePanel />
            <div className="main-content">
                <HeaderMenu />
                <IntroModal isOpen={showModal} onClose={() => setShowModal(false)} />
                <div className="explorer-content">
                    <img src={WhiteLogo} alt="Logo" className="explore-page-black-logo" />
                    <ExplorerMainBarSearch onSearch={fetchData} />
                    <ExplorerMainPageNotice /> 
                    <div className="explore-page-info-container"> 
                    </div>
                    {data && <div>{JSON.stringify(data, null, 2)}</div>}
                </div>
            </div>
        </div>
    );
}

export default Explorer;
