import React from 'react';
import LoadingComponent from '../ReusableUtility/LoadingComponent'; // Ensure the path to your LoadingComponent is correct
import { useTheme } from '../Auth/AppContexts/ThemeContext'; // This might still be used if you want to dynamically set the background color based on the theme

const FullPageLoader = () => {
    const { theme } = useTheme(); // Access the current theme if needed for background color

    // Dynamically set the background color based on the theme, if desired
    const fullPageLoadingStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme === 'dark' ? 'black' : 'white', // Or use a fixed color if preferred
        zIndex: 9999,
    };

    return (
        <div style={fullPageLoadingStyle}>
            <LoadingComponent loading={true} />
        </div>
    );
};

export default FullPageLoader;
