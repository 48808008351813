// TokenTagComponent.js
import React from 'react';
import './TokenTagComponent.css';

const TokenTagComponent = () => {
    return (
        <span className="token-tag-display">Token</span>
    );
};

export default TokenTagComponent;
