import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN; // Stored in your .env file

const mixpanelUtils = {
  initialize: () => {
    mixpanel.init(MIXPANEL_TOKEN, { debug: false }); // Disable debug logging
  },
  trackEvent: (eventName, properties = {}) => {
    if (MIXPANEL_TOKEN) { // Ensure Mixpanel is initialized only if token is present
        mixpanel.track(eventName, properties);
    }
  },
  // Add other Mixpanel functions you need, such as identify, setProfile, etc.
};

export default mixpanelUtils;
