import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for React Router v6
import './ExplorerMainSearchBar.css';
import SearchResultItem from './SearchResultItemComponent/SearchResultItem'; 
import LoadingComponent from '../../../ReusableUtility/LoadingComponent';
import { ReactComponent as SearchIcon } from '../../../Media/Icons/ExplorerSearchIcon.svg';
import { ReactComponent as SearchIconDark } from '../../../Media/Icons/SearchIconDarkMode.svg';
import { useTheme } from '../../../Auth/AppContexts/ThemeContext';
import { PublicKey } from '@solana/web3.js';
import { useSolanaConnection } from '../../../Auth/AppContexts/SolanaConnectionContext';

const debounceFunction = debounce((fetchFunction, input) => {
    fetchFunction(input);
}, 500);


const ExplorerMainSearchBar = ({ className }) => {
   const { theme } = useTheme();
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const ref = useRef(null);
    const navigate = useNavigate();
    const [hasSearched, setHasSearched] = useState(false);
    const [addressError, setAddressError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const connection = useSolanaConnection();


   

    



// Function to check if the search term is a valid Solana wallet address
const isValidSolanaAddress = (address) => {
    return /^[1-9A-HJ-NP-Za-km-z]{32,50}$/i.test(address);
};


const handleInputChange = async (event) => {
    const input = event.target.value.trim();
     
    setSearchTerm(input);
    setIsLoading(true);
    setAddressError('');
    setSearchResults([]);

    let validSearch = false;

    // Check for transaction hash length
    if (input.length >= 64) {
        const txData = await fetchTransactionData(input);
        if (txData && txData.isValid) {
            setSearchResults([{ ...txData, isTx: true }]);
            validSearch = true;
        } else {
            setAddressError('No data found for the entered Transaction Hash');
        }
    } else {
        const addressType = await checkAddressType(input);
        
        if (addressType === 'token') {
            await fetchAndSetSearchResults(input);
            validSearch = true;
        } else if (addressType === 'wallet') {
            await fetchAndHandleWalletAddress(input);
            validSearch = true;
        } else {
            await searchByTokenSymbol(input);
        }
    }
    
    setIsLoading(false);
    if (!validSearch && !input) {
        setSearchResults([]);
        setAddressError('Input does not match any tokens, wallets, or transactions.');
    }
};



// Assume this function exists and refactors the DexScreener API call
// Modify searchByTokenSymbol to accept validSearch as an argument and return it
async function searchByTokenSymbol(symbol, validSearch) {
    try {
        const dexScreenerAPI = `https://api.dexscreener.com/latest/dex/search/?q=${symbol}`;
        const response = await fetch(dexScreenerAPI);
        if (!response.ok) {
            throw new Error(`Failed to fetch data, status: ${response.status}`);
        }
        const data = await response.json();

        const solanaTokens = processSolanaTokens(data, symbol);
        if (solanaTokens.length > 0) {
            setSearchResults(solanaTokens);
            validSearch = true; // Update validSearch based on the search result
        } else {
            setAddressError('No matching tokens found');
        }
    } catch (error) {
        setAddressError('Error occurred during the search');
    }
    return validSearch; // Return the updated validSearch status
}


// Helper function to process tokens from DexScreener response
function processSolanaTokens(data, input) {
    const solanaTokenMap = new Map();
    data.pairs.filter(pair => pair.chainId === 'solana').forEach(pair => {
        if (!solanaTokenMap.has(pair.baseToken.address)) {
            solanaTokenMap.set(pair.baseToken.address, {
                address: pair.baseToken.address,
                name: pair.baseToken.name,
                symbol: pair.baseToken.symbol,
                image: pair.info?.imageUrl,
                isToken: true,
            });
        }
    });

    return Array.from(solanaTokenMap.values()).sort((a, b) => {
        const nameMatchA = a.name.toLowerCase().includes(input.toLowerCase()) ? -1 : 0;
        const nameMatchB = b.name.toLowerCase().includes(input.toLowerCase()) ? -1 : 0;
        return nameMatchA - nameMatchB;
    });
}



















 
// Function to fetch transaction data based on a tx hash entered
const fetchTransactionData = async (txHash) => {
    //console.log(`Fetching data for transaction hash: ${txHash}`);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/txData-for-TxDetailsPage?txnSignature=${txHash}`);
        if (!response.ok) {
            throw new Error(`Network response was not ok, status: ${response.status}`);
        }

        const transactionResponse = await response.json();
        //console.log('Parsed transaction data:', transactionResponse);

        if (transactionResponse && transactionResponse.success && transactionResponse.result) {
            const { type, status, signatures } = transactionResponse.result;
            return {
                type, 
                status, 
                signatures, 
                isTx: true,
                isValid: true // This flag is set based on the presence of the 'success' property in the response
            };
        } else {
            console.error('No transaction data found');
            return { isValid: false };
        }
    } catch (error) {
        console.error('Failed to fetch transaction data:', error);
        return { isValid: false };
    }
};







    // Function to check if a given address is a token address or wallet address

  const checkAddressType = async (address) => {
    //console.log(`Checking address type for: ${address}`); // Log the address being checked

    try {
        const serverBaseUrl = process.env.REACT_APP_BASE_URL 
        const endpoint = `${serverBaseUrl}/checkAddressType/${address}`;
        //console.log(`Making request to: ${endpoint}`); // Log the full endpoint URL being requested

        const response = await fetch(endpoint);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const { type } = await response.json();
        //console.log(`Received response: Address is a '${type}'.`); // Log the type received from the backend

        // 'type' will be 'wallet', 'token', or 'unknown' as determined by your backend
        return type;
    } catch (error) {
        console.error('Error checking address type:', error);
        return 'error';
    }
};







// API call to fetch token information using internal endpoint
const fetchAndSetSearchResults = async (tokenAddress) => {
    try {
        const apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/token-info?tokenAddress=${tokenAddress}`);
        if (!apiResponse.ok) {
            throw new Error(`Network response was not ok, status: ${apiResponse.status}`);
        }
        const tokenInfoFromAPI = await apiResponse.json();

        let searchResult = tokenInfoFromAPI.result ? [{ ...tokenInfoFromAPI.result, isToken: true }] : [];
        setSearchResults(searchResult);

        fetch(`${process.env.REACT_APP_BASE_URL}/total-holders-count?mintAddress=${tokenAddress}`)
            .then(response => {
                if (!response.ok) throw new Error(`Network response was not ok, status: ${response.status}`);
                return response.json();
            })
            .then(({ totalHoldersCount }) => {
                if (totalHoldersCount !== undefined) {
                    setSearchResults(currentResults =>
                        currentResults.map(token =>
                            token.address === tokenAddress ? { ...token, holders: totalHoldersCount } : token
                        )
                    );
                }
            })
            .catch(error => {
                console.error('Failed to fetch holders count:', error);
                // Here, you choose not to reset search results, preserving existing data
            });
    } catch (error) {
        console.error('Failed to fetch token information:', error);
        // Instead of clearing search results, you might log the error or handle it differently.
        // Do not clear search results here to keep any valid results visible.
    }
};







const fetchAndHandleWalletAddress = async (walletAddress) => {
    //setIsLoading(true);

    try {
        // Fetch SOL balance
        const balanceResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/sol-balance?address=${walletAddress}`);
         
        //using `shyft API
        // const balance = await balanceResponse.json(); 

        //using RPC hod
        const balanceData = await balanceResponse.json(); // Directly getting the balance

        


        // Fetch Solana domain names
        const domainResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/sol-domain-names?walletAddress=${walletAddress}`);
        const domainJson = await domainResponse.json();
        const domainName = domainJson.result && domainJson.result.length > 0 ? domainJson.result[0].name : null;

        // Preparing the result object
        const walletResult = {
            address: walletAddress,
            
            //Using shyft API extraction 
             //balance: balance.toString(), 

            //RPC sol balance extraction
             balance: balanceData.solBalance.toString(), // Convert balance to string

            domainName: domainName,

            isWallet: true
        };

        setSearchResults([walletResult]);
    } catch (error) {
        console.error('Error fetching wallet information:', error);
        setSearchResults([]);
    } finally {
        //setIsLoading(false);
    }
};





// Outside your component
const debouncedFetchAndSetSearchResults = debounce(fetchAndSetSearchResults, 500);

// Inside useEffect
useEffect(() => {
    if (searchTerm) {
        debouncedFetchAndSetSearchResults(searchTerm);
    } else {
        setSearchResults([]);
    }

    // Cleanup
    return () => debouncedFetchAndSetSearchResults.cancel();
}, [searchTerm]);





    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };



    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



const navigateToDetails = (result) => {
    setShowDropdown(false);
    setSearchTerm('');

    if (result.isWallet) {
        // Navigate to wallet details page
        navigate(`/dashboard/explorer/w/${result.address}`);
    } else if (result.isToken) {
        // Navigate to token details page
        navigate(`/dashboard/explorer/t/${result.address}`);
    } else if (result.isTx) {
        // Navigate to transaction details page using the first signature as the txHash
        const txHash = result.signatures && result.signatures.length > 0 ? result.signatures[0] : null;
        if (txHash) {
            navigate(`/dashboard/explorer/tx/${txHash}`);
        } else {
            // Handle the case where txHash is not available
            console.error('Transaction hash is undefined');
            // Possibly navigate to a default page or show an error message
        }
    }
};





// Inside ExplorerMainSearchBar component or wherever `navigateToDetails` is accessible
const handleClick = (e, result) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    navigateToDetails(result); // Navigate based on the result
};





      
    return (
         <div className={`explorer-searchbar-container ${className}`} ref={ref}>
            {theme === 'dark' ? (
                <SearchIconDark className="explorer-search-icon-dark" />
            ) : (
                <SearchIcon className="explorer-search-icon" />
            )}
            <input
                type="text"
                className="explorer-search-input"
                onChange={handleInputChange}
                onFocus={() => setShowDropdown(true)}
                placeholder="Search for any token, wallet address or tx hash..."
                value={searchTerm}
                spellCheck="false" 
            />

        {showDropdown && (
            <div className="explorer-search-dropdown">
                {isLoading ? (
                    <LoadingComponent loading={isLoading} />
                ) : searchResults.length > 0 ? (
                    searchResults.map((item) => (


                        <SearchResultItem 
                            key={item.isTx ? item.txHash : item.address}
                            token={item.isToken ? item : null}
                            walletData={item.isWallet ? item : null}
                            txData={item.isTx ? item : null}
                            onClick={(e) => handleClick(e, item)}
                        />
                        
                    ))
                ) : (
                 
                    <div className="defualt-search-message">
                        Search the Solana blockchain...
                    </div>
                )}
                {hasSearched && searchResults.length === 0 && (
                    <div className="search-message">
                        No search results found, try again.
                    </div>
                )}
            </div>
        )}
    </div>
    );
};

export default ExplorerMainSearchBar;