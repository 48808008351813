//this manages the clickable items in the dropdown in the wallet overview
import React from 'react';
import './DropdownTokenHoldingsItem.css'; 
import { numberWithCommas } from '../../../../../../ReusableUtility/FormatNumberWithCommas';

const DropdownTokenHoldingsItem = ({ tokenLabel, iconUrl, valueInUSD, tokenQuantity, tokenAddress, onClick }) => {
  // Format the token quantity and value with commas
  const formattedTokenQuantity = numberWithCommas(tokenQuantity);
  const formattedValueInUSD = numberWithCommas(valueInUSD);

  return (
    <div className="dropdown-item-container" onClick={() => onClick(tokenAddress)}>
      <div className="dropdown-item-icon-label">
        <img src={iconUrl} alt={tokenLabel} className="dropdown-item-icon" />
        <span className="dropdown-item-token-name">{tokenLabel}</span>
      </div>
      <span className="dropdown-item-value">${formattedValueInUSD}</span>
      <span className="dropdown-item-amount">{formattedTokenQuantity}</span>
    </div>
  );
};

export default DropdownTokenHoldingsItem;
