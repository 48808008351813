import React from 'react';
import './ReferMainTable.css';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'; // For the wallet connect button
import UsernameLink from '../../../../ReusableUtility/UsernameWalletLink/UsernameWalletLink';

const ReferMainTable = ({ data }) => {
    const { connected } = useWallet();

    return (
          <div className="refer-table-container">
            <div className="refer-table-header-row">
                <div># RANK</div>
                <div>USER</div>
                <div>LOYALTY POINTS (SOON) </div>
                <div>REFERRAL POINTS</div>
                <div>TOTAL POINTS</div>
            </div>
            
            {!connected ? (
                <div className="refer-table-content-row centered">
                    <div className="wallet-connect-prompt">
                        <p>Please connect your wallet to view the leaderboard and your unique ref link.</p>
                        <WalletMultiButton />
                    </div>
                </div>
            ) : !Array.isArray(data) || data.length === 0 ? (
                <div className="refer-table-content-row centered">
                    <div className="no-data-message">No leaderboard data available.</div>
                </div>
            ) : (
                data.map((user, index) => (
                    <div key={index} className="refer-table-content-row">
                        <div>{index + 1}</div>
                        <div>
                             <UsernameLink username={user.username} walletAddress={user.wallet_address} />
                        </div>
                        <div>{user.usage_points || 'N/A'}</div> {/* Updated to use real data if available */}
                        <div>{user.referral_points !== undefined ? user.referral_points : '0'}</div>
                        <div>{user.total_points !== undefined ? user.total_points : '0'}</div>
                    </div>
                ))
            )}
        </div>
    );
};

export default ReferMainTable;
