//formats number siwht commas

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};






//Formats, SOL decimals, token transfers, add commas where neccesary - MAIN ONE FOR MOST TX TYPES

export const formatNumber = (num) => {
  // Check if num is null or undefined
  if (num == null) {
    return 'N/A'; // or any other placeholder you prefer
  }

  if (num < 1) {
    // For decimals less than 1, show full decimal
    return num.toString();
  } else {
    // For numbers 1 or greater
    let parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts.length > 1) {
      // If there is a fractional part, limit to two decimal places
      parts[1] = parts[1].substring(0, 2);
    }
    return parts.join(".");
  }
};



 

//BURN TX'S
export const formatBurnAmount = (amount) => {
    // Check if the amount is an integer or has decimals
    if (amount % 1 === 0) {
        // If it's an integer, return without decimal places
        return amount.toLocaleString();
    } else {
        // If it has decimals, return with up to 2 decimal places
        return amount.toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
};



//Used maninly for SOL display on Sieraa AI
export function formatSOLAmount(amount) {
    const num = Number(amount);
    if (num === 0) {
        return '0';  // Return '0' if the amount is zero, without 'SOL'
    }
    const amountStr = String(amount);
    const decimalPos = amountStr.indexOf('.');
    let decimals = 0;
    if (decimalPos !== -1) {
        decimals = amountStr.length - decimalPos - 1; // Calculate the number of decimals based on the user input
    }
    return num.toFixed(decimals); // Return the formatted number without appending 'SOL'
}

