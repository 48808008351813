import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RedirectToOnboarding = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.startsWith('/refer')) {
            const queryParams = new URLSearchParams(location.search);
            const referralCode = queryParams.get('code');
            if (referralCode) {
                navigate(`/onboarding?code=${referralCode}`);
            }
        }
    }, [location, navigate]);

    return null; // This component doesn't render anything
};

export default RedirectToOnboarding;
