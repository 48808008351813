import React from 'react';
import HeaderMenu from '../../../Components/HeaderMenu/HeaderMenu';
import SidePanel from '../../../Components/SidePanel/SidePanel';
import CreateTokenForm from '../CreateTokensPage/CreateTokenForm';
import { useWallet } from '@solana/wallet-adapter-react';
import './CreateSPLTokens.css'; // Ensure the CSS file is imported

const SPLTokenPage = () => {
    const { publicKey } = useWallet();
    const walletAddress = publicKey ? publicKey.toBase58() : '';



    return (
        <div className="page-container">
            <HeaderMenu />
            <div className="content-container">
                <SidePanel />
                <div className="create-tokens-container">
                    <CreateTokenForm walletAddress={walletAddress} />
                </div>
            </div>
        </div>
    );
};

export default SPLTokenPage;
