import React from 'react';
import txSuccessImagePath from '../../../../Media/Icons/TxSuccessTag.svg';
import txFailedImagePath from '../../../../Media/Icons/TxFailedTag.svg';
import txPendingImagePath from '../../../../Media/Icons/TxPendingTag.svg';
import solTransferIconPath from '../../../../Media/Icons/SolTransferIconNew.svg';
import tokenTransferIconPath from '../../../../Media/Icons/TokenTransferIcon.svg';
import otherTxIconPath from '../../../../Media/Icons/OtherTxIcon.svg';
import './TxStatusAndTypeComponent.css'; 
import SwapTxIconPath from '../../../../Media/Icons/SwapTxIcon.svg';
import BurnTxIconPath from '../../../../Media/Icons/BurnTxIconTag.svg';
import './TxStatusAndTypeComponent.css'; 
import createTxIcon from '../../../../Media/Icons/CreateTxIcon.svg';



const TxStatusAndTypeComponent = ({ status, type }) => {
    let statusImagePath;
    let typeImagePath = otherTxIconPath; // Default to a generic token transfer icon path

    // Determine the status image path
    switch (status) {
        case 'Success':
            statusImagePath = txSuccessImagePath;
            break;
        case 'Fail':
            statusImagePath = txFailedImagePath;
            break;
        case 'Pending':
            statusImagePath = txPendingImagePath;
            break;

        default:
            statusImagePath = null;
    }

  // Determine the type image path based on the type of transaction
switch (type) {
    case 'SOL_TRANSFER':
        typeImagePath = solTransferIconPath;
        break;
    case 'TOKEN_TRANSFER':
        typeImagePath = tokenTransferIconPath;
        break;
    case 'SWAP':
        typeImagePath = SwapTxIconPath; // Set to SwapTxIconPath for SWAP transactions
        break;
    case 'TOKEN_BURN': 
        typeImagePath = BurnTxIconPath;
        break;
    case 'CREATE': // Adding case for CREATE transaction type
        typeImagePath = createTxIcon; // Use createTxIcon for CREATE transactions
        break;
    default:
        // Keep the default typeImagePath if the type doesn't match any cases
        break;
}


 return (
         <div className="txStatusTypeContainer">
            <div className="txStatusTypeTitle">Status & TX Type</div>
            <div className="tagGroup"> {/* New div to group tags */}
                <div className="TxstatusTag">
                    {statusImagePath && <img src={statusImagePath} alt="Transaction Status" />}
                </div>
                <div className="TxtypeTag">
                    {typeImagePath && <img src={typeImagePath} alt="Transaction Type" />}
                </div>
            </div>
        </div>
    );
};

export default TxStatusAndTypeComponent;