import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext({
  userId: null,
  userWalletAddress: null,
  setUserId: () => {},
  setUserWalletAddress: () => {}
});

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
    const [userWalletAddress, setUserWalletAddress] = useState(localStorage.getItem('userWalletAddress') || null);

    useEffect(() => {
        // Update localStorage when userId changes
        if (userId !== null) {
            localStorage.setItem('userId', userId);
            fetchUserWalletAddress(userId); // Fetch and set wallet address when userId is set
        } else {
            localStorage.removeItem('userId');
            setUserWalletAddress(null); // Clear wallet address if userId is cleared
        }
    }, [userId]);



const fetchUserWalletAddress = async (userId) => {
    try {
        const apiUrl = `${process.env.REACT_APP_USER_API_URL}/api/fetch-user-by-id`; // Ensure this URL is correct
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId }),
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch user data, status: ${response.status}`);
        }

        const data = await response.json();
        // Make sure to correctly access the wallet_address field in the response
        if (data && data.wallet_address) {
            setUserWalletAddress(data.wallet_address);
            localStorage.setItem('userWalletAddress', data.wallet_address);
        } else {
            console.error("User data or wallet address not found in response:", data);
            // You might want to handle this case, e.g., by setting the wallet address to null or showing an error
        }
    } catch (error) {
        console.error('Error fetching user wallet address:', error);
    }
};





    useEffect(() => {
        // Sync userWalletAddress to localStorage whenever it changes
        if (userWalletAddress !== null) {
            localStorage.setItem('userWalletAddress', userWalletAddress);
        } else {
            localStorage.removeItem('userWalletAddress');
        }
    }, [userWalletAddress]);

    return (
        <UserContext.Provider value={{ userId, userWalletAddress, setUserId, setUserWalletAddress }}>
            {children}
        </UserContext.Provider>
    );
};
