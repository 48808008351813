// SwapContext.js


import React, { createContext, useContext, useState } from 'react';

const SwapContext = createContext();

export const SwapProvider = ({ children }) => {
    const [swapQuotes, setSwapQuotes] = useState([]);
    const [swapStatuses, setSwapStatuses] = useState({});
    

    const updateSwapQuotes = (quoteDetails) => {
        setSwapQuotes(prevQuotes => [...prevQuotes, quoteDetails].flat());
    };


    const setSwapStatus = (quoteId, status, txHash = null) => {
    setSwapStatuses(prevStatuses => {
        //console.log(`Attempting to update status for quoteId ${quoteId} to '${status}'.`);

        // Extend the condition to also skip updates from 'error' to 'failed', 'rejected', or 'success'
        if ((status === 'error' && (['rejected', 'success', 'failed'].includes(prevStatuses[quoteId]?.status))) ||
            (status === 'failed' && prevStatuses[quoteId]?.status === 'success')) {
            //console.log(`Skipping update to '${status}' for quoteId ${quoteId} as it is currently marked as '${prevStatuses[quoteId]?.status}'.`);
            return prevStatuses;
        }

        if (prevStatuses[quoteId] && prevStatuses[quoteId].status === status && (!txHash || prevStatuses[quoteId].txHash === txHash)) {
            //console.log(`No update needed for quoteId ${quoteId}: Status ('${status}') and transaction hash ('${txHash || 'N/A'}') are already up to date.`);
            return {...prevStatuses};
        }

        if (prevStatuses[quoteId]) {
            //console.log(`Previous status for quoteId ${quoteId} was '${prevStatuses[quoteId]?.status}'. Updating to '${status}'.`);
        } else {
            //console.log(`Setting initial status for quoteId ${quoteId} to '${status}'.`);
        }

        return {
            ...prevStatuses,
            [quoteId]: { ...prevStatuses[quoteId], status, txHash }
        };
    });
};




    return (
        <SwapContext.Provider value={{ swapQuotes, updateSwapQuotes, swapStatuses, setSwapStatus }}>
            {children}
        </SwapContext.Provider>
    );
};

export const useSwap = () => useContext(SwapContext);
