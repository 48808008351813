import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import './SidePanel.css';
import WhiteEmblem from '../../Media/Logos/EmblemBeta.svg'; // Adjust the path as needed
import { ReactComponent as DiscordLogo } from '../../Media/Icons/DiscordLogo.svg';
import { ReactComponent as TwitterLogo } from '../../Media/Icons/XIconLogo.svg';
import { ReactComponent as ReferUser } from '../../Media/Icons/ReferUserIcon.svg';
import { ReactComponent as PortfolioIcon } from '../../Media/Icons/PortfolioIcon.svg'; 
import WhiteNotificationBell from '../../Media/Icons/WhiteNotificationBell.svg'; 
import Badge from '../../ReusableUtility/Notifications/Badge/NotificationBadge';
import { useNotification } from '../../Auth/AppContexts/NotificationContext';
import NotificationModal from '../ExplorerFeature/NotificationModal/MainNotificationModal';
import { ReactComponent as MenuItemExplore } from '../../Media/Icons/MenuItemExplore.svg';
import { ReactComponent as MenuItemAISierra } from '../../Media/Icons/SparklesDarkMode.svg'; 
import { ReactComponent as DocsIcon } from '../../Media/Icons/DocsIcon.svg'; // Adjust the path as needed




const SidePanel = () => {
     const { isModalOpen, toggleModal, notifications } = useNotification();
      const location = useLocation();

    const notificationCount = 5;

    // Function to determine if the menu item is active based on the current path
    const isActive = (basepath) => location.pathname.startsWith(basepath);




    return (
        <div className="side-panel">
            <img src={WhiteEmblem} alt="Logo" className="side-panel-logo" />



                <div className={`menu-item-swap-container ${isActive('/dashboard/sierra-ai') ? 'menu-item-active' : ''}`}>
                <Link to="/dashboard/sierra-ai">
                    <MenuItemAISierra className="swap-icon" />
                </Link>
            </div>



            {/* Explore Item Container */}
            <div className={`menu-item-explore-container ${isActive('/dashboard/explorer') ? 'menu-item-active' : ''}`}>
                <Link to="/dashboard/explorer">
                    <MenuItemExplore className="explore-icon" />
                </Link>
            </div>

         

            {/* Portfolio Item Container */}
            <div className={`menu-item-portfolio-container ${isActive('/dashboard/portfolio') ? 'menu-item-active' : ''}`}>
                <Link to="/dashboard/portfolio">
                    <PortfolioIcon className="portfolio-icon" />
                </Link>
            </div>





               {/* Spacer element */}
            <div className="SidePanelSpacer"></div>


           <div className="notification-bell-link-container" onClick={toggleModal}>
            <img src={WhiteNotificationBell} className="notification-bell-logo" alt="Notifications" />
            {/* Display a badge with the count of notifications if there are any */}
            {notificationCount > 0 && <Badge count={notificationCount} />}
        </div>

        {/* Conditionally render the NotificationModal component based on the isModalOpen state */}
        {isModalOpen && <NotificationModal />}
    


           <div className={`refer-users-link-container ${isActive('/dashboard/refer') ? 'menu-item-active' : ''}`}>
                <Link to="/dashboard/refer">
                    <ReferUser className="refer-users-logo" />
                </Link>
            </div>


            {/* Docs link container */}
            <div className="docs-link-container">
                <a href="https://blackfin-explorer.gitbook.io/welcome-to-blackfin/" target="_blank" rel="noopener noreferrer">
                    <DocsIcon className="docs-logo" />
                </a>
            </div>





            {/* Twitter link container */}
            <div className="twitter-link-container">
                <a href="https://twitter.com/blackfinhq" target="_blank" rel="noopener noreferrer">
                    <TwitterLogo className="twitter-logo" />
                </a>
            </div>



            {/* Discord link container */}
            <div className="discord-link-container">
                <a href="https://discord.gg/jwFZqZt9Dp" target="_blank" rel="noopener noreferrer">
                    <DiscordLogo className="discord-logo" />
                </a>
            </div>


            
            {/* Existing container for the green dot */}
            <div className="green-dot-container">
                <div className="green-dot"></div>
            </div>
        </div>




    );
};

export default SidePanel;
