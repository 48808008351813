// Badge.js
import React from 'react';
import './NotificationBadge.css'; // Import CSS for styling the badge

const Badge = ({ count }) => {
  return (
    <div className="badge-container">
      {count}
    </div>
  );
};

export default Badge;
