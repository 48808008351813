// Button.js

import React from 'react';
import { MoonLoader } from 'react-spinners';
import './Button.css';


const Button = ({ text, color, className, type, isLoading, onClick, ...props }) => {
    const colorClass = color ? `button-${color}` : '';
    return (
        <button 
            type={type} 
            className={`button ${colorClass} ${className}`} 
            disabled={isLoading} 
            onClick={onClick}
            {...props}
        >
            {isLoading ? (
                // Use HashLoader when isLoading is true
                <MoonLoader color={'#fff'} loading={isLoading} size={13} />
            ) : (
                <span className="text">{text}</span>
            )}
        </button>
    );
}

export default Button;
