import React, { useState, useEffect } from 'react';
import './WalletTxDetailsComponent.css'; // New unique style file
import { ReactComponent as TxDirectionIcon } from '../../../../Media/Icons/TxDirectionIcon.svg';
import { ReactComponent as TxDirectionIconDarkMode } from '../../../../Media/Icons/TxDirectionIconDark.svg';
import TxDirectionIconDarkModePath from '../../../../Media/Icons/TxDirectionIconDark.svg';

import ProtocolIcon from '../../../../Media/Icons/TxDirectionIcon.svg';
import solTransferIcon from '../../../../Media/Icons/SolTransferIconNew.svg';
import tokenTransferIcon from '../../../../Media/Icons/TokenTransferIcon.svg';
import BurnTxIcon from '../../../../Media/Icons/BurnTxIconTag.svg';
import SoldTokenIcon from '../../../../Media/Icons/SoldTokenIcon.svg';
import BoughtTokenIcon from '../../../../Media/Icons/BoughtTokenIcon.svg';
import createTxIcon from '../../../../Media/Icons/CreateTxIcon.svg';
import { WalletAddressLink as ExternalWalletAddressLink } from '../../../../ReusableUtility/WalletAndTxLink/WalletAndTxLink';
import { TxHashLink, AccountNameLink } from '../../../../ReusableUtility/WalletAndTxLink/WalletAndTxLink';
import TimeAgo from '../../../../ReusableUtility/RefreshFetchTime'; 
import { formatBurnAmount, formatNumber } from '../../../../ReusableUtility/FormatNumberWithCommas';
import LoadingComponent from '../../../../ReusableUtility/LoadingComponent'; 
import txFailedImage from '../../../../Media/Icons/TxFailedTag.svg';
import TokenLink from '../../../../ReusableUtility/TokenLink/TokenLink'; // Adjust the import path 
import { useTheme } from '../../../../Auth/AppContexts/ThemeContext'; 
import { protocolMapping } from '../../../../ReusableUtility/ProtocolMapping';



const WalletTxDetailsComponent = ({ data, isLoading, transactions, walletTokenMetadata, tokenMetaInfo, walletAddress  }) => {
const { theme } = useTheme(); 

 
 
  const isDataAvailable = data && Array.isArray(data);
  
// URL for the default token image
const defaultTokenImage = "https://cnemhnnwbhehhupxqoin.supabase.co/storage/v1/object/public/Icons/CircleDashed-r.svg?t=2024-01-19T20%3A11%3A42.277Z";


const formatAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-6)}`;
  };

// Define the default Solana logo URI
const DEFAULT_SOLANA_LOGO_URI = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png";



const TransactionTypeIcons = {
  'SOLD': SoldTokenIcon,
  'BOUGHT': BoughtTokenIcon,
  // Add other transaction types and their corresponding icons here in the future
};

  
// Use the imported ExternalWalletAddressLink component
const WalletAddressLink = ({ address, showIcon }) => {
  if (!address) {
    return <span className="wallet-link">Address not available</span>;
  }

  return (
    <div className="wallet-link-container">
      {showIcon && (
                theme === 'dark'
                ? <TxDirectionIconDarkMode className="icon-inline" />
                : <TxDirectionIcon className="icon-inline" />
            )}
      <ExternalWalletAddressLink address={address} className="wallet-link">
        {formatAddress(address)}
      </ExternalWalletAddressLink>
    </div>
  );
};



 // Function to render a row for SOL transfers

  const renderSOLTransferRow = (tx, key) => {


    // Find the token metadata for the current transaction
    const tokenMetadata = walletTokenMetadata.find(metadata => metadata.address === tx.tokenAddress);
    const txnHash = tx.signatures?.[0];
    const timestamp = tx.timestamp;
    const sender = tx.actions?.find(action => action.type === 'SOL_TRANSFER')?.info?.sender;
    const receiver = tx.actions?.find(action => action.type === 'SOL_TRANSFER')?.info?.receiver;
    const amount = tx.actions?.find(action => action.type === 'SOL_TRANSFER')?.info?.amount;
    
    //SOL TRANSFER JSX

     // Determine if the transaction is incoming
     const isIncoming = receiver === walletAddress;

    

    return (
      <div className="wallet-content-row">
      <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
      <span><img src={solTransferIcon} alt="SOL Transfer" /></span>
      <span><TimeAgo timestamp={timestamp} /></span>
      <WalletAddressLink address={sender} showIcon={false} />
      <WalletAddressLink address={receiver} showIcon={true} />
      {tx.type === "SOL_TRANSFER" && (
       <div className={`amount-with-logo ${isIncoming ? 'highlight-incoming-amount' : ''}`}>
        <img src={DEFAULT_SOLANA_LOGO_URI} alt="SOL" className="tx-panel-token-logo" />
        <span>{amount !== undefined ? `${amount} ` : '0 '}<TokenLink symbol="SOL" isSolTransfer={true} /></span>
    </div>
      )}
    </div>
    );
  };

  




//TOKEN TRANSFER LOGIC

const renderTokenTransferRow = (tx, key) => {
  const txnHash = tx.signatures?.[0];
  const timestamp = tx.timestamp;
  const sender = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.sender;
  const receiver = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.receiver;
  const amount = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.amount;
  const tokenAddress = tx.actions?.find(action => action.type === 'TOKEN_TRANSFER')?.info?.token_address;
  const tokenMetadata = walletTokenMetadata.find(metadata => metadata.address === tokenAddress);
  const tokenSymbol = tokenMetadata?.info?.symbol || 'TOKEN';
  const tokenImage = tokenMetadata?.info?.image;

  // Check if the transaction is a failed one
  const isFailedTransaction = tx.status !== "Success";
  
  
  // Determine if the transaction is incoming and should be highlighted
  const isIncoming = receiver === walletAddress;



  return (
    <div className="wallet-content-row">
      <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
      {isFailedTransaction ? (
        <span><img src={txFailedImage} alt="Transaction Failed" className="tx-type-icon" /></span>
      ) : (
        <span><img src={tokenTransferIcon} alt="Token Transfer" className="tx-type-icon" /></span>
      )}
      <span><TimeAgo timestamp={timestamp} /></span>
      <WalletAddressLink address={sender} showIcon={false} />
      <WalletAddressLink address={receiver} showIcon={true} />
      {/* Apply highlight-incoming-amount class conditionally */}
      <span className={`wallet-amount-column ${isIncoming ? 'highlight-incoming-amount' : ''}`}>
        <img src={tokenImage || defaultTokenImage} alt={tokenSymbol} className="tx-panel-token-logo" />
         {`${formatNumber(amount, 2)}`}&nbsp; <TokenLink tokenAddress={tokenAddress} symbol={tokenSymbol} />
      </span>
    </div>
  );
};





// TOKEN BURN JSX
// Updated renderTokenBurnRow function
const renderTokenBurnRow = (tx, key, tokenMetaInfo) => {
  const txnHash = tx.signatures?.[0];
  const timestamp = tx.timestamp;
  const sender = tx.signers[0];
  const amount = tx.actions?.find(action => action.type === 'TOKEN_BURN')?.info?.amount;
  const tokenAddress = tx.actions?.find(action => action.type === 'TOKEN_BURN')?.info?.token_address;

 // Use tokenMetaInfo for the token metadata, with a default image if not present
  const tokenInfo = tokenMetaInfo[tokenAddress] || { symbol: 'token', image: defaultTokenImage };
  const tokenSymbol = tokenInfo.symbol;
  // Assign defaultTokenImage if tokenImage is not available
  const tokenImage = tokenInfo.image || defaultTokenImage;


  // Format the burn amount
  const formattedAmount = amount !== undefined ? formatBurnAmount(amount) : '0';

  
  return (
     <div className="wallet-content-row">
    <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
    <span><img src={BurnTxIcon} alt="Burn Transaction" className="tx-type-icon" /></span>
    <span><TimeAgo timestamp={timestamp} /></span>
    <WalletAddressLink address={sender} showIcon={false} />
    {/* Apply the .burn-address class to this span */}
    <span className="burn-address">Burned</span>
    <span className="wallet-amount-column">
      {tokenImage && <img src={tokenImage} alt={tokenSymbol} className="tx-panel-token-logo" />}
        {`${formattedAmount} `}&nbsp;  <TokenLink tokenAddress={tokenAddress} symbol={tokenSymbol} />
    </span>
  </div>
  );
};




const renderSwapTransactionRow = (tx, key, walletAddress) => {

 
  
//console.log("Transaction data:", tx);
  const status = tx?.status;
  const isFailedTransaction = status !== "Success"; 


  const txnHash = tx.signatures?.[0];
  const timestamp = tx.timestamp;
  const feePayer = tx.fee_payer;
  const protocolAddress = tx.protocol?.address;
  const protocolName = protocolMapping[protocolAddress] || "Unknown Protocol";
  
  // Destructure with default values to avoid undefined
  const { in: inToken = {}, out: outToken = {} } = tx.actions?.[0]?.info?.tokens_swapped || {};

  

  // Check if the fee payer is the wallet that initiated the swap
  if (feePayer === walletAddress) {
    return (
      <>
        



        {/* SWAPPED OUT - WHAT USER SOLD*/}
          {
            <div className="wallet-content-row" key={`${txnHash}-out`}>
        <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
        
        {/* Conditionally render 'Sold' or 'Failed' tag */}
        {isFailedTransaction ? (
          <span><img src={txFailedImage} alt="Transaction Failed" /></span>
        ) : (
          <span><img src={TransactionTypeIcons['SOLD']} alt="Sold" /></span>
        )}

        <span><TimeAgo timestamp={timestamp} /></span>
        <span><WalletAddressLink address={walletAddress} showIcon={false} /></span>
        <span className="wallet-link-container">


            <img 
                src={theme === 'dark' ? TxDirectionIconDarkModePath : ProtocolIcon} 
                alt="Direction Icon"
                className="icon-inline"
            />



          <AccountNameLink address={protocolAddress}
          protocolName={protocolName} protocolMapping={protocolMapping} />
        </span>
        <span className="wallet-amount-column">
              <img src={inToken.image_uri || defaultTokenImage} alt={inToken.symbol} className="tx-panel-token-logo" />
          {formatNumber(inToken.amount, 2)}&nbsp; <TokenLink tokenAddress={inToken.token_address} symbol={inToken.symbol} />
        </span>
      </div>

      
      }





          {/* SWAPPED IN - WHAT USER BOUGHT */}
        {status === "Success" && (
          <div className="wallet-content-row" key={`${txnHash}-in`}>
            <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
            <span><img src={TransactionTypeIcons['BOUGHT']} alt="Bought" /></span>
            <span><TimeAgo timestamp={timestamp} /></span>
            <AccountNameLink address={protocolAddress}  protocolName={protocolName} protocolMapping={protocolMapping} />
            <span><WalletAddressLink address={walletAddress} showIcon={true} /></span>
            <span className="wallet-amount-column highlight-incoming-amount">
                <img src={outToken.image_uri || defaultTokenImage} alt={outToken.symbol} className="tx-panel-token-logo" />
              {formatNumber(outToken.amount, 2)}&nbsp; <TokenLink tokenAddress={outToken.token_address} symbol={outToken.symbol} />
            </span>
          </div>

          
        )}
    

      </>
    );
  }
  return null; // Return null if the transaction should not be displayed
};




const renderCreateTxRow = (tx, key) => {
  const txnHash = tx.signatures?.[0];
  const timestamp = tx.timestamp;
  const protocolAddress = tx.protocol?.address; // Extract protocol address from transaction
  const protocolName = protocolMapping[protocolAddress] || "Unknown Protocol"; // Lookup protocol name using mapping

  const sender = tx.actions?.find(action => action.type === 'CREATE')?.info?.sender;
  const recipient = tx.actions?.find(action => action.type === 'CREATE')?.info?.recipient;

  const isFailedTransaction = tx.status !== "Success";

  return (
    <div className="wallet-content-row">
      <span>{txnHash ? <TxHashLink signatures={txnHash} /> : 'N/A'}</span>
      {isFailedTransaction ? (
        <span><img src={txFailedImage} alt="Transaction Failed" className="tx-type-icon" /></span>
      ) : (
        <span><img src={createTxIcon} alt="Create Transaction" className="tx-type-icon" /></span>
      )}
      <span><TimeAgo timestamp={timestamp} /></span>
      {/* Display protocol name as a link */}
     
     
      <span>
          <WalletAddressLink address={sender} showIcon={false} />
      </span>

      <AccountNameLink address={protocolAddress} protocolName={protocolName} />
    
     
    </div>
  );
};




  
  // Main component return
  return (
       <div className="wallet-transaction-details">
      <div className="wallet-header-row">
        <span className="wallet-header-transaction">TRANSACTION #</span>
        <span>TX TYPE</span>
        <span>TIME</span>
        <span>FROM</span>
        <span>TO</span>
        <span className="wallet-header-amount">AMOUNT</span>
      </div>

      {/* Conditional rendering based on loading state */}
      {isLoading ? (
        // Show the loading component if data is still being fetched
        <div className="wallet-tx-loading-container">
          <LoadingComponent />
        </div>
      ) : transactions.length > 0 ? (
        // Render the list of transactions if data is not loading and transactions are available
        transactions.map((tx, index) => {
          const key = tx.signatures[0] || `transaction-${index}`;
          switch (tx.type) {
            case "SOL_TRANSFER":
              return renderSOLTransferRow(tx, key);
            case "TOKEN_TRANSFER":
              return renderTokenTransferRow(tx, key);
            case "TOKEN_BURN":
              return renderTokenBurnRow(tx, key, tokenMetaInfo);
            case "SWAP":
          return renderSwapTransactionRow(tx, key,walletAddress);
           case "CREATE":
      // Add this case to handle "CREATE" transactions
      return renderCreateTxRow(tx, key);
          
             default:
                        // Skip rendering for unsupported transaction types
                        return null;
          }
        })
      ) : (
        // Display a message if there are no transactions
        <div className="wallet-content-row">
          <span className="wallet-no-data">No transaction data available.</span>
        </div>
      )}
    </div>
  );
};

export default WalletTxDetailsComponent;