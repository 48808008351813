import React from 'react';
import Button from '../../Buttons/Button'; 
import './TokenDataButtonNavigation.css';

const TokenDataNavigation = ({ onNavigate, activePanel  }) => {
  // Define a handler for button click events that invokes a callback with the target component's name
  const handleNavigationClick = (targetComponent) => {
    onNavigate(targetComponent);
  };

  return (
    <div className="token-data-navigation">
      <Button
        onClick={() => handleNavigationClick('transfers')}
        text="Transactions"
        className={activePanel === 'transfers' ? 'active' : ''}
      />
      <Button
        onClick={() => handleNavigationClick('holders')}
        text="Holders"
        className={activePanel === 'holders' ? 'active' : ''}
      />
    </div>
  );
};

export default TokenDataNavigation;
