import React, { useState, useEffect, useRef, useContext } from 'react';
import './NotificationSettingsModal.css'; 
import Button from '../../../../Buttons/Button';
import { UserContext } from '../../../../../Auth/AppContexts/UserContext'; 
import { useParams } from 'react-router-dom';

const NotificationSettingsModal = ({ onClose, onSave, preference, onChangePreference, updateIsActiveNotification }) => {
    const [selectedOption, setSelectedOption] = useState('none');
    const modalRef = useRef(null);
    const { userId } = useContext(UserContext);
    const { walletAddress } = useParams(); // Assuming walletAddress is part of the URL

    
    const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    onChangePreference(event.target.value); 
  };

  useEffect(() => {
    setSelectedOption(preference); // Ensure the local state updates when the prop changes
  }, [preference]);




 




    useEffect(() => {
        const fetchCurrentPreference = async () => {
            if (userId && walletAddress) {
                try {
                    const apiUrl = `${process.env.REACT_APP_USER_API_URL}/api/get-notification-preference`;
                    const response = await fetch(apiUrl, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ userId, walletAddress }),
                    });
                    const data = await response.json();
                    if (response.ok && data.notificationType) {
                        setSelectedOption(data.notificationType);
                    } else {
                        setSelectedOption('none');
                    }
                } catch (error) {
                    console.error('Error fetching current preference:', error);
                    setSelectedOption('none');
                }
            }
        };

        fetchCurrentPreference();
    }, [userId, walletAddress]);

      


   const handleSaveClick = async () => {
        if (userId && walletAddress) {
            // Assuming onSave is an async function
            await onSave(selectedOption, walletAddress);
            // Determine if the current selection indicates an active notification
            const isActive = selectedOption !== 'none';
            // Update parent component's active notification state
            updateIsActiveNotification(isActive);
            onClose();
        }
    };



    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);




    

  return (
      <div className="notification-settings-modal" ref={modalRef}>
      {userId ? (
        <div className="notification-modal-content" onClick={e => e.stopPropagation()}>
          <div className="notification-modal-header">
            <h2>Set up Wallet Notifications</h2>
          </div>
          <div className="notification-options">
            <label className="notification-option">
              <input 
                type="radio" 
                value="none" 
                checked={selectedOption === 'none'}
                onChange={handleOptionChange} 
              /> None
            </label>
            <label className="notification-option">
              <input 
                type="radio" 
                value="SWAP" 
                checked={selectedOption === 'SWAP'}
                onChange={handleOptionChange} 
              /> Swaps (Buys & Sells)
            </label>
            <label className="notification-option">
              <input 
                type="radio" 
                value="TOKEN_TRANSFER" 
                checked={selectedOption === 'TOKEN_TRANSFER'}
                onChange={handleOptionChange} 
              /> Token Transfers
            </label>
            <label className="notification-option">
              <input 
                type="radio" 
                value="SOL_TRANSFER" 
                checked={selectedOption === 'SOL_TRANSFER'}
                onChange={handleOptionChange} 
              /> SOL Transfers
            </label>
          </div>
          <Button text="Save" onClick={handleSaveClick} />
        </div>
      ) : (
        <div className="notification-modal-overlay" onClick={e => e.stopPropagation()}>
          <p>Please <a href={`${window.location.origin}/onboarding`} >sign up</a> or connect wallet to create wallet notifications.</p>
        </div>
      )}
    </div>
  );
};

export default NotificationSettingsModal;
