// IntroTextContainer.js

import React from 'react';


const IntroTextContainer = () => {
    return (
        <div className="intro-text-container">
            <p>Welcome to Blackfin. Let's set up your profile.</p>
            <p>Create a username and confirm your wallet address is correct.</p>
            <p>Once you're all set, you can start using our Explorer, other products and start earning points and referring friends.</p>
            <p>When you're ready, click Go.</p>
        </div>
    );
};

export default IntroTextContainer;
