import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Axios is used for making HTTP requests
import { useWallet } from '@solana/wallet-adapter-react';
import { useSolanaConnection } from '../../../Auth/AppContexts/SolanaConnectionContext'; 
import { Connection, Transaction, VersionedTransaction } from '@solana/web3.js';

import './CreateTokenForm.css';

const CreateTokenForm = ({ walletAddress }) => {
    const [formData, setFormData] = useState({
        network: 'mainnet-beta',
         wallet: walletAddress, 
        name: '',
        symbol: '',
        description: '',
        decimals: '',
        feePayer: '',
    });
    const [file, setFile] = useState(null);
    const wallet = useWallet(); // Use the wallet
     const connection = useSolanaConnection();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const base64ToUint8Array = (base64) => {
    const raw = atob(base64);
    const uint8Array = new Uint8Array(new ArrayBuffer(raw.length));
    for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
};



const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach(key => data.append(key, formData[key]));
    if (file) {
        data.append('file', file);
    }

    console.log('Submitting form data to backend...'); // Log before sending data

    try {
        const response = await axios.post('http://localhost:3002/create-spl-token', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log('Backend response received:', response.data); // Log the response from the backend

        if (response.data.success && response.data.result.encoded_transaction) {
            const { encoded_transaction } = response.data.result;
            console.log('Encoded transaction:', encoded_transaction); // Log the encoded transaction

            // Convert the base64 encoded string to a Uint8Array
            const uint8ArrayTransaction = base64ToUint8Array(encoded_transaction);
            // Deserialize the transaction from the Uint8Array
            const transaction = VersionedTransaction.deserialize(uint8ArrayTransaction);

            console.log('Deserialized transaction:', transaction); // Log the deserialized transaction

            if (!wallet.signTransaction) {
                console.error('Wallet does not support signing');
                return;
            }

            console.log('Signing transaction...'); // Log before signing
            const signedTransaction = await wallet.signTransaction(transaction);
            console.log('Transaction signed:', signedTransaction); // Log after signing

            console.log('Sending signed transaction to the blockchain...'); // Log before sending
            const signature = await connection.sendRawTransaction(signedTransaction.serialize());
            console.log('Signed transaction sent, signature:', signature); // Log the signature

            

            console.log('Awaiting transaction confirmation...'); // Log before confirmation
            await connection.confirmTransaction(signature, 'confirmed');
            console.log('Transaction confirmed with signature:', signature); // Log confirmation
        } else {
            console.error('Failed to create token:', response.data.error);
        }
    } catch (error) {
        console.error('Error creating token:', error.response ? error.response.data : error);
    }
};



     useEffect(() => {
        // If the wallet address changes (e.g., user connects/disconnects wallet),
        // update the wallet field in the form data.
        setFormData(prevFormData => ({
            ...prevFormData,
            wallet: walletAddress || '' // If there's no walletAddress, set to an empty string
        }));
    }, [walletAddress]);













    return (
        <div className="form-container">
           

            <label htmlFor="walletAddress" className="input-label">Wallet Address</label>
            <input id="walletAddress" 
            name="wallet" type="text" 
            value={formData.wallet} 
            onChange={handleInputChange}
             readOnly={!!walletAddress}  
            className="input-field" 
            
            />

            

            <label htmlFor="tokenName" className="input-label">Token Name</label>
            <input id="tokenName" name="name" type="text" value={formData.name} onChange={handleInputChange} className="input-field" />

            <label htmlFor="tokenSymbol" className="input-label">Token Symbol</label>
            <input id="tokenSymbol" name="symbol" type="text" value={formData.symbol} onChange={handleInputChange} className="input-field" />

            <label htmlFor="tokenDecimals" className="input-label">Number of Decimals</label>
            <input id="tokenDecimals" name="decimals" type="number" value={formData.decimals} onChange={handleInputChange} className="input-field" />

            <label htmlFor="tokenDescription" className="input-label">Description</label>
            <textarea id="tokenDescription" name="description" value={formData.description} onChange={handleInputChange} className="input-field"></textarea>

            <label htmlFor="tokenLogo" className="input-label">Token Logo</label>
            <input id="tokenLogo" name="file" type="file" onChange={handleFileChange} className="input-field" />

        

            <button type="submit" className="submit-button" onClick={handleSubmit}>Create Token</button>
        </div>
    );
};

export default CreateTokenForm;
