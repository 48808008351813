import React from 'react';
import './SierraTokenVolume.css'; // Make sure to import your CSS file
import { formatNumber } from '../../../../ReusableUtility/FormatNumberWithCommas'; // Assuming formatNumber is saved in a utils.js file

const TokenVolume = ({ volumeData, marketCap }) => {
    return (
         <div className="token-volume-container">
            <p><strong>24hr Vol:</strong> ${formatNumber(volumeData) || 'Not available'}</p>
            <div className="token-volume-divider"></div> {/* This is the divider */}
            <p><strong>MC:</strong> ${formatNumber(marketCap) || 'Not available'}</p>
        </div>
    );
};


export default TokenVolume;