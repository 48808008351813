import React from 'react';
import './TxSummaryAssetDisplay.css';
import { ReactComponent as SolLogo } from '../../../../Media/Icons/solana-plain.svg';

import { WalletAddressLink } from '../../../../ReusableUtility/WalletAndTxLink/WalletAndTxLink';
import TokenIconEmblem from '../../../../Media/Icons/DefualtTokenIcon.svg'
import { formatNumber, formatBurnAmount } from '../../../../ReusableUtility/FormatNumberWithCommas';
import BurnIconPath from '../../../../Media/Icons/BurnIcon.svg';
import TokenLink from '../../../../ReusableUtility/TokenLink/TokenLink';




const TxSummaryAssetDisplay = ({ amount, sender, receiver, symbol, image, type, swapDetails, status, tokenAddress }) => {

     if (status === 'Fail') {
        return null; // Or return some JSX to indicate the transaction failed
    }

    const isSolTransfer = type === 'SOL_TRANSFER';
    const isSwap = type === 'SWAP';
    const isBurn = type === 'TOKEN_BURN';
    const assetSymbol = isSolTransfer ? 'SOL' : symbol || 'Unknown Token';



const formatAmount = (amount, symbol) => {
  // Check if amount is undefined or null
  if (amount == null) {
    return 'N/A'; // or any other placeholder you prefer
  }

  if (symbol === 'SOL') {
    return formatNumber(amount); // For SOL, show the full amount
  }
  return formatNumber(amount, 2); // For other tokens, show two decimal places
};


   


    
    return (
        <div className="txSummaryAssetDisplay">
            <div className="assetDisplayContainer">




                {isSwap ? (

                      //SWAP RELATED LOGIC
                    <>
                        <div className="asset">
                            {swapDetails.inImage ? (
                                <img src={swapDetails.inImage} alt={swapDetails.inSymbol} className="AssetSummaryTokenLogo" />
                            ) : (
                                <img src={TokenIconEmblem} alt="Token Emblem" className="AssetSummaryTokenLogo" />
                            )}
                             <span className="assetAmount">{formatAmount(swapDetails.inAmount, swapDetails.inSymbol)}</span>
                           
                            <span className="assetSymbol">
                                <TokenLink 
                                    tokenAddress={swapDetails.inTokenAddress}
                                    symbol={swapDetails.inSymbol} 
                                />
                            </span>

                        </div>
                        <div className="transactionArrow">→</div>
                        <div className="asset">
                            {swapDetails.outImage ? (
                                <img src={swapDetails.outImage} alt={swapDetails.outSymbol} className="AssetSummaryTokenLogo" />
                            ) : (
                                <img src={TokenIconEmblem} alt="Token Emblem" className="AssetSummaryTokenLogo" />
                            )}
                             <span className="assetAmount">{formatAmount(swapDetails.outAmount, swapDetails.outSymbol)}</span>

                            <span className="assetSymbol">
                                <TokenLink 
                                    tokenAddress={swapDetails.outTokenAddress}
                                    symbol={swapDetails.outSymbol}
                                />
                            </span>
                        </div>
                    </>

                     ) : isBurn ? (
                    // BURN TRANSACTION LOGIC
                    <>
                        <div className="asset">
                            <WalletAddressLink address={sender} className="senderAddressLink" />
                            {image && <img src={image} alt={symbol} className="AssetSummaryTokenLogo" />}
                            <span className="assetAmount">{formatBurnAmount(amount, symbol)}</span>

                            <span className="assetSymbol">
                               <TokenLink 
                                tokenAddress={tokenAddress}
                                symbol={symbol}
                            />
                            </span>

                        </div>
                        <div className="transactionArrow">→</div> 
                        <img src={BurnIconPath} alt="Burn Transaction" className="AssetSummaryTokenLogo" />
                    </>

                ) : (

                    //SOL & TOKEN TRANSFERS LOGIC
                    <>
                        <div className="asset">
                            <WalletAddressLink address={sender} className="senderAddressLink" />
                            {isSolTransfer ? (
                                <SolLogo className="SOLassetLogo" />
                            ) : (
                                image ? <img src={image} alt={symbol} className="AssetSummaryTokenLogo" /> : <img src={TokenIconEmblem} alt="Token Emblem" className="AssetSummaryTokenLogo" />
                            )}
                            <span className="assetAmount">{formatAmount(amount, isSolTransfer ? 'SOL' : symbol)}</span>

                             <span className="assetSymbol">
                                <TokenLink 
                                tokenAddress={tokenAddress}
                                symbol={isSolTransfer ? 'SOL' : symbol}
                                isSolTransfer={isSolTransfer}
                            />
                            </span>

                        </div>
                        <div className="transactionArrow">→</div>
                        <div className="asset">
                            <WalletAddressLink address={receiver} className="receiverAddressLink" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TxSummaryAssetDisplay;