import React, { useEffect, useRef } from 'react';
import './MainNotificationModal.css'; 
import { useNotification } from '../../../Auth/AppContexts/NotificationContext'; 
import NotificationItem from '../NotificationModal/NotificationItem/NotificationItem'; 

const NotificationModal = () => {
    const { notifications, setNotifications, toggleModal } = useNotification();
    const hasNotifications = notifications && notifications.length > 0;
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                toggleModal(); // Close the modal if click is outside of modal
            }
        };

        // Add when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Return function to be called when it unmounts
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [toggleModal]);


    // Prevent modal close on content click
    const handleModalContentClick = (e) => e.stopPropagation();

     // Function to clear all notifications
    const clearAllNotifications = () => {
        setNotifications([]); // Assuming your state updater function is named setNotifications
        // Add any additional logic or API calls to clear notifications from backend
    };







    return (
         <div className="main-notification-modal-overlay" onClick={toggleModal}>
            <div className="main-notification-modal-content" ref={modalRef} onClick={handleModalContentClick}>
                <div className="notification-modal-header">
                    <h2>Notifications</h2>
                    {hasNotifications && (
                        <button className="notification-clear-all" onClick={clearAllNotifications}>
                            Clear all
                        </button>
                    )}
                </div>
                <div className="notification-list">
                    <div className="notification-header-row">
                        <span className="notification-column-header">TYPE</span>
                        <span className="notification-column-header">DESCRIPTION</span>
                        <span className="notification-column-header">TIME</span>
                        <span className="notification-column-header">ACTION</span>
                    </div>
                    {hasNotifications ? (
                        notifications.map((notification, index) => (
                            <NotificationItem
                                key={index}
                                type={notification.type}
                                description={notification.description}
                                timestamp={notification.timestamp}
                                onView={() => {/* Handle view action here */}}
                            />
                        ))
                    ) : (
                        <p className="no-notifications">No new notifications.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationModal;
