import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import WalletAddressDisplay from '../../Components/ExplorerFeature/WalletAddressPageComponents/WalletAddressDisplay/WalletAddressDisplay';
import SidePanel from '../../Components/SidePanel/SidePanel'; 
import HeaderMenu from '../../Components/HeaderMenu/HeaderMenu'; 
import WalletOverview from '../../Components/ExplorerFeature/WalletAddressPageComponents/WalletOverviewComponent/WalletOverviewComponent'; 
import WalletInfoComponent from '../../Components/ExplorerFeature/WalletAddressPageComponents/WalletInfoComponent/WalletInfoComponent'; 
import WalletDataNavigation from '../../Components/ExplorerFeature/WalletAddressPageComponents/WalletNavigationButton/WalletPageDataButtonNaviagtion'; 
import WalletTxDetailsComponent from '../../Components/ExplorerFeature/WalletAddressPageComponents/WalletTxDetailsPanel/WalletTxDetailsComponent'; 
import { fetchSolanaTokenPrice } from '../../APIFunctions/FetchSolanaTokenPrice';
import { fetchTokenDetailsForTransactions } from '../../ReusableUtility/FetchTokenMetadataForTxComponent';
import './WalletDetailsPage.css'
import Button from '../../Components/Buttons/Button'; 
import NotificationSettingsModal from '../../Components/ExplorerFeature/WalletAddressPageComponents/WalletNotifications/NotificationsSettingsModal/NotificationSettingsModal'; // Import the modal component
import { UserContext } from '../../Auth/AppContexts/UserContext';
import FullPageLoader from '../../ReusableUtility/FullPageUILoading';



const UserWalletAddressPage = () => {
   const {  userId, userWalletAddress, updateUserContext } = useContext(UserContext);
  const { walletAddress } = useParams(); 
  const [walletData, setWalletData] = useState({ balance: null });
  const [error, setError] = useState(null);
   const [walletTokenMetadata, setWalletTokenMetadata] = useState([]);
  const [transactionData, setTransactionData] = useState([]); 
  const [activeSection, setActiveSection] = useState('transfers');
  const [isLoading, setIsLoading] = useState(true);
  const [tokenHoldings, setTokenHoldings] = useState([]); 
  const [solPrice, setSolPrice] = useState(null);
   const [totalTokenUsdValue, setTotalTokenUsdValue] = useState(0);
   const [solanaDomains, setSolanaDomains] = useState([]);
   const [transactions, setTransactions] = useState([]);
    const [tokenMetaInfo, setTokenMetaInfo] = useState({});
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [lastTxSignature, setLastTxSignature] = useState(null);
    const [notificationPreference, setNotificationPreference] = useState('none');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isActiveNotification, setIsActiveNotification] = useState(false);
    const location = useLocation();



    //Manages central laoding logic
    const [isGlobalLoading, setGlobalLoading] = useState(true);

    const [loadingStatuses, setLoadingStatuses] = useState({
  
    
  });

  const hasAllComponentsLoaded = () => {
  return Object.values(loadingStatuses).every(status => status === false);
};

  useEffect(() => {
  setGlobalLoading(!hasAllComponentsLoaded());
}, [loadingStatuses]);
  


  



 
  

    // Function to check if the search term is a valid Solana wallet address
    const isValidSolanaAddress = (address) => {
  return /^[1-9A-HJ-NP-Za-km-z]{44}$/.test(address);
};


//Function to fetch Solana domains
  const fetchSolanaDomains = async () => {
    setLoadingStatuses(prevStatuses => ({ ...prevStatuses, solanaDomains: true })); // Start loading Solana domains

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sol-domain-names?walletAddress=${walletAddress}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const domains = await response.json();
      setSolanaDomains(domains.result || []); // Assuming the domains are in the 'result' property
    } catch (error) {
      console.error('Error fetching Solana domains:', error);
    } finally {
      setLoadingStatuses(prevStatuses => ({ ...prevStatuses, solanaDomains: false })); // End loading Solana domains
    }
  };

  // Fetch Solana domains in useEffect when walletAddress changes
  useEffect(() => {
    if (isValidSolanaAddress(walletAddress)) {
      fetchSolanaDomains();
    }
  }, [walletAddress]);










// Function to fetch token metadata mainly fetches token balances for a specific wallet
const fetchWalletTokenMetadata = async () => {
  setLoadingStatuses(prevStatuses => ({ ...prevStatuses, walletTokenMetadata: true })); // Start loading wallet token metadata

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wallet-token-metadata/${walletAddress}`);
    if (!response.ok) {
      const text = await response.text(); // Read the text response for debugging
      console.error('Error response:', text); // Log the text response
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const metadata = await response.json();
    setWalletTokenMetadata(metadata.result);
  } catch (error) {
    console.error('Error fetching token metadata:', error);
  } finally {
    setLoadingStatuses(prevStatuses => ({ ...prevStatuses, walletTokenMetadata: false })); // End loading wallet token metadata
  }
};

  // Separate useEffect for fetching wallet token metadata
   useEffect(() => {
    if (isValidSolanaAddress(walletAddress)) {
      fetchWalletTokenMetadata();
    }
  }, [walletAddress, location]);





useEffect(() => {
  const fetchWalletData = async () => {
    if (!walletAddress || walletAddress.trim() === '' || !isValidSolanaAddress(walletAddress)) {
      //console.log("Invalid or empty walletAddress, skipping API call");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sol-balance?address=${walletAddress}`);
      if (!response.ok) {
        throw new Error(`Error fetching wallet data: ${response.statusText}`);
      }

      const data = await response.json();

      //Using shyft API 
       //setWalletData({ balance: data });

      //Using RPC emthod
      setWalletData({ balance: data.solBalance });
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  // Fetch SOL price
    const fetchSolPrice = async () => {
      const priceData = await fetchSolanaTokenPrice();
      if (priceData && priceData.price) {
        setSolPrice(priceData.price);
      }
    };

    fetchWalletData();
    fetchSolPrice();

  fetchWalletData();
}, [walletAddress, location]);





const fetchTransactionData = async (loadMore = false) => {
  setLoadingMore(loadMore);
  setIsLoading(!loadMore);

  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/api/Fetch-Wallet-TxHistory?walletAddress=${walletAddress}&enable_raw=true&tx_num=100`;

    if (loadMore && lastTxSignature) {
      apiUrl += `&before_tx_signature=${lastTxSignature}`;
      
    }
    


    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`Error fetching transaction data: ${response.statusText}`);
    }

    const fetchedData = await response.json();
    

    if (loadMore) {
      setTransactions(prevTransactions => [...prevTransactions, ...fetchedData.result]);
    } else {
      setTransactions(fetchedData.result);
    }

    if (fetchedData.result.length > 0) {
      const lastTransactionInBatch = fetchedData.result[fetchedData.result.length - 1];
      setLastTxSignature(lastTransactionInBatch.signatures[0]);
      
    }

   // If you're fetching up to 100 items at a time
   setCanLoadMore(fetchedData.result.length >= 100);


    // Fetch token metadata for each transaction
    fetchedData.result.forEach(tx => {
      const tokenAddress = tx.actions?.find(action => action.type === 'TOKEN_BURN')?.info?.token_address;
      if (tokenAddress && !tokenMetaInfo[tokenAddress]) {
        fetchTokenMetadata(tokenAddress);
      }
    });
  } catch (error) {
    console.error("Error in fetchTransactionData:", error);
  } finally {
    setLoadingMore(false);
    setIsLoading(false);
  }
};



useEffect(() => {
  if (walletAddress && isValidSolanaAddress(walletAddress)) {
    fetchTransactionData(); // Fetch initial data
  }
}, [walletAddress, location]);








// Function to fetch specific token information such as symbols, images etc for a  token address
const fetchTokenMetadata = async (tokenAddress) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/token-info?tokenAddress=${encodeURIComponent(tokenAddress)}`;
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const jsonResponse = await response.json();

    // Check if the API call was successful and has the result
    if (jsonResponse.success && jsonResponse.result) {
      setTokenMetaInfo(prevMetaInfo => ({
        ...prevMetaInfo,
        [tokenAddress]: {
          symbol: jsonResponse.result.symbol,
          image: jsonResponse.result.image
        }
      }));
    } else {
      throw new Error(jsonResponse.message || "Unknown error occurred");
    }
  } catch (error) {
    console.error("Error fetching token metadata:", error);
    setTokenMetaInfo(prevMetaInfo => ({
      ...prevMetaInfo,
      [tokenAddress]: { symbol: 'TOKEN', image: null } // Fallback values
    }));
  }
};


useEffect(() => {
  transactions.forEach(tx => {
    const tokenAddress = tx.actions?.find(action => action.type === 'TOKEN_BURN')?.info?.token_address;
    if (tokenAddress && !tokenMetaInfo[tokenAddress]) {
      fetchTokenMetadata(tokenAddress);
    }
  });
}, [transactions, tokenMetaInfo, location]);







    // Handler for section navigation
  const handleNavigate = (section) => {
    setActiveSection(section);
  };

  

   // Callback function to update total token USD value
  const handleTotalTokenValueCalculated = (totalValue) => {
    setTotalTokenUsdValue(totalValue);
  };

  
   // Calculate USD value for SOL
  const numericUsdValue = solPrice && walletData.balance ? parseFloat((walletData.balance * solPrice).toFixed(2)) : 0;

  // Calculate the total wallet value by summing numericUsdValue and totalTokenUsdValue
  const totalWalletUsdValue = numericUsdValue + totalTokenUsdValue;
 
 
  


 // Define a function to handle saving preferences
const handleSavePreference = async () => {
    await saveNotificationPreference(userId, walletAddress, notificationPreference);
    setIsModalOpen(false); // Optionally close the modal after saving
  };

   // Function to update the isActiveNotification state
    const updateIsActiveNotification = (isActive) => {
        setIsActiveNotification(isActive);
    };


  const saveNotificationPreference = async (userId, walletAddress, notificationType) => {
  const isActive = notificationType !== 'none';
  try {
    const apiUrl = `${process.env.REACT_APP_USER_API_URL}/api/user-notifications`;
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        wallet_address: walletAddress,
        notification_type: notificationType,
        is_active: isActive
      })
    });

    if (!response.ok) {
      // If response is not OK, attempt to read text to avoid JSON parse error
      const errorText = await response.text();
      throw new Error(`Failed to save notification preference: ${errorText}`);
    }

    // If response is OK, parse JSON
    const data = await response.json();
    //console.log('Notification preference saved:', data);
  } catch (error) {
    console.error('Error in saveNotificationPreference:', error.message);
  }
};


//fetches notification preference for modal to display active red dot 
 useEffect(() => {
        const fetchNotificationPreference = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_USER_API_URL}/api/get-notification-preference`;
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId, walletAddress }),
                });
                const data = await response.json();
                if (response.ok && data.notificationType !== 'none') {
                    setIsActiveNotification(true);
                } else {
                    setIsActiveNotification(false);
                }
            } catch (error) {
                console.error('Error fetching notification preference:', error);
                setIsActiveNotification(false);
            }
        };

        fetchNotificationPreference();
    }, [userId, walletAddress]);



if (isGlobalLoading) {
  return <FullPageLoader />;
}




  return (
    <div className="wallet-details-container">
      <SidePanel />
      <HeaderMenu />

       <WalletAddressDisplay
           address={walletAddress}
           label="Wallet Address"
               onBellClick={() => setIsModalOpen(true)}
    isActiveNotification={isActiveNotification}
        />
   
      
      <div className="wallet-address-page-overview-and-info-container"> 
         


        <WalletOverview
          balance={walletData.balance}
          usdValue={numericUsdValue.toFixed(2)} 
          tokenHoldings={walletTokenMetadata}
           onTotalTokenValueCalculated={handleTotalTokenValueCalculated} 
        />
        <WalletInfoComponent
           totalWalletValue={totalWalletUsdValue}
           solanaDomains={solanaDomains}
        />

         
      </div>
        <WalletDataNavigation onNavigate={handleNavigate} /> 
        
       <WalletTxDetailsComponent
        transactions={transactions}
        isLoading={isLoading}
        walletAddress={walletAddress}
        walletTokenMetadata={walletTokenMetadata}
        tokenMetaInfo={tokenMetaInfo}
    />

  {isModalOpen && (
      <NotificationSettingsModal
        onClose={() => setIsModalOpen(false)} 
        preference={notificationPreference}
        onChangePreference={(newPreference) => {
          setNotificationPreference(newPreference); 
        }}
        onSave={handleSavePreference} 
        updateIsActiveNotification={updateIsActiveNotification}
        
      />
    )}



  
{canLoadMore && (
    <Button
        text={loadingMore ? 'Loading...' : 'Load More Transactions'}
        color="primary"
        className=""
        isLoading={loadingMore}
        onClick={() => fetchTransactionData(true)}
        type="button"
        style={{ marginBottom: '20px' }} // Add margin bottom here
    />
)}




     
    </div>
  );
};

export default UserWalletAddressPage;