import React, { useState, useEffect } from 'react';
import './HeaderSolanaPrice.css';
import SolanaLogo from '../../../Media/Icons/solana-plain.svg'; // Make sure the path is correct
import { fetchSolanaTokenPrice } from '../../../APIFunctions/FetchSolanaTokenPrice'; // Adjust the path as needed

const HeaderSolanaPrice = () => {
    const [solanaPrice, setSolanaPrice] = useState(null);
    const [initialPrice, setInitialPrice] = useState(null);
    const [priceChangePercent, setPriceChangePercent] = useState(null);


    useEffect(() => {
    const fetchSolanaPrice = async () => {
        try {
            const priceData = await fetchSolanaTokenPrice();
            if (priceData && priceData.price) {
                const formattedPrice = Number(priceData.price).toFixed(2);
                setSolanaPrice(formattedPrice);

                // Check if we need to update the initial price
                const storedInitialPrice = localStorage.getItem('initialSolanaPrice');
                const storedTimestamp = localStorage.getItem('initialPriceTimestamp');
                const now = new Date();
                const isSameDay = storedTimestamp && new Date(parseInt(storedTimestamp)).toDateString() === now.toDateString();
                
                if (!storedInitialPrice || !isSameDay) {
                    // Update initial price and timestamp in localStorage
                    localStorage.setItem('initialSolanaPrice', formattedPrice);
                    localStorage.setItem('initialPriceTimestamp', now.getTime().toString());
                    setInitialPrice(formattedPrice);
                } else {
                    // Use stored initial price to calculate percentage change
                    setInitialPrice(storedInitialPrice);
                    const percentageChange = ((formattedPrice - storedInitialPrice) / storedInitialPrice) * 100;
                    setPriceChangePercent(percentageChange.toFixed(2));
                }
            }
        } catch (error) {
            console.error('Failed to fetch Solana price:', error);
        }
    };

    fetchSolanaPrice();
    const intervalId = setInterval(fetchSolanaPrice, 6000); // Refresh every 1 minute

    return () => clearInterval(intervalId);
}, []);





    const getPriceChangeColor = () => {
        if (priceChangePercent > 0) return 'green';
        if (priceChangePercent < 0) return 'red';
        return 'lightgrey';
    };

    const formatPriceChange = () => {
        if (priceChangePercent === null) return '';
        const sign = priceChangePercent >= 0 ? '+' : ''; // Negative values will automatically have '-' prefix
        return `(${sign}${priceChangePercent}%)`;
    };

    return (
        <div className="header-solana-price-container">
            <img src={SolanaLogo} alt="Solana Logo" className="solana-logo" />
            <span className="solana-price">{solanaPrice ? `$${solanaPrice}` : 'Loading...'}</span>
            <span className="solana-price-change" style={{ color: getPriceChangeColor() }}>
                {formatPriceChange()}
            </span>
        </div>
    );
};

export default HeaderSolanaPrice;
