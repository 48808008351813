//FetchTokenPrice.js


const BASE_URL = "https://price.jup.ag/v4/price";

export const fetchTokenPrice = async (tokenSymbol, tokenId, vsToken = "USDC") => {
    try {
        const url = `${BASE_URL}?ids=${encodeURIComponent(tokenId)}&vsCurrencies=${encodeURIComponent(vsToken)}`;
        const response = await fetch(url);
        const data = await response.json();

        // Return price data for the requested token address (ID), fallback to symbol if needed
        return data.data[tokenId] || data.data[tokenSymbol] || null;
    } catch (error) {
        console.error("Error fetching token price:", error);
        return null;
    }
};
