import React, { createContext, useState, useCallback, useEffect } from 'react';


export const SierraSearchContext = createContext();

export const SierraSearchProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [tokenMetaInfo, setTokenMetaInfo] = useState({}); 
    const [additionalTokenDetails, setAdditionalTokenDetails] = useState({});

    const [selectedTokenVolume, setSelectedTokenVolume] = useState(null);
     const [selectedToken, setSelectedToken] = useState({
        address: "So11111111111111111111111111111111111111112",
        name: "Solana",
        symbol: "SOL"
    });


const fetchAdditionalTokenDetailsForURLShare = useCallback(async (tokenAddress) => {
   
    try {
        //console.log(`Request URL: ${process.env.REACT_APP_BASE_URL}/sierra-token-details?address=${encodeURIComponent(tokenAddress)}`); // Log the full API request URL
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sierra-token-details?address=${encodeURIComponent(tokenAddress)}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch additional token details, status: ${response.status}`);
        }
        const tokenDetails = await response.json();
        //console.log('Fetched token details:', tokenDetails); // Log the fetched token details
        
        // Assuming tokenDetails directly contains the price and volume
        setAdditionalTokenDetails(prevDetails => {
  // Insert the new token details into the previous state
  const updatedDetails = {
    ...prevDetails,
    [tokenAddress]: {
      volume24h: tokenDetails.volume24h,
      priceUsd: tokenDetails.priceUsd,
    },
  };

  // Convert the updatedDetails object to an array, sort it, and convert back to an object
  const sortedByVolume = Object.entries(updatedDetails)
    .sort((a, b) => b[1].volume24h - a[1].volume24h)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  return sortedByVolume;
});

    } catch (error) {
        console.error("Error fetching additional token details:", error);
        // Handle the error state as needed, e.g., setting some default values or showing an error message
    }
}, []);


   









    // Function to fetch specific token metadata for a token address
   const fetchTokenMetadata = useCallback(async (tokenAddress) => {
    try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/token-info?tokenAddress=${encodeURIComponent(tokenAddress)}`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonResponse = await response.json();

        if (jsonResponse.success && jsonResponse.result) {
            const newMetaInfo = {
                 name: jsonResponse.result.name, 
                symbol: jsonResponse.result.symbol,
                image: jsonResponse.result.image,
                currentSupply: jsonResponse.result.current_supply,
            };
            setTokenMetaInfo(prevMetaInfo => ({
                ...prevMetaInfo,
                [tokenAddress]: newMetaInfo,
            }));
            return newMetaInfo; // Return the newly fetched metadata for immediate use
        } else {
            throw new Error(jsonResponse.message || "Unknown error occurred");
        }
    } catch (error) {
        console.error("Error fetching token metadata:", error);
        setTokenMetaInfo(prevMetaInfo => ({
            ...prevMetaInfo,
            [tokenAddress]: { symbol: 'TOKEN', image: null }, // Fallback values
        }));
        return null; // Indicate failure to fetch metadata
    }
}, []);




   



    const fetchTokens = useCallback(async (query) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sierra-search-tokens?q=${query}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch data, status: ${response.status}`);
        }
        const tokens = await response.json();
        
        setSearchResults(tokens); // Set fetched tokens as search results
        
        // Fetch token metadata for each token in the search results
        tokens.forEach(token => fetchTokenMetadata(token.address));
    } catch (error) {
        console.error("Error fetching tokens:", error);
        setSearchResults([]); // Reset or handle the error state as needed
    }
}, [fetchTokenMetadata]);



      // On mount, fetch default Solana metadata
    useEffect(() => {
        fetchTokenMetadata("So11111111111111111111111111111111111111112");
    }, [fetchTokenMetadata]);



    
    // Adjust setSelectedTokenByAddress logic
const setSelectedTokenByAddress = useCallback(async (address) => {
    const metadata = await fetchTokenMetadata(address);
    if (metadata) {
        setSelectedToken({
            address,
            ...metadata,
        });
    }
}, [fetchTokenMetadata]);




 


    
    // Make the fetchTokens, fetchTokenMetadata function, and setSelectedToken available via context
    const value = {
        searchResults,
        selectedToken,
        tokenMetaInfo, // Expose token metadata
        fetchTokens,
        fetchTokenMetadata, // Allow fetching of token metadata
        setSelectedToken, 
        selectedTokenVolume,
        setSelectedTokenByAddress,
        fetchAdditionalTokenDetailsForURLShare,
        additionalTokenDetails,
    };

    return (
        <SierraSearchContext.Provider value={value}>
            {children}
        </SierraSearchContext.Provider>
    );
};
