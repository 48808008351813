import React, { useMemo, useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './Auth/LandingPage/LandingPage';
import OnboardingPage from './Auth/Onboarding/OnboardingPage';
import TokenDetailsPage from './MainApp/TokenDetailsPage/TokenDetailsPage'; 
import Dashboard from './MainApp/Dashboard';
import Insight from './MainApp/TradingInsightPage/TradingInsight'; 
import Trade from './MainApp/TradePage/Trade'; 
import Explorer from './MainApp/ExplorerPage/Explorer'; 
import WalletDetailsPage from './MainApp/UserWalletAddressPage/WalletDetailsPage'; 
import TxDetailsPage from './MainApp/TransactionDetailsPage/TxDetailsPage'
import ReferUsersPage from './MainApp/ReferUsersPage/ReferUsersPage'
import TGEPage from './MainApp/TGEPage/TGEPage';
import { WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { SessionProvider } from './Auth/AppContexts/SessionContext';
import { UserProvider } from './Auth/AppContexts/UserContext';
import RedirectToOnboarding from './ReusableUtility/ReferralOnboarding/RedirectUserOnboarding';
import { NotificationProvider } from './Auth/AppContexts/NotificationContext'; 
import PortfolioAnalyticsPage from './MainApp/PortfolioPage/PortfolioAnalyticsPage'; 
import MobileUXMessage from './Media/MobileOptimiseAndUX/MobileUXMessage';
import ReactGA from 'react-ga4';
import MobileWarningModal from './Media/MobileUXWarning/MobileUXWarning';
import { SwapProvider } from './Auth/AppContexts/SwapContext';
import { SendTransactionProvider } from './Auth/AppContexts/SendTransactionContext';
import { SolanaConnectionProvider } from './Auth/AppContexts/SolanaConnectionContext'; 
import { SierraSearchProvider } from './Auth/AppContexts/SierraSearchContext'; 
import SPLTokenPage from './MainApp/MintAndCreateTokensPage/CreateTokensPage/CreateSPLTokens'; 

import { Buffer } from 'buffer';
window.Buffer = Buffer;







require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
  function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null; // This component does not render anything
}

    



    const [showNotification, setShowNotification] = useState(true);
    const [showMobileWarning, setShowMobileWarning] = useState(false);




    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // Add SolflareWalletAdapter to the array of wallets
  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter({ network }), 
    
  ], [network]);

    // Use the custom RPC URL from .env
    const rpcUrl = useMemo(() => process.env.REACT_APP_SOLANA_RPC_URL, []);



    //BLOCK MOBILE APPS COMPLETELY
            // Function to check if the device is mobile
        // const isMobileDevice = () => {
        //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // };

        // if (isMobileDevice()) {
        //     // If it's a mobile device, previously returned the MobileUXMessage component
        //     // Now this check is removed to allow access on mobile devices
        //     return <MobileUXMessage />;
        // }


        //FUNCTION TO WANR MOBILES UX IS NOT THAT GOOD

        
        // Function to check if the device is mobile
            const isMobileDevice = () => {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            };

            // Effect to show the modal on first load for mobile users
            useEffect(() => {
                if (isMobileDevice()) {
                    setShowMobileWarning(true);
                }
            }, []);


              
                                            

            

    

    return (
         <SolanaConnectionProvider>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <UserProvider>
                    <NotificationProvider> 
                        <SessionProvider>
                             <SierraSearchProvider> 
                            <SwapProvider> 
                                  <SendTransactionProvider> 
                            <Router>
                                <PageTracker />
                                <div className="App">
                                   
                                    {showMobileWarning && <MobileWarningModal onDismiss={() => setShowMobileWarning(false)} />}
                            
                                     <Routes>
                                    <Route path="/landing" element={<LandingPage />} />
                                    <Route path="/onboarding" element={<OnboardingPage />} />
                                    <Route path="/dashboard" element={<Dashboard />} />

                                   <Route path="/dashboard/sierra-ai" element={<Insight />} />
                                    <Route path="/dashboard/sierra-ai/t/:tokenAddress" element={<Insight />} />

                                  <Route path="/launch" element={<TGEPage />} /> 

                                   <Route path="/create-spl-token" element={<SPLTokenPage />} />

                                

                                    
                                    <Route path="/dashboard/trade" element={<Trade />} />
                                    <Route path="/dashboard/explorer" element={<Explorer />} />
                                    <Route path="/dashboard/explorer/t/:tokenAddress" element={<TokenDetailsPage />} /> 
                                    <Route path="/dashboard/explorer/w/:walletAddress" element={<WalletDetailsPage />} />
                                    <Route path="/dashboard/explorer/tx/:txAddress" element={<TxDetailsPage />} />
                                     <Route path="/dashboard/refer" element={<ReferUsersPage />} />
                                     <Route path="/dashboard/portfolio" element={<PortfolioAnalyticsPage />} />
                                    <Route path="/" element={<Navigate to="/dashboard/sierra-ai" replace />} />
                                     <Route path="*" element={<RedirectToOnboarding />} />
                                </Routes>

                                </div>
                            </Router>
                            </SendTransactionProvider>
                            </SwapProvider>
                             </SierraSearchProvider>
                        </SessionProvider>
                        </NotificationProvider>
                    </UserProvider>
                </WalletModalProvider>
            </WalletProvider>
        </SolanaConnectionProvider>
    );
}

export default App;
