import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [notifications, setNotifications] = useState([
        { type: 'System', description: 'System maintenance scheduled for tomorrow.', timestamp: 'Just now' },
        { type: 'Swap', description: 'Swap completed: 0.5 ETH for 200 DAI.', timestamp: '5 minutes ago' },
        { type: 'Token Transfer', description: 'Received 100 LINK in your wallet.', timestamp: '10 minutes ago' },
        { type: 'SOL Transfer', description: 'Sent 2 SOL to another wallet.', timestamp: '15 minutes ago' },
    ]);

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    return (
        <NotificationContext.Provider value={{ isModalOpen, toggleModal, notifications, setNotifications }}>
            {children}
        </NotificationContext.Provider>
    );
};