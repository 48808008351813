import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import './Theme.css'; // Adjust the path as necessary
import { ThemeProvider } from './Auth/AppContexts/ThemeContext'; 



import mixpanelUtils from './Auth/MixPanelManager';

// Initialize Mixpanel
mixpanelUtils.initialize();


// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
ReactGA.send('pageview'); // For the initial page load


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider> {/* Wrap your App component with ThemeProvider */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
