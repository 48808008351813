import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Auth/AppContexts/UserContext';

const useWalletConnection = () => {
    const { publicKey, connected } = useWallet();
    const { setUserId } = useContext(UserContext);
    const navigate = useNavigate();
      const wallet = useWallet(); 

    

    const checkUser = async (walletAddress) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/api/check-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ walletAddress }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Error checking user.');
            }

            if (data.userId) {
                // User is registered
                setUserId(data.userId); 
                localStorage.setItem('userId', data.userId); 
            } else {
                // User is not registered
                setUserId(null);
                localStorage.removeItem('userId');
                navigate('/onboarding'); // Redirect to onboarding page
            }
        } catch (error) {
            console.error('Error checking user:', error);
            // Optionally handle the error
        }
    };

     const connectWallet = useCallback(async () => {
    //console.log('Attempting to connect wallet'); // Debug statement
    try {
        await wallet.connect();
        //console.log('Wallet connected'); // Debug statement
    } catch (error) {
        console.error('Failed to connect:', error);
    }
}, [wallet]);







    useEffect(() => {
        if (connected && publicKey) {
            const walletAddress = publicKey.toString();
            checkUser(walletAddress);
        } else {
            setUserId(null);
            localStorage.removeItem('userId');
        }
    }, [publicKey, connected, setUserId, navigate]);

    return { connected: wallet.connected, connectWallet };
};

export default useWalletConnection;