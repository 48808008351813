import React from 'react';
import './TxDateAndTime.css'; 
import { ReactComponent as ClockIcon } from '../../../../Media/Icons/ClockIcon.svg'; 
import { ReactComponent as ClockIconDark } from '../../../../Media/Icons/ClockIconDarkMode.svg'; 
import TimeAgo from '../../../../ReusableUtility/RefreshFetchTime'; 
import { useTheme } from '../../../../Auth/AppContexts/ThemeContext'; 




const formatAbsoluteDateTime = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString(undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true // This will ensure AM/PM is included
  });
};

 

const TxDateAndTime = ({ timestamp }) => {
    const { theme } = useTheme();



    return (
        <div className="txDateAndTimeContainer">
            <div className="headerText">DATE & TIME</div>
            <div className="txDateAndTime">
                {theme === 'dark' ? <ClockIconDark className="txclockIcon" /> : <ClockIcon className="txclockIcon" />}
                <span className="dateText">
                    {timestamp ? <>{<TimeAgo timestamp={timestamp} />} ({formatAbsoluteDateTime(timestamp)})</> : 'Loading...'}
                </span>
            </div>
        </div>
    );
};

export default TxDateAndTime;
