// function allows the wallet or tx to be clickable in the frontend and go to the dedicated page
import React from 'react';
import './WalletAndTxLink.css'; 
import { protocolMapping } from '../../ReusableUtility/ProtocolMapping';





// Wallet Address Formatting and Link Creation
const formatAddress = (address) => {
    return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`;
};



const createWalletLink = (address) => {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    return `${baseUrl}/dashboard/explorer/w/${address}`;
};

export const WalletAddressLink = ({ address }) => {
    if (!address) {
        return <span className="TxAndWalletLink">N/A</span>;
    }

    const formattedAddress = formatAddress(address);
    const walletLink = createWalletLink(address);

    return (
        <a href={walletLink} className="TxAndWalletLink">
            {formattedAddress}
        </a>
    );
};





// Transaction Hash Formatting and Link Creation

// Transaction Hash Formatting
const formatTxHash = (signatures) => {
    if (!signatures) {
        return 'N/A';
    }
    return `${signatures.slice(0, 10)}...`;
};




// Transaction Link Creation for tx hashes 
const createTxLink = (signatures) => {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    return `${baseUrl}/dashboard/explorer/tx/${signatures}`;
};

export const TxHashLink = ({ signatures }) => {
    //console.log("TxHashLink signatures:", signatures); // 
    if (!signatures) {
        return <span className="TxAndWalletLink">N/A</span>;
    }

    const formattedHash = formatTxHash(signatures);
    const txLink = createTxLink(signatures);

    return (
        <a href={txLink} className="TxAndWalletLink">
            {formattedHash}
        </a>
    );
};





//This allows protocol or account names to display as words, mainly used for the protocol swpa name display
export const AccountNameLink = ({ address }) => {
  const protocolName = protocolMapping[address] || "Unknown Protocol";
  const protocolUrl = `/dashboard/explorer/w/${address}`;

  return (
    <a href={protocolUrl} className="protocolMappingStyle">
      {protocolName}
    </a>
  );
};