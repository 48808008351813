import React, { useContext } from 'react';
import { SierraSearchContext } from '../../../../Auth/AppContexts/SierraSearchContext';
import './SierraSearchResultItem.css';

const SierraSearchResultItem = ({ tokenData, onSelectToken }) => {
    const { tokenMetaInfo } = useContext(SierraSearchContext);

    // Attempt to get the token image from the fetched metadata
    const logoUrl = tokenMetaInfo[tokenData.address]?.image || tokenData.logoURI || 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png';

    const handleClick = () => {
        onSelectToken(tokenData);
    };

    return (
        <div className="sierra-search-result-item" onClick={handleClick}>
            <img src={logoUrl} alt={`${tokenData.name} logo`} className="sierra-token-logo" />
            <div className="sierra-token-info">
                <div className="sierra-token-name">{tokenData.name} ({tokenData.symbol})</div>
                <div className="sierra-token-address">{tokenData.address}</div>
            </div>
        </div>
    );
};

export default SierraSearchResultItem;
