import React from 'react';
import Button from '../Buttons/Button';
import './TokenRightSidePanel.css';

const TokenRightSidePanel = ({ selectedToken, onBuyClick, onSellClick }) => {
    if (!selectedToken) {
        return <div className="token-right-side-panel">Please select a token.</div>;
    }

    const { name, marketCap, fdmc, supply, holders, liquidity, address, logoURI } = selectedToken;
    const formattedAddress = address ? `${address.substring(0, 4)}...${address.substring(address.length - 4)}` : 'N/A';
    const solscanUrl = `https://solscan.io/token/${address}`;
    const defaultImage = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png';

    return (
        <div className="token-right-side-panel">
            <div className="token-header">
                <img 
                    src={logoURI || defaultImage} 
                    alt={`${name} logo`} 
                    className="token-logo" 
                    onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
                />
                <h2 className="token-name">{name}</h2>
                <div className="token-panel-buttons">
                    <Button text="Buy" color="green" onClick={onBuyClick} />
                    <Button text="Sell" color="red" onClick={onSellClick} />
                </div>
            </div>
            <p>Token Address: {formattedAddress}</p>
            <p><a href={solscanUrl} target="_blank" rel="noopener noreferrer">Explorer</a></p>
            <p>Market Cap: {marketCap || 'N/A'}</p>
            <p>Fully Diluted Market Cap (FDMC): {fdmc || 'N/A'}</p>
            <p>Total Supply: {supply || 'N/A'}</p>
            <p>Number of Holders: {holders || 'N/A'}</p>
            <p>Liquidity: {liquidity || 'N/A'}</p>
            {/* ... more detailed information */}
        </div>
    );
};

export default TokenRightSidePanel;
