import React, { useState, useEffect } from 'react';
import './ExplorerWelcomeModal.css';
import WhiteLogo from '../../../Media/Logos/WhiteLogoBig.svg';
import { ReactComponent as DiscordLogo } from '../../../Media/Icons/DiscordLogo.svg';
import { ReactComponent as TwitterLogo } from '../../../Media/Icons/XIconLogo.svg';
import { ReactComponent as DocsIcon } from '../../../Media/Icons/DocsIcon.svg';



const IntroModal = ({ isOpen, onClose }) => {
    // Set up state for the current slide
    const [currentSlide, setCurrentSlide] = useState(0);



     // Check local storage to see if the intro has been completed before
    useEffect(() => {
        const hasSeenIntro = localStorage.getItem('introSeen');
        if (hasSeenIntro) {
            onClose();  // If the intro has been seen, close the modal immediately
        }
    }, [onClose]);








    // Information for each slide
    const slides = [
        { 
        title: "Welcome to Blackfin", 
        content: "Blackfin is a next-generation AI trading and data application layer on Solana.\n" +
                 "Our mission is to enable effortless on-chain trading and data exploration through AI and natural language." 
    },


      { 
        title: "Execute Trades using AI", 
        content: "Execute trades on-chain swiftly with our custom AI LLM models.\n" +
                "Use simple natural language commands to navigate, transact and analyse assets on Solana."
      },


         { 
        title: "Integrated Solana Explorer", 
        content: "Explore Solana seamlessly with our AI-powered Explorer feature.\n" +
                "This feature offers AI-powered transaction summaries and concise, user-friendly and relevant token asset data at your fingertips."
      },


         { 
        title: "Join the Blackfin fam", 
        content: "We're building the best AI x Crypto product in the space, with the best community, coupled with the best vibes. Get involved!"
                
      },
      
    ];

    if (!isOpen) return null;

     // Function to advance to the next slide or close if it's the last one
    const handleButtonClick = () => {
    if (currentSlide < slides.length - 1) {
        setCurrentSlide(currentSlide + 1);
    } else {
        localStorage.setItem('introSeen', 'true');  // Set a flag in local storage
        onClose(); // Close the modal on the last slide
    }
};


    // Function to navigate to a slide
    const navigateToSlide = (index) => {
        setCurrentSlide(index);
    };



     // Check if the current slide is the last one
    const isLastSlide = currentSlide === slides.length - 1;




    return (
        <div className="explorer-full-page-overlay">
            <div className="explorer-coming-soon-modal">
                <button onClick={onClose} className="close-modal-button">X</button>
                <img src={WhiteLogo} alt="Logo" className="explorer-modal-logo" />
                <h2>{slides[currentSlide].title}</h2>
                {slides[currentSlide].content.split('\n').map((sentence, index) => (
                    <p key={index} className="modal-content-sentence">{sentence}</p>
                ))}
                
                {/* Render social links for the last slide */}
                {isLastSlide && (
                    <div className="social-links">
                        <a href="https://twitter.com/blackfinhq" target="_blank" rel="noopener noreferrer">
                            <TwitterLogo className="social-icon" />
                        </a>
                        <a href="https://discord.gg/jwFZqZt9Dp" target="_blank" rel="noopener noreferrer">
                            <DiscordLogo className="social-icon" />
                        </a>
                        <a href="https://blackfin-explorer.gitbook.io/welcome-to-blackfin/" target="_blank" rel="noopener noreferrer">
                            <DocsIcon className="social-icon" />
                        </a>
                    </div>
                )}

           <button onClick={handleButtonClick} className="explorer-modal-content-button">
            {currentSlide === 0 ? 'Tell me more' : 
            currentSlide === 2 ? 'Got it' : 
            isLastSlide ? 'Get Started' : 'Next'}
        </button>


                
                {/* Navigation dots */}
                <div className="navigation-dots">
                    {slides.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentSlide ? 'active' : ''}`}
                            onClick={() => navigateToSlide(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default IntroModal;
